import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface ButtonProps extends MuiButtonProps {
  dataId?: string;
  sx?: SxProps<Theme>;
}

function Button({ dataId, sx, ...props }: ButtonProps) {
  return <MuiButton data-id={dataId} sx={sx} {...props}></MuiButton>;
}

export default Button;
