import { useState, useEffect } from 'react';

import { useCallService } from 'hooks';

import SponsorshipTypeForm, {
  ISponsorshipTypeFormInputs,
} from 'features/sponsorships/forms/SponsorshipTypeForm';

import httpRoutes from 'utils/httpRoutes';
import { IEvent } from 'layout/OrganizerEventLayout';

type EditSponsorshipTypeProps = {
  eventId: number;
  eventDetails: IEvent;
  sponsorshipId: string;
  sponsorshipTypeId: number;
  onSuccess: () => void;
  hideDialog: () => void;
};

const EditSponsorshipType = ({
  eventId,
  eventDetails,
  sponsorshipId,
  sponsorshipTypeId,
  onSuccess,
  hideDialog,
}: EditSponsorshipTypeProps) => {
  const [sponsorshipType, setSponsorshipType] =
    useState<ISponsorshipTypeFormInputs>();

  const { callService } = useCallService();

  const editSponsorshipType = async (values: ISponsorshipTypeFormInputs) => {
    const { response } = await callService({
      resource: httpRoutes.sponsorshipTypes.updateSponsorshipType(values),
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  useEffect(() => {
    const getSponsorshipTypeDetails = async () => {
      const { response } = await callService({
        resource:
          httpRoutes.sponsorshipTypes.getSponsorshipType(sponsorshipTypeId),
      });

      if (response) {
        setSponsorshipType(response);
      }
    };

    getSponsorshipTypeDetails();
  }, [sponsorshipTypeId]);

  if (!sponsorshipType) {
    return <>Loading...</>;
  }

  return (
    <SponsorshipTypeForm
      eventId={eventId}
      eventDetails={eventDetails}
      sponsorshipId={sponsorshipId}
      sponsorshipType={sponsorshipType}
      onSubmit={editSponsorshipType}
      hideDialog={hideDialog}
    />
  );
};

export default EditSponsorshipType;
