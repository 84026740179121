import PropTypes from 'prop-types';

// material-ui
import { Box, Container } from '@mui/material';

const AuthCardWrapper = ({ children }) => (
  <Container maxWidth="sm">
    <Box sx={{ p: { xs: 1 } }}>{children}</Box>
  </Container>
);

AuthCardWrapper.propTypes = {
  children: PropTypes.node,
};

export default AuthCardWrapper;
