import { Outlet, RouteObject } from 'react-router-dom';

import {
  Events,
  EventTicketTypes,
  Transactions,
  Users,
} from 'features/admin/pages';
import AdminLayout from 'layout/AdminLayout';
import UserTransactions from 'features/admin/pages/users/UserTransactions';
import UserEvents from 'features/admin/pages/users/UserEvents';

const AdminRoutes: RouteObject[] = [
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      {
        path: '/admin/events',
        element: <Events />,
      },
      {
        path: '/admin/users',
        element: <Users />,
      },
      {
        path: '/admin/users/:userId/purchases',
        element: <UserTransactions />,
      },
      {
        path: '/admin/users/:userId/events',
        element: <UserEvents />,
      },
      {
        path: '/admin/transactions',
        element: <Transactions />,
      },
      {
        path: '/admin/ticket-types',
        element: <EventTicketTypes />,
      },
    ],
  },
];

export default AdminRoutes;
