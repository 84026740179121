// material-ui
import { Box, Button } from '@mui/material';

// third party
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import authResources from 'features/auth/resources';

import useCallService from 'hooks/useCallService';
import { PasswordFormField } from 'components/formFields';

interface IResetPasswordFormInputs {
  token: string;
  password: string;
  confirmPassword: string;
}

const AuthResetPassword = ({
  token,
  setIsSubmitted,
}: {
  token: string;
  setIsSubmitted: (value: boolean) => void;
}) => {
  const { callService } = useCallService();

  const onSubmit = async (values: IResetPasswordFormInputs) => {
    const payload = {
      password: values.password,
      token,
    };

    await callService({
      resource: authResources.resetPasswordConfirmation(payload),
    });

    setIsSubmitted(true);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IResetPasswordFormInputs>({
    defaultValues: {
      token: token,
      password: '',
      confirmPassword: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PasswordFormField
          name="password"
          control={control}
          label="New Password"
        />

        <PasswordFormField
          name="confirmPassword"
          control={control}
          label="Confirm Password"
        />

        <Box sx={{ mt: 2 }}>
          <Button
            disableElevation
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
          >
            Submit
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AuthResetPassword;
