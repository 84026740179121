import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// material-ui
import { IconButton, ListItem, ListItemText } from '@mui/material';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import PageContainer from 'components/PageContainer';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { formatDateTime } from 'utils/formatDateTime';
import EventPayout from '../rightDrawer/EventPayout';
import { MoneyIcon } from 'material-icons';
import { CancelOutlined } from '@mui/icons-material';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import CancelEvent from '../dialogs/CancelEvent';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

let eventsRequest: AbortController | undefined;

const EventListGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const getEventPayout = (eventId: number) => {
    showDialog({
      content: <EventPayout eventId={eventId} />,
      fullScreen: true,
    });
  };

  const cancelEvent = (eventId: number, name: string) => {
    showDialog({
      content: <CancelEvent id={eventId} name={name} hideDialog={hideDialog} />,
      size: 'sm',
    });
  };

  const getEvents = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    if (eventsRequest) {
      eventsRequest.abort();
    }

    try {
      eventsRequest = new AbortController();

      const { response } = await callService({
        resource: httpRoutes.admin.getEvents({
          payload: {
            params: { ...state },
            filters,
          },
          controller: eventsRequest,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        setLoading((prevState) => ({ ...prevState, data: false }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getEvents();
  }, [state, filters]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'ID',
      field: 'id',
      sortable: true,
      type: 'string',
    },
    {
      headerName: 'Event Name',
      field: 'name',
      sortable: true,
      type: 'string',
      flex: 0.2,
    },
    {
      headerName: 'Organizer',
      field: 'organizerName',
      sortable: true,
      type: 'string',
    },
    {
      headerName: 'Private',
      field: 'private',
      sortable: true,
      type: 'boolean',
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
    },
    {
      headerName: 'Tickets Sold',
      field: 'ticketsSold',
      sortable: true,
      type: 'number',
    },
    {
      headerName: 'Sponsorships Sold',
      field: 'sponsorshipsSold',
      sortable: true,
      type: 'number',
    },
    {
      headerName: 'Total Revenue',
      field: 'totalRevenue',
      sortable: true,
      type: 'usdCurrency',
    },
    {
      headerName: 'Created On',
      field: 'createdOn',
      sortable: true,
      type: 'dateTime',
    },
    {
      headerName: 'Published on',
      field: 'publishedOn',
      sortable: true,
      type: 'dateTime',
    },
    {
      headerName: 'Ends At',
      field: 'endsOn',
      sortable: true,
      type: 'dateTime',
    },

    {
      headerName: 'Events',
      field: 'mobile',
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <ListItem>
            <ListItemText
              primary={`${params.row.id} ${params.row.name}`}
              secondary={`Created: ${formatDateTime(
                params.row.createdOn,
                params.row.timezone
              )} Status: ${params.row.status}`}
              sx={{ ml: 2 }}
            />
          </ListItem>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 0.15,
      ColumnComponent: (params: any) => {
        const _disabled =
          params.row.sponsorshipsSold < 1 && params.row.ticketsSold < 1;
        return (
          <>
            <IconButton
              disabled={_disabled}
              onClick={() => getEventPayout(params.row.id)}
            >
              <MoneyIcon />
            </IconButton>
            <IconButton
              disabled={params.row.status === 'Canceled'}
              onClick={() => cancelEvent(params.row.id, params.row.name)}
            >
              <CancelOutlined />
            </IconButton>
          </>
        );
      },
    },
  ];

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Event Name',
    },
    {
      id: 'organizerName',
      type: 'text',
      label: 'Organizer Name',
    },
    {
      id: 'salesStatus',
      type: 'select',
      label: 'Event Sales',
      options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Selling',
          value: 'selling',
        },
        {
          label: 'Sold',
          value: 'sold',
        },
      ],
    },
    {
      id: 'isPrivate',
      type: 'select',
      label: 'Private',
      options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
    },
    {
      id: 'status',
      type: 'select',
      label: 'Event Status',
      options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Draft',
          value: 'Draft',
        },
        {
          label: 'Published',
          value: 'Published',
        },
        {
          label: 'Canceled',
          value: 'Canceled',
        },
      ],
    },
  ];

  return (
    <PageContainer maxWidth="xl">
      <DataGrid
        tableTitle="Events List"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
        onCellClick={(values) => {
          console.log('Values: ', values);

          if (values.field === 'id') {
            navigate(
              `/organizer/events/${encodeURIComponent(values.row.id)}/dashboard`
            );
          }
        }}
      />
    </PageContainer>
  );
};

const EventList = () => (
  <ControlledDataGrid>
    <EventListGrid />
  </ControlledDataGrid>
);

export default EventList;
