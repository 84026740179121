import {
  memo,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  useEffect,
} from 'react';

import Cookies from 'js-cookie';
import jwt from 'jwt-decode';

import { useNavigate } from 'react-router-dom';

import AuthContext, { AUTH_INITIAL_STATE, User } from './contexts/AuthContext';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState(AUTH_INITIAL_STATE);

  const navigate = useNavigate();

  const loadUserFromCookies = async () => {
    setAuthState({ ...authState, validating: true });
    const token = Cookies.get('ehi-token');

    if (token) {
      const currentDate = Number(new Date().getTime().toString().slice(0, 10));

      const decodedToken: any = jwt(token);

      if (currentDate < decodedToken.exp) {
        setAuthState({
          authenticated: decodedToken.authenticated,
          user: {
            id: decodedToken.userId,
            firstName: decodedToken.firstName,
            lastName: decodedToken.lastName,
            email: decodedToken.email,
          },
          token,
          tokenExpiration: decodedToken.exp,
          validating: false,
          isAdmin: decodedToken.isAdmin,
        });
      }

      if (currentDate > decodedToken.exp) {
        logout();
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    loadUserFromCookies();
  }, []);

  const login = useCallback(
    ({ user, token }: { user: User; token: string }) => {
      Cookies.set('ehi-token', token);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedToken: any = jwt(token);

      setAuthState({
        authenticated: true,
        user,
        token,
        tokenExpiration: decodedToken.exp,
        validating: false,
        isAdmin: decodedToken.isAdmin,
      });
    },
    [navigate]
  );

  const logout = useCallback(async () => {
    setAuthState({
      ...AUTH_INITIAL_STATE,
      authenticated: false,
      validating: false,
    });
    Cookies.remove('ehi-token');

    // navigate('/');
  }, []);

  const updateUser = useCallback(
    ({ user }: { user: any }) => {
      setAuthState({
        ...authState,
        user,
      });
    },
    [authState]
  );

  const value = useMemo(
    () => ({
      authState,
      login,
      logout,
      updateUser,
    }),
    [authState, login, logout, updateUser]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default memo(AuthProvider);
