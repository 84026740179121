import React, { useCallback, useMemo, useState } from 'react';

import RightDrawer from 'components/rightDrawer/components/RightDrawer';

import RightDrawerContext, {
  RIGHT_DRAWER_INITIAL_STATE,
  RightDrawerProviderState,
} from './contexts/RightDrawerContext';

interface RightDrawerProviderProps {
  children: React.ReactNode;
}

const RightDrawerProvider = ({ children }: RightDrawerProviderProps) => {
  const [rightDrawerState, setRightDrawerState] = useState(
    RIGHT_DRAWER_INITIAL_STATE
  );

  const showRightDrawer = useCallback(
    ({
      title,
      size,
      content,
      disableEscapeKeyDown,
    }: RightDrawerProviderState) => {
      setRightDrawerState({
        open: true,
        title,
        size,
        content,
        disableEscapeKeyDown,
      });
    },
    []
  );

  const hideRightDrawer = useCallback(() => {
    setRightDrawerState((prevState: any) => ({ ...prevState, open: false }));
  }, []);

  const value = useMemo(
    () => ({
      rightDrawerState,
      showRightDrawer,
      hideRightDrawer,
    }),
    [rightDrawerState, showRightDrawer, hideRightDrawer]
  );

  const setIsOpen = () => {
    if (!rightDrawerState.open) {
      hideRightDrawer();
    }
  };

  return (
    <RightDrawerContext.Provider value={value}>
      <RightDrawer
        title={rightDrawerState.title}
        content={rightDrawerState.content}
        disableEscapeKeyDown={rightDrawerState.disableEscapeKeyDown}
        enableBackdropClose={rightDrawerState.enableBackdropClose}
        isOpen={Boolean(rightDrawerState.open)}
        setIsOpen={setIsOpen}
        width={480}
      />
      {children}
    </RightDrawerContext.Provider>
  );
};

export default RightDrawerProvider;
