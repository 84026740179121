import MuiTypography, { TypographyProps } from '@mui/material/Typography';

interface CustomTypographyProps extends TypographyProps {
  content: string;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  noWrap?: boolean;
  paragraph?: boolean;
  variant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2';
  rest?: any;
  color?: string;
}

const Typography = ({
  content,
  align = 'left',
  noWrap = false,
  paragraph = false,
  variant = 'body1',
  rest,
  color,
}: CustomTypographyProps) => (
  <MuiTypography
    align={align}
    noWrap={noWrap}
    paragraph={paragraph}
    variant={variant}
    {...rest}
    color={color}
  >
    {content}
  </MuiTypography>
);

export default Typography;
