import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title: string;
  description: string;
  url: string;
  image?: string;
  keywords?: string[];
}

export default function SEO(props: SEOProps) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content="article" />
      <meta property="og:title" content={props.title} />
      <meta property="og:url" content={props.url} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.image} />
      <meta property="og:site_name" content="EventHi" />
      {/* End Facebook tags */}

      {/* Twitter tags */}
      <meta name="twitter:card" content="article" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={props.image} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* End Twitter tags */}
    </Helmet>
  );
}
