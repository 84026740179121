import { useState } from 'react';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';

import { FilterProps } from '../filterTypes';

export default function SwitchFilter({ id, label, onChange }: FilterProps) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <FormGroup sx={{ mt: 0.5 }}>
      <FormControlLabel
        control={
          <Switch
            id={id}
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
              onChange(isChecked);
            }}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
