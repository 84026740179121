import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import useCallService from 'hooks/useCallService';

import EventGeneralInfoForm, {
  IEventFormInputs,
  INITIAL_EVENT_FORM_STATE,
} from '../forms/EventGeneralInfoForm';

import Loading from 'components/Loading';
import PageContainer from 'components/PageContainer';

import httpRoutes from 'utils/httpRoutes';

const GeneralInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState(INITIAL_EVENT_FORM_STATE);

  const { callService } = useCallService();

  const params = useParams();
  const navigate = useNavigate();

  const { id } = params;
  const _eventId = Number(id);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    const getEvent = async () => {
      setIsLoading(true);

      try {
        const { response } = await callService({
          resource: httpRoutes.organizer.getEvent(_eventId),
        });

        if (response) {
          const _eventCategories = response.eventDetails.eventCategories.map(
            (ec: any) => ec.categoryId
          );

          const _editValues: IEventFormInputs = {
            id: response.eventDetails.id,
            name: response.eventDetails.name,
            hostname: response.eventDetails.hostname,
            contactEmail: response.eventDetails.contactEmail,
            eventLocation: response.eventDetails.eventLocation,
            hideLocation: response.eventDetails.hideLocation,
            allowShare: response.eventDetails.allowShare,
            private: response.eventDetails.private,
            cannabisConsumption: response.eventDetails.cannabisConsumption,
            isVirtual: response.eventDetails.isVirtual,
            status: response.eventDetails.status,
            virtualUrl: response.eventDetails.virtualUrl,
            startsAt: response.eventDetails.startsAt,
            startTime: response.eventDetails.startsAt,
            endsAt: response.eventDetails.endsAt,
            endTime: response.eventDetails.endsAt,
            eventCategories: _eventCategories,
            eventType: response.eventDetails.eventType,
            timezone: response.eventDetails.timezone,
            refundPolicy: response.eventDetails.refundPolicy,
          };

          setDefaultValues(_editValues);
        }
      } finally {
        setIsLoading(false);
      }
    };
    getEvent();
  }, [id]);

  const onSubmit = async (values: IEventFormInputs, details?: boolean) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.updateEvent(values),
        successMessage: 'Changes saved!',
      });

      if (response) {
        if (details) {
          navigate(`/organizer/events/${_eventId}/details`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContainer title="General Info" maxWidth="md">
      {isLoading ? (
        <Loading />
      ) : (
        <EventGeneralInfoForm
          onSubmit={onSubmit}
          onBack={() => navigate(`/organizer/events/${_eventId}/dashboard`)}
          defaultValues={defaultValues}
        />
      )}
    </PageContainer>
  );
};

export default GeneralInfo;
