import { useEffect, useState, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import {
  AppBar as MuiAppBar,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';

import WhiteLogo from 'assets/images/logos/EventHi-Logo-White.png';
import ColorLogo from 'assets/images/logos/full-color-logo.png';

import {
  AccountIcon,
  DashboardIcon,
  DownArrowIcon,
  LogoutIcon,
  OrderHistoryIcon,
  MenuIcon,
  CloseIcon,
} from 'material-icons';

import { useAuthDispatcher, useAuthState } from 'providers/AuthProvider/hooks';
import { useRightDrawerDispatcher } from 'providers/RightDrawerProvider/hooks/useRightDrawerDispatcher';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import LogoSection from './LogoSection';
import Login from 'features/auth/dialogs/Login';
import SignUp from 'features/auth/dialogs/SignUp';

const profileLinks = [
  {
    icon: <AccountIcon />,
    label: 'Account Profile',
    link: '/user/profile',
  },
  {
    icon: <OrderHistoryIcon />,
    label: 'Order History',
    link: '/user/order-history',
  },
  {
    icon: <DashboardIcon />,
    label: 'Organizer Dashboard',
    link: '/organizer/events',
  },
];

const publicLinks = [
  {
    key: 0,
    label: 'CREATE EVENT',
    path: '/events/create',
  },
];

const AppBar = () => {
  const theme = useTheme();

  const { logout } = useAuthDispatcher();
  const { authState } = useAuthState();
  const { showRightDrawer, hideRightDrawer } = useRightDrawerDispatcher();
  const { showDialog } = useDialogDispatcher();

  const [userProfile, setUserProfile] = useState(authState);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [transparentHeader, setTransparentHeader] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const showLogin = () => {
    showDialog({
      size: 'sm',
      content: <Login />,
    });
  };

  const showSignUp = () => {
    showDialog({
      size: 'sm',
      content: <SignUp />,
    });
  };

  const handleLogout = () => {
    navigate('/');
    logout();
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenMobileDrawer = () => {
    showRightDrawer({
      content: (
        <Grid container m={1}>
          <Grid
            container
            sx={{ p: 1, m: 1, mb: 2, pr: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <LogoSection imageSrc={ColorLogo} />
            </Grid>
            <Grid item>
              {' '}
              <IconButton
                size="large"
                edge="start"
                onClick={hideRightDrawer}
                sx={{ color: 'purple' }}
              >
                <CloseIcon color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          {userProfile.authenticated && (
            <Grid container sx={{ pl: 2 }}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Stack direction="row" spacing={1.25} alignItems="center">
                  <Stack>
                    <Typography variant="caption">Signed in as</Typography>
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {userProfile.user.email}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
              </Grid>
              {profileLinks.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Button
                    key={item.label}
                    onClick={() => {
                      navigate(item.link);
                      hideRightDrawer();
                    }}
                    sx={{
                      p: 1.25,
                      m: 0.5,
                      color: 'black',
                      ':hover': {
                        border: '1px solid purple',
                        color: 'purple',
                      },
                    }}
                    startIcon={item.icon}
                  >
                    {item.label}
                  </Button>
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                sx={{
                  mt: 2,
                }}
              >
                <Divider />
                <Button
                  variant="text"
                  onClick={() => {
                    navigate('/');
                    logout();
                    hideRightDrawer();
                  }}
                  startIcon={<LogoutIcon />}
                  sx={{
                    mt: 1,
                    color: 'black',
                  }}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          )}
          {!userProfile.authenticated && (
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                aria-label="login"
                aria-controls="menu-appbar"
                aria-haspopup="false"
                onClick={() => {
                  showLogin();
                  hideRightDrawer();
                }}
                color="inherit"
                sx={{
                  fontWeight: 'bold',
                  ':hover': {
                    borderRadius: '24px',
                    backgroundColor: 'rgba(255,255,255,.85)',
                    color: 'purple',
                  },
                }}
              >
                LOGIN
              </Button>
              <Button
                size="large"
                variant="outlined"
                aria-label="signup"
                aria-controls="menu-appbar"
                aria-haspopup="false"
                onClick={() => {
                  showSignUp();
                  hideRightDrawer();
                }}
                color="inherit"
                sx={{
                  m: 1,
                  fontWeight: 'bold',
                  ':hover': {
                    borderRadius: '24px',
                    backgroundColor: 'rgba(255,255,255,.85)',
                    color: 'purple',
                  },
                }}
              >
                SIGN UP
              </Button>
            </Grid>
          )}
        </Grid>
      ),
    });
  };

  useEffect(() => {
    if (matches) {
      hideRightDrawer();
    }
  }, [matches]);

  useEffect(() => {
    setUserProfile(authState);
  }, [authState]);

  useEffect(() => {
    switch (location.pathname) {
      case '':
      case '/':
      case '/features':
        setTransparentHeader(true);
        break;
      default:
        setTransparentHeader(false);
        break;
    }
  }, [location.pathname]);

  return (
    <MuiAppBar
      color={transparentHeader ? 'transparent' : 'inherit'}
      elevation={transparentHeader ? 0 : 1}
      position={transparentHeader ? 'absolute' : 'sticky'}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ width: '100%', mt: { sm: 0, md: 1 } }}>
        <LogoSection imageSrc={transparentHeader ? WhiteLogo : ColorLogo} />
        {/* Desktop View */}
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          sx={{
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          }}
        >
          <Grid item>
            {publicLinks.map((item, index) => (
              <Button
                key={index}
                sx={{
                  color: transparentHeader ? 'white' : 'purple',
                  m: 1,
                  pt: 1,
                  pb: 1,
                  pr: 2,
                  pl: 2,
                  fontWeight: 'bold',
                  borderRadius: '24px',
                  ':hover': {
                    backgroundColor: transparentHeader
                      ? 'rgba(255,255,255,.85)'
                      : 'rgba(255,255,255,.15)',
                    color: 'purple',
                  },
                }}
                onClick={() => navigate(item.path)}
              >
                {item.label}
              </Button>
            ))}
          </Grid>

          {authState.authenticated && (
            <Grid item sx={{ color: transparentHeader ? 'white' : 'primary' }}>
              <Button
                size="large"
                variant="outlined"
                aria-label="user profile"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color="inherit"
                sx={{
                  fontWeight: 'bold',
                  borderRadius: '24px',
                  ':hover': {
                    backgroundColor: 'rgba(255,255,255,.85)',
                    color: 'purple',
                  },
                }}
              >
                <AccountIcon fontSize="medium" sx={{ mr: 0.5 }} />
                <DownArrowIcon fontSize="medium" />
              </Button>
              <Menu
                sx={{ mt: 8 }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: 250,
                    minWidth: 240,
                    maxWidth: 290,
                    pl: 1,
                    pt: 2,
                    mb: 1,
                  }}
                >
                  <Grid item>
                    <Stack direction="row" spacing={1.25} alignItems="center">
                      <Stack>
                        <Typography variant="caption">Signed in as</Typography>
                        <Typography variant="body1">
                          {userProfile.user.email}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
                {profileLinks.map((item) => (
                  <MenuItem
                    key={item.label}
                    onClick={() => {
                      handleCloseUserMenu();
                      navigate(item.link);
                    }}
                    sx={{ p: 1.25 }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{item.label}</ListItemText>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem
                  key="logout"
                  onClick={() => {
                    handleLogout();
                    handleCloseUserMenu();
                  }}
                  sx={{ p: 1.25 }}
                >
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Menu>
            </Grid>
          )}
          {!authState.authenticated && (
            <Grid
              item
              sx={{ color: transparentHeader ? 'white' : 'primary', ml: 2 }}
            >
              <Button
                size="large"
                variant="outlined"
                aria-label="login"
                aria-controls="menu-appbar"
                aria-haspopup="false"
                onClick={showLogin}
                color="inherit"
                sx={{
                  fontWeight: 'bold',
                  ':hover': {
                    borderRadius: '24px',
                    backgroundColor: transparentHeader
                      ? 'rgba(255,255,255,.85)'
                      : 'purple',
                    color: transparentHeader ? 'purple' : 'white',
                  },
                }}
              >
                LOGIN
              </Button>
            </Grid>
          )}
        </Grid>
        {/* Mobile */}
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            [theme.breakpoints.up('sm')]: {
              display: 'none',
            },
          }}
        >
          <Button
            size="small"
            sx={{
              color: transparentHeader ? 'white' : 'purple',
              m: 1,
              pt: 1,
              pb: 1,
              pr: 2,
              pl: 2,
              fontWeight: 'bold',
              borderRadius: '24px',
              ':hover': {
                backgroundColor: transparentHeader
                  ? 'rgba(255,255,255,.85)'
                  : 'rgba(255,255,255,.15)',
                color: 'purple',
              },
            }}
            onClick={() => navigate(publicLinks[0].path)}
          >
            {publicLinks[0].label}
          </Button>
          <IconButton
            size="large"
            edge="start"
            sx={{
              color: transparentHeader ? 'white' : 'purple',
            }}
            onClick={handleOpenMobileDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
