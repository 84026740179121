import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { useRightDrawerDispatcher } from 'providers/RightDrawerProvider/hooks/useRightDrawerDispatcher';

import OrderSummary from '../../../events/components/rightDrawer/OrganizerOrderSummary';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import PageContainer from 'components/PageContainer';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';
import { formatDate } from 'utils/formatDateTime';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

const TransactionsGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showRightDrawer } = useRightDrawerDispatcher();
  const params = useParams();

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const { userId = 0 } = params;
  const _userId = Number(userId);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getUserTransactions();
  }, [state, filters]);

  const getOrderDetails = (orderId: number) => {
    showRightDrawer({
      content: <OrderSummary orderId={orderId} />,
    });
  };

  const getUserTransactions = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, data: true }));

      const { response } = await callService({
        resource: httpRoutes.admin.getUserTransactions(_userId, {
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  const filterModel: FilterType[] = [
    {
      id: 'eventName',
      type: 'text',
      label: 'Event Name',
    },
  ];

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'ID',
      field: 'id',
      sortable: false,
    },
    {
      headerName: 'Event',
      field: 'eventName',
      sortable: true,
      flex: 0.1,
      ColumnComponent: (params: any) => {
        return `${params.row.event.name}`;
      },
    },
    {
      headerName: 'Date Purchased',
      field: 'date',
      sortable: true,
      type: 'dateTime',
      flex: 0.1,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Card Type',
      field: 'creditCardType',
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Subtotal',
      field: 'subtotal',
      sortable: true,
      type: 'usdCurrency',
      headerAlign: 'center',
      align: 'right',
      flex: 0.1,
    },
    {
      headerName: 'Fees',
      field: 'totalFees',
      sortable: true,
      headerAlign: 'center',
      type: 'usdCurrency',
      align: 'right',
      flex: 0.1,
    },
    {
      headerName: 'Total',
      field: 'total',
      sortable: true,
      type: 'usdCurrency',
      headerAlign: 'center',
      align: 'right',
      flex: 0.1,
    },
    {
      headerName: 'Transactions',
      field: 'mobile',
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <Grid
            container
            height="100%"
            m={0.5}
            p={1}
            justifyContent="space-between"
            flexDirection="row"
            spacing={1}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Name</Typography>
              <Typography fontWeight="bold">
                {params.row.customerName}
              </Typography>
              <Typography>{params.row.customerEmail}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Date Purchased</Typography>
              <Typography>{formatDate(params.row.datePurchased)}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Totals</Typography>
              {Number(params.row.subtotal) > 0 && (
                <Typography>
                  Subtotal: {formatCurrency(params.row.subtotal)}
                </Typography>
              )}
              {Number(params.row.totalFees) > 0 && (
                <Typography>
                  Fees: {formatCurrency(params.row.totalFees)}
                </Typography>
              )}
              {Number(params.row.total) > 0 && (
                <Typography>
                  Total: {formatCurrency(params.row.total)}
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <PageContainer maxWidth="xl">
      <DataGrid
        tableTitle="User Transactions"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
        onRowClick={(params) => {
          if (params.status !== 'cancelled') {
            getOrderDetails(Number(params.id));
          }
        }}
      />
    </PageContainer>
  );
};

const UserTransactions = () => (
  <ControlledDataGrid>
    <TransactionsGrid />
  </ControlledDataGrid>
);

export default UserTransactions;
