import React from 'react';

import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  SxProps,
} from '@mui/material';

interface CheckboxFieldProps {
  label: string | React.ReactElement;
  size?: 'small' | 'medium';
  labelPlacement?: 'top' | 'bottom' | 'end' | 'start';
  checkedIcon?: React.ReactElement;
  icon?: React.ReactElement;
  sx?: SxProps;
}

export type CheckboxFormFieldProps<T extends FieldValues> = CheckboxFieldProps &
  UseControllerProps<T>;

const CheckboxFormField = <T extends FieldValues>(
  props: CheckboxFormFieldProps<T>
) => {
  const { label, name, control, labelPlacement, checkedIcon, icon, size, sx } =
    props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });

  return (
    <FormControl
      error={Boolean(errors[name])}
      sx={{ border: errors[name] ? '1px solid red' : 'none', ...sx }}
    >
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checkedIcon={checkedIcon}
                icon={icon}
                size={size}
              />
            )}
          />
        }
      />
    </FormControl>
  );
};

export default CheckboxFormField;
