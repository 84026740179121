import { instance as apiClient } from 'utils/axios';

const apiBase = '/api';

const resources = {
  resetPasswordRequest(payload) {
    return apiClient.post(`${apiBase}/auth/recover/reset`, payload);
  },
  resetPasswordConfirmation(payload) {
    return apiClient.post(`${apiBase}/auth/recover/password`, payload);
  },
  createUserAccount(payload) {
    return apiClient.post(`${apiBase}/users/`, payload);
  },
  sendVerificationEmail(payload) {
    return apiClient.post(`${apiBase}/auth/send-verification-email`, payload);
  },
  login(payload) {
    return apiClient.post(`${apiBase}/auth/login`, payload);
  },
};

export default resources;
