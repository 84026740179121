// material-ui
import { ButtonBase } from '@mui/material';

const LogoSection = ({ imageSrc }: { imageSrc: string }) => (
  <ButtonBase
    disableRipple
    href={'/'}
    sx={{ width: { lg: 160, md: 140, sm: 100, xs: 80 }, margin: 'auto' }}
  >
    <img src={imageSrc} alt="EventHi Logo" style={{ width: 'inherit' }} />
  </ButtonBase>
);

export default LogoSection;
