import { useNavigate } from 'react-router-dom';

import { useCallService } from 'hooks';

import TicketTypeForm, {
  ITicketTypeFormInputs,
} from 'features/ticketTypes/forms/TicketTypeForm';

import { IEvent } from 'layout/OrganizerEventLayout';

import httpRoutes from 'utils/httpRoutes';

type CreateTicketTypeProps = {
  eventId: number;
  eventDetails: IEvent;
  onSuccess: () => void;
  hideDialog: () => void;
};

const CreateTicketType = ({
  eventId,
  eventDetails,
  onSuccess,
  hideDialog,
}: CreateTicketTypeProps) => {
  const { callService } = useCallService();
  const navigate = useNavigate();

  const createTicketType = async (
    values: ITicketTypeFormInputs,
    dashboard?: boolean
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.ticketTypes.createTicketType(values),
        successMessage: 'Changes saved!',
      });

      if (response) {
        hideDialog();
        if (!dashboard) {
          onSuccess();
          navigate(`/organizer/events/${eventId}/tickets`);
        } else {
          navigate(`/organizer/events/${eventId}/dashboard?publishPrompt=true`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <TicketTypeForm
      eventId={eventId}
      eventDetails={eventDetails}
      hideDialog={hideDialog}
      onSubmit={createTicketType}
    />
  );
};

export default CreateTicketType;
