import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
import { REACT_APP_API_URL } from 'config/config';
import Cookies from 'js-cookie';

export const createAxiosInstance = (baseUrl, contentType) => {
  const instance = axios.create({
    baseURL: baseUrl,
    adapter: httpAdapter,
    headers: {
      'Content-Type': contentType,
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = Cookies.get('ehi-token');

      config.headers.authorization = `Bearer ${token}`;

      return config;
    },
    (error) => {
      console.error('Request error: ', error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // if (error.response && error.response.status === 401) {
      //   window.location =
      //     window.location.protocol + '//' + window.location.host + '/';
      // }

      return Promise.reject(error);
    }
  );

  return instance;
};

// eslint-disable-next-line no-undef
export const instance = createAxiosInstance(
  REACT_APP_API_URL,
  'application/json'
);

export const multipartInstance = createAxiosInstance(
  REACT_APP_API_URL,
  'multipart/form-data'
);

export default instance;
