import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  MutableRefObject,
} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import { loadMapApi } from 'utils/googleMaps';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

type PropTypes = {
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
  placeholder?: string;
  standout?: boolean;
  label?: string;
};

export default function GoogleMapsAutoComplete({
  onChange,
  placeholder,
  standout,
  label,
}: PropTypes) {
  const [selectedValue, setSelectedValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const loaded = useRef(false);

  const geocoderService: MutableRefObject<google.maps.Geocoder | undefined> =
    useRef();

  const autocompleteService: MutableRefObject<
    google.maps.places.AutocompleteService | undefined
  > = useRef();

  useEffect(() => {
    const googleMapScript = loadMapApi();

    const geoCoderInit = () => {
      loaded.current = true;
    };

    googleMapScript.addEventListener('load', geoCoderInit);

    return () => {
      googleMapScript.removeEventListener('load', geoCoderInit);
      loaded.current = false;
    };
  }, []);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string; componentRestrictions: { country: 'us' } },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  const handleChange = async (value: any) => {
    setSelectedValue(value);

    if (value?.description) {
      if (geocoderService.current) {
        const { results } = await geocoderService.current.geocode({
          address: value.description,
          componentRestrictions: { country: 'us' },
        });

        if (results) {
          return onChange({
            addressComponents: results[0].address_components,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            placeId: results[0].place_id,
          });
        }
      }
    } else {
      return onChange({});
    }
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService({
        fields: ['address_components', 'geometry', 'place_id'],
      });
    }

    if (!geocoderService.current && (window as any).google) {
      geocoderService.current = new (window as any).google.maps.Geocoder({
        fields: ['address_components', 'geometry', 'place_id'],
      });
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (!geocoderService.current) {
      return undefined;
    }

    if (inputValue === '' || inputValue === null) {
      setOptions(selectedValue ? [selectedValue] : []);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: 'us' } },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (selectedValue) {
            newOptions = [selectedValue];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [selectedValue, inputValue, fetch]);

  return (
    <>
      <Autocomplete
        id="google-autocomplete"
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={selectedValue}
        onChange={(_event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          handleChange(newValue);
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            variant={standout ? 'outlined' : 'filled'}
            {...params}
            label={label ? label : placeholder}
            placeholder={placeholder}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: 'text.secondary', mr: 2 }}
                  />
                </Grid>
                <Grid item>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}
