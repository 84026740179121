import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// material-ui
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';

import EventHiLogoShort from 'assets/images/logos/logo.png';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import PageContainer from 'components/PageContainer';

import { useCallService } from 'hooks';

import { EventIcon } from 'material-icons';

import { REACT_APP_CLOUDFRONT_URL } from 'config/config';

import httpRoutes from 'utils/httpRoutes';
import {
  formatDate,
  formatEventDate,
  formatEventDateTime,
  formatEventStartDate,
} from 'utils/formatDateTime';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

const EventListGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const getEvents = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.getEvents({
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getEvents();
  }, [state, filters]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: '',
      field: 'eventPhoto',
      type: 'string',
      flex: 0.1,
      ColumnComponent: (params: any) => {
        if (params.row.imageUrl) {
          return (
            <img
              style={{
                margin: 'auto',
                height: '51px',
                maxWidth: '75px',
                objectFit: 'contain',
              }}
              src={`${REACT_APP_CLOUDFRONT_URL}/${params.row.imageUrl}`}
            />
          );
        }
        return (
          <img
            style={{
              margin: 'auto',
              height: '51px',
              maxWidth: '75px',
              objectFit: 'contain',
            }}
            src={EventHiLogoShort}
            alt="EventHi Logo"
          />
        );
      },
    },
    {
      headerName: 'Event Name',
      field: 'name',
      sortable: true,
      type: 'string',
      flex: 0.3,
    },
    {
      headerName: 'Created On',
      field: 'dateAdded',
      sortable: true,
      type: 'date',
      flex: 0.2,
    },
    {
      headerName: 'Starts At',
      field: 'dateAdded',
      sortable: true,
      type: 'date',
      flex: 0.2,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      flex: 0.15,
    },
    {
      headerName: 'Events',
      field: 'mobile',
      flex: 1,
      ColumnComponent: (params: any) => {
        console.log('Params: ', params.row);
        return (
          <ListItem>
            <img
              style={{
                margin: 'auto',
                aspectRatio: '2/1',
                width: '75px',
                objectFit: 'contain',
              }}
              src={`${REACT_APP_CLOUDFRONT_URL}/${params.row.imageUrl}`}
            />
            <ListItemText
              primary={params.row.name}
              secondary={`${formatEventDate(params.row.startsAt)} Status: ${
                params.row.status
              }`}
              sx={{ ml: 2 }}
            />
          </ListItem>
        );
      },
    },
  ];

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Event Name',
    },
    {
      id: 'status',
      type: 'select',
      label: 'Event Status',
      options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Draft',
          value: 'Draft',
        },
        {
          label: 'Published',
          value: 'Published',
        },
        {
          label: 'Canceled',
          value: 'Canceled',
        },
      ],
    },
    {
      id: 'startDate',
      type: 'date',
      label: 'Start Date',
    },
    {
      id: 'endDate',
      type: 'date',
      label: 'End Date',
    },
  ];

  return (
    <PageContainer maxWidth="xl">
      <Grid container justifyContent="end" sx={{ mb: 4 }}>
        <Button
          variant="contained"
          onClick={() => navigate('/organizer/events/create')}
          startIcon={<EventIcon />}
        >
          Create Event
        </Button>
      </Grid>
      <DataGrid
        tableTitle="Your Events"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
        onRowClick={(values) => {
          navigate(
            `/organizer/events/${encodeURIComponent(values.id)}/dashboard`
          );
        }}
      />
    </PageContainer>
  );
};

const EventList = () => (
  <ControlledDataGrid>
    <EventListGrid />
  </ControlledDataGrid>
);

export default EventList;
