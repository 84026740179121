import React from 'react';

import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import DatePicker from 'components/datePicker';
import { FormControl, SxProps } from '@mui/material';

interface DateProps {
  label: string;
  margin?: 'normal' | 'dense' | 'none';
  sx?: SxProps;
}

export type DateFormFieldProps<T extends FieldValues> = DateProps &
  UseControllerProps<T>;

const DateFormField = <T extends FieldValues>(props: DateFormFieldProps<T>) => {
  const { label, name, control, margin = 'normal', sx } = props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });

  return (
    <FormControl error={Boolean(errors[name])} margin={margin} sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            onChange={field.onChange}
            value={field.value}
            label={label}
          />
        )}
      />
    </FormControl>
  );
};

export default DateFormField;
