import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { SelectOption, FilterProps } from '../filterTypes';

export default function SelectFilter({
  id,
  label,
  value = '',
  options = [],
  onChange,
}: FilterProps) {
  return (
    <FormControl fullWidth sx={{ minWidth: '10rem' }}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        id={id}
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option: SelectOption) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
