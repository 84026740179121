import { useEffect, useState } from 'react';

import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useCallService } from 'hooks';

import EventCard, {
  EventCardProps,
} from 'components/cards/EventCard/EventCard';
import { EventCardSkeleton } from 'components/cards/EventCard';

import Button from 'components/atomic/Button';
import DateRangePicker from 'components/datePicker/DateRangePicker';
import GoogleMapsAutoComplete from 'components/autocomplete/AutoComplete';

import httpRoutes from 'utils/httpRoutes';
import EmptyState from 'components/EmptyState';

import useFilterStore from 'states/filterState';
import { ClearAll, ExpandMoreOutlined } from '@mui/icons-material';
import { TuneIcon } from 'material-icons';

const DEFAULT_EVENT_LIST: EventCardProps[] = [];

export interface GetUpcomingEventsRequest {
  page: number;
  limit: number;
  order: 'ASC' | 'DESC';
  orderBy: string;
  name: string;
  latitude?: number;
  longitude?: number;
  radius?: number | string;
  city: string;
  state: string;
  cannabisConsumption: string;
  virtual: string;
  categoryId?: number | string;
  startDate: Date | null;
  endDate: Date | null;
}

const LoadingEvents = () => {
  const limit = 16;
  const eventSkeletons = [];

  for (let index = 0; index < limit; index++) {
    eventSkeletons.push(<EventCardSkeleton key={index} />);
  }

  return (
    <Grid container spacing={{ xs: 2, md: 2, lg: 3 }}>
      {eventSkeletons}
    </Grid>
  );
};

const SelectWrapper = ({
  label,
  value,
  onChange,
  options,
}: {
  label: string;
  value: any;
  onChange: any;
  options: any[];
}) => {
  return (
    <Grid item xs={6} md={3}>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>{label}</InputLabel>
        <Select disableUnderline value={value} onChange={onChange}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

let upcomingEventsRequest: AbortController | undefined;

function EventList() {
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState(DEFAULT_EVENT_LIST);
  const [eventCategories, setEventCategories] = useState<any[]>([]);
  const filterStore = useFilterStore();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [totalRows, setTotalRows] = useState(0);
  const [locationChecked, setLocationChecked] = useState<boolean>(false);

  const { callService } = useCallService();

  const setCoordinates = (pos?: {
    coords: { longitude: number; latitude: number };
  }) => {
    if (pos?.coords) {
      console.log('POS COORDS: ', pos.coords);
      setLocationChecked(true);

      filterStore.update({
        ...filterStore.state,
        longitude: pos.coords.longitude,
        latitude: pos.coords.latitude,
      });
    }
    setLocationChecked(true);
  };

  const getEvents = async () => {
    setLoading(true);

    if (upcomingEventsRequest) {
      upcomingEventsRequest.abort();
    }

    try {
      upcomingEventsRequest = new AbortController();

      const { response } = await callService({
        resource: httpRoutes.events.getUpcomingEvents({
          controller: upcomingEventsRequest,
          payload: filterStore.state,
        }),
      });

      if (response) {
        const _events: EventCardProps[] = response?.items.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            startsAt: item.startsAt,
            ticketsStartingAt: item.ticketsStartingAt,
            nameSlug: item.nameSlug,
            cannabisConsumption: item.cannabisConsumption,
            headerImageUrl: item.headerImageUrl,
            location: item.venueCity
              ? item.venueCity + ', ' + item.venueState
              : '',
            timezone: item.timezone,
          };
        });

        setTotalRows(response.totalRows);
        setEvents(_events);
        upcomingEventsRequest = undefined;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setCoordinates);
    } else {
      setLocationChecked(true);
      alert('Location services not available.');
    }
  }, []);

  useEffect(() => {
    getEvents();
  }, [filterStore.state]);

  useEffect(() => {
    const getEventCategories = async () => {
      try {
        const { response } = await callService({
          resource: httpRoutes.events.getEventCategories(),
        });

        if (response) {
          const _eventCategories: any[] = [
            {
              value: '',
              label: 'All Categories',
            },
          ];

          response.forEach((item: { id: number; name: string }) => {
            _eventCategories.push({
              value: item.id,
              label: item.name,
            });
          });

          setEventCategories(_eventCategories);
        }
      } catch (e) {
        console.log(e);
      }
    };

    getEventCategories();
  }, []);

  return (
    <Grid
      maxWidth="xl"
      container
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      sx={{ p: { xs: 1, sm: 1, md: 2, large: 3 }, margin: 'auto' }}
    >
      {/* Filter Start */}
      <Accordion sx={{ mb: 1 }} defaultExpanded={!matches}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container direction="row">
            <Grid item sx={{ mr: 0.5 }}>
              <TuneIcon />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h3">Event Filters</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="flex-end">
            <Button
              onClick={filterStore.clear}
              variant="text"
              startIcon={<ClearAll />}
            >
              CLEAR
            </Button>
          </Grid>
          <Grid container>
            <SelectWrapper
              label="Event Type"
              value={filterStore.state.virtual}
              onChange={(value: any) => {
                filterStore.update({
                  ...filterStore.state,
                  virtual: value.target.value,
                });
              }}
              options={[
                { label: 'All', value: '' },
                { label: 'In-Person', value: false },
                { label: 'Virtual', value: true },
              ]}
            />
            <SelectWrapper
              label="Category"
              value={filterStore.state.categoryId}
              onChange={(value: any) => {
                if (value.target.value === '') {
                  filterStore.update({
                    ...filterStore.state,
                    categoryId: undefined,
                  });
                } else {
                  filterStore.update({
                    ...filterStore.state,
                    categoryId: value.target.value,
                  });
                }
              }}
              options={eventCategories}
            />
            <SelectWrapper
              label="Consumption"
              value={filterStore.state.cannabisConsumption}
              onChange={(value: any) => {
                filterStore.update({
                  ...filterStore.state,
                  cannabisConsumption: value.target.value,
                });
              }}
              options={[
                {
                  label: 'All',
                  value: '',
                },
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
            />
            <SelectWrapper
              label="Distance"
              value={filterStore.state.radius}
              onChange={(value: any) => {
                filterStore.update({
                  ...filterStore.state,
                  radius: value.target.value,
                });
              }}
              options={[
                {
                  label: '25 miles',
                  value: 25,
                },
                {
                  label: '50 miles',
                  value: 50,
                },
                {
                  label: '100 miles',
                  value: 100,
                },
                {
                  label: 'Any distance',
                  value: 0,
                },
              ]}
            />
          </Grid>
          <Grid container spacing={1} pt={1}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Search Events By Name"
                variant="filled"
                fullWidth
                key={'name'}
                value={filterStore.state.name || ''}
                onChange={(value: any) => {
                  filterStore.update({
                    ...filterStore.state,
                    name: value.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <GoogleMapsAutoComplete
                onChange={(value) => {
                  let state = '';
                  let city = '';

                  if (value.addressComponents) {
                    for (let i = 0; i < value.addressComponents.length; i++) {
                      const item = value.addressComponents[i];

                      if (item.types[0] === 'locality') {
                        city = item.short_name;
                      }

                      if (item.types[0] === 'administrative_area_level_1') {
                        state = item.short_name;
                      }
                    }
                  }

                  const _lat = value.latitude;
                  const _lng = value.longitude;

                  if (state !== '' && value.addressComponents) {
                    filterStore.update({
                      ...filterStore.state,
                      city,
                      state,
                      page: 0,
                    });
                  } else if (value.latitude) {
                    filterStore.update({
                      ...filterStore.state,
                      latitude: _lat,
                      longitude: _lng,
                      radius: 50,
                      page: 0,
                    });
                  } else {
                    filterStore.update({
                      ...filterStore.state,
                      city: '',
                      state: '',
                      radius: 0,
                      page: 0,
                    });
                  }
                }}
                label="Search Near You"
                placeholder="Los Angeles, CA"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DateRangePicker
                onChange={(value) => {
                  const _startDate = value[0] ? new Date(value[0]) : null;
                  const _endDate = value[1] ? new Date(value[1]) : null;

                  _endDate?.setDate(_endDate.getDate() + 1);

                  filterStore.update({
                    ...filterStore.state,
                    startDate: _startDate,
                    endDate: _endDate,
                  });
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {loading && <LoadingEvents />}
      {!loading && events.length > 0 && (
        <Grid container spacing={{ xs: 0, md: 2, lg: 3 }}>
          {events.map((item: any) => (
            <Grid
              key={item.id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{ mb: 2 }}
            >
              <EventCard item={item} />
            </Grid>
          ))}
        </Grid>
      )}
      {!loading && events.length === 0 && (
        <EmptyState>
          <Grid container justifyContent="center" mt={5}>
            <Grid item xs={12}>
              <Typography textAlign="center">
                No events found that match your search criteria.
              </Typography>
            </Grid>
          </Grid>
        </EmptyState>
      )}

      <Grid container justifyContent="space-around" mt={3}>
        {/* Pagination Buttons */}
        <Button
          variant="contained"
          onClick={() => {
            filterStore.update({
              ...filterStore.state,
              page: (filterStore.state.page -= 1),
            });
          }}
          size="large"
          startIcon={<ArrowBackIosNewIcon />}
          disabled={filterStore.state.page === 0}
        >
          PREV
        </Button>
        <Button
          onClick={() => {
            filterStore.update({
              ...filterStore.state,
              page: (filterStore.state.page += 1),
            });
          }}
          size="large"
          endIcon={<NavigateNextIcon />}
          disabled={
            (filterStore.state.page + 1) * filterStore.state.limit >= totalRows
          }
        >
          NEXT
        </Button>
      </Grid>
    </Grid>
  );
}

export default EventList;
