import React, {
  createContext,
  useContext,
  memo,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';

import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { FilterType } from 'components/filters/filterTypes';

import Header from './Header';
import TableHeader from './TableHeader';
import TableBodyRow from './TableBodyRow';
import Pagination, { PaginationInterface } from './Pagination';
import { DataGridColumnProps } from './DataGridColumn';
import StyledNoRowsOverlay from './StyledNoRowsOverlay';
import LoadingTableBody from './LoadingTableBody';

import { formatCurrency } from 'utils/helpers';
import { formatDateTime } from 'utils/formatDateTime';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const Container = styled(Box)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export type LoadingState = {
  data: boolean;
  exporting: boolean;
};

export type DataGridModel = {
  rows: any[];
  totalRows: number;
};

const INITIAL_DATAGRID_STATE: PaginationInterface = {
  page: 0,
  limit: 50,
  order: 'desc',
  orderBy: '',
};

export const DataGridContext = createContext<{
  state: PaginationInterface;
  setState: Dispatch<SetStateAction<PaginationInterface>>;
}>({ state: INITIAL_DATAGRID_STATE, setState: () => null });

const DataGridProvider = DataGridContext.Provider;

export const useDataGridState = () => {
  const { state, setState } = useContext(DataGridContext);

  return { state, setState };
};

export const ControlledDataGrid = ({ children }: any) => {
  const [state, setState] = useState(INITIAL_DATAGRID_STATE);

  return (
    <DataGridProvider value={{ state, setState }}>{children}</DataGridProvider>
  );
};

interface DataGridProps {
  totalRows: number;
  filters?: FilterType[];
  handleFilterChange?: (value: any) => void;
  rows: any[];
  columns: DataGridColumnProps[];
  loading?: boolean;
  actionColumn?: any;
  showEmptyRows?: boolean;
  rowIdentifier?: string | number;
  onCellClick?: (params: any) => void;
  onRowClick?: (params: any) => void;
  handleExport?: () => void;
  tableTitle?: string;
}

const DataGrid = ({
  totalRows,
  filters,
  handleFilterChange,
  rows,
  columns,
  loading,
  actionColumn: ActionColumn,
  showEmptyRows = false,
  rowIdentifier = 'id',
  onCellClick,
  onRowClick,
  handleExport,
  tableTitle,
}: DataGridProps) => {
  const { state, setState } = useContext(DataGridContext);

  const { order, orderBy, page, limit } = state;

  const onPageChange = (_: any, page: any) => {
    setState((prevState: any) => ({ ...prevState, page }));
  };

  const onRowsPerPageChange = (event: any) => {
    const limit = event.target.value;
    setState((prevState: any) => ({ ...prevState, limit }));
  };

  const onSortColumn = (field: string, order: string) => {
    const orderBy = field;

    setState((prevState: any) => {
      if (prevState.orderBy == orderBy && prevState.order === order) {
        return { ...prevState, order: 'desc', orderBy: '' };
      }

      return { ...prevState, order, orderBy };
    });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    setState((prevState) => ({
      ...prevState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    }));
  };

  const renderFormattedValue = (value: any, type: any, label: any) => {
    if (type !== 'boolean' && !value && value !== 0) {
      return '-';
    }

    let response = value;

    /* eslint-disable no-restricted-globals */
    if (type) {
      if (type === 'fixed' && !isNaN(value)) {
        response = Number(value).toFixed(2);
      }

      if (type === 'percentage') {
        response = `${(Number(value) * 100).toPrecision(4)} %`;
      }

      if (type === 'usdCurrency') {
        response = formatCurrency(Number(value));
      }

      if (type === 'dateTime') {
        response = formatDateTime(new Date(value));
      }

      if (type === 'boolean') {
        if (value) {
          return <CheckRoundedIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />;
        } else {
          return <CloseRoundedIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />;
        }
      }
    }

    if (label) {
      response += label;
    }

    return response;
  };

  const emptyRows = limit - rows.length;

  return (
    <Container>
      <TableContainer sx={{ overflowY: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            borderRadius: '15px',
            flexDirection: 'column',
          }}
        >
          <Header
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleExport={handleExport}
            tableTitle={tableTitle}
          />
          <Pagination
            totalRows={totalRows}
            page={page}
            rowsPerPage={limit}
            handlePageChange={onPageChange}
            handleRowsPerPageChange={onRowsPerPageChange}
          />
          <Table sx={{ width: '100%' }}>
            <TableHeader
              columns={columns}
              orderBy={orderBy}
              order={order}
              onRequestSort={handleRequestSort}
            />
            {loading && (
              <TableBody>
                <LoadingTableBody rowsPerPage={limit} columns={columns} />
              </TableBody>
            )}

            {!loading && rows.length > 0 && (
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <TableBodyRow
                      key={index}
                      handleCellClick={onCellClick}
                      handleRowClick={onRowClick}
                      row={row}
                      rowIdentifier={rowIdentifier}
                      columns={columns}
                      actionColumn={ActionColumn}
                      renderFormattedValue={renderFormattedValue}
                    />
                  );
                })}
                {emptyRows > 0 && showEmptyRows && (
                  <TableRow style={{ height: 52 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {!loading && rows.length === 0 && <StyledNoRowsOverlay />}
        </Box>
        <Pagination
          totalRows={totalRows}
          page={page}
          rowsPerPage={limit}
          handlePageChange={onPageChange}
          handleRowsPerPageChange={onRowsPerPageChange}
        />
      </TableContainer>
    </Container>
  );
};

export default memo(DataGrid);
