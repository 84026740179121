import { ReactElement, ReactNode } from 'react';

import { Grid } from '@mui/material';

const EmptyState = ({ children }: { children: ReactNode }) => {
  return (
    <Grid container sx={{ height: '100vh', width: 'inherit', mt: 2 }}>
      {children}
    </Grid>
  );
};

export default EmptyState;
