// material-ui
import { Box, Button } from '@mui/material';

// third party
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

// project imports
import { EmailFormField } from 'components/formFields';

import authResources from 'features/auth/resources';

import useCallService from 'hooks/useCallService';

// ============================|| AUTH - FORGOT PASSWORD ||============================ //

interface IForgotPasswordInputs {
  email: string;
}

const AuthForgotPassword = ({
  setIsSubmitted,
}: {
  setIsSubmitted: (value: boolean) => void;
}) => {
  const { callService } = useCallService();

  const onSubmit = async (values: IForgotPasswordInputs) => {
    await callService({
      resource: authResources.resetPasswordRequest(values),
    });

    setIsSubmitted(true);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IForgotPasswordInputs>({
    defaultValues: {
      email: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailFormField name="email" control={control} label="Email" />

        <Box sx={{ mt: 2 }}>
          <Button
            disableElevation
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
          >
            Submit
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AuthForgotPassword;
