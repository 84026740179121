import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { Box, Button, Stack } from '@mui/material';

// third party
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  EmailFormField,
  PasswordFormField,
  CheckboxFormField,
} from 'components/formFields';

import { useAuthDispatcher } from 'providers/AuthProvider/hooks';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

export interface ILoginFormInputs {
  email: string;
  password: string;
  rememberMe: boolean;
}

const AdminAuthLogin = ({ onSuccess }: { onSuccess: () => void }) => {
  const { login } = useAuthDispatcher();
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const navigate = useNavigate();

  const onSubmit = async (values: ILoginFormInputs) => {
    const { response } = await callService({
      resource: httpRoutes.admin.login(values),
      successMessage: 'Logged in successfully!',
    });

    if (response) {
      login(response);
      onSuccess();
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().max(255).required('Password is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ILoginFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailFormField name="email" control={control} label="Email" />

        <PasswordFormField name="password" control={control} label="Password" />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <CheckboxFormField
            name="rememberMe"
            control={control}
            label="Remember me"
          />
          <Button
            variant="text"
            onClick={() => {
              navigate('/forgot-password');
              hideDialog();
            }}
          >
            Forgot Password?
          </Button>
        </Stack>

        <Box sx={{ mt: 2 }}>
          <Button
            disableElevation
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
          >
            Sign in
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AdminAuthLogin;
