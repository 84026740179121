import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import AppBar from 'layout/MainLayout/AppBar';
import { Outlet } from 'react-router-dom';
import Drawer from './Drawer';

const AdminLayout = () => {
  return (
    <Stack sx={{ margin: 0, padding: 0 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: '#f4f2f5',
        }}
      >
        <AppBar />
        <Card
          sx={{
            p: { xs: 1, sm: 2, md: 3, lg: 4 },
            ml: 4,
            borderRadius: '15px',
          }}
          elevation={2}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} mt={2}>
              <Grid container>
                {' '}
                <Drawer />
                <Typography>Admin Layout</Typography>
                <Grid item xs={12}>
                  <Outlet />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Stack>
  );
};

export default AdminLayout;
