import { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import {
  CreateSponsorshipType,
  DeleteSponsorshipType,
  EditSponsorshipType,
  CreateSponsorship,
  EditSponsorship,
} from 'features/sponsorships/components/Modals';
import { ISponsorshipTypeFormInputs } from 'features/sponsorships/forms/SponsorshipTypeForm';

import { useCallService } from 'hooks';

import { AddIcon, DeleteIcon, EditIcon, SponsorshipIcon } from 'material-icons';

import { IEvent, INITIAL_EVENT_STATE } from 'layout/OrganizerEventLayout';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import { formatDate } from 'utils/formatDateTime';

import PageContainer from 'components/PageContainer';
import { FilterState } from 'components/filters/Filters';
import { FilterType } from 'components/filters/filterTypes';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';

const loadingState = {
  data: true,
  exporting: true,
};

const EventSponsorshipTypesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();

  const params = useParams();

  const { id = 0 } = params;

  const [sponsorship, setSponsorship] = useState<any>();
  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [eventDetails, setEventDetails] = useState<IEvent>(INITIAL_EVENT_STATE);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    const getEvent = async () => {
      try {
        const { response } = await callService({
          resource: httpRoutes.organizer.getEvent(Number(id)),
        });

        if (response) {
          setEventDetails(response.eventDetails);
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (id > 0) getEvent();
  }, []);

  const getSponsorship = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.getEventSponsorship(Number(id)),
      });

      if (response) {
        setSponsorship(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prev) => ({ ...prev, sponsorship: false }));
    }
  };

  const getSponsorshipTypes = async () => {
    try {
      setLoading((prev) => ({ ...prev, data: true }));
      const { response } = await callService({
        resource: httpRoutes.organizer.getSponsorshipTypes({
          eventId: Number(id),
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prev) => ({ ...prev, data: false }));
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getSponsorshipTypes();
  }, [filters, state]);

  useEffect(() => {
    getSponsorship();
  }, []);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Sponsorship Type',
      field: 'name',
      sortable: true,
      filterable: true,
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'active',
      sortable: true,
      filterable: true,
      type: 'boolean',
    },
    {
      headerName: 'Sales Start',
      field: 'saleStartDate',
      sortable: true,
      filterable: false,
      hide: true,
      type: 'dateTime',
      flex: 0.5,
    },
    {
      headerName: 'Sales End',
      field: 'saleEndDate',
      sortable: true,
      filterable: false,
      hide: true,
      type: 'dateTime',
      flex: 0.5,
    },
    {
      headerName: 'Price',
      field: 'price',
      sortable: true,
      filterable: true,
      type: 'usdCurrency',
      flex: 0.5,
      align: 'right',
    },
    {
      headerName: 'Remaining/Quantity',
      field: 'quantityRemaining',
      align: 'right',
      headerAlign: 'right',
      filterable: false,
      sortable: false,
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <>
            <>
              {Intl.NumberFormat('en-US').format(params.row.quantityRemaining)}/
              {Intl.NumberFormat('en-US').format(params.row.quantity)}
            </>
          </>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton onClick={() => showEditModal(params.row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => showDeleteModal(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
    {
      headerName: 'Sponsorships',
      field: 'mobile',
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <Grid
            container
            height="100%"
            m={0.5}
            p={1}
            justifyContent="space-between"
            flexDirection="row"
            spacing={1}
          >
            <Grid item xs={4}>
              <Typography variant="caption">Name</Typography>
              <Typography fontWeight="bold">{params.row.name}</Typography>

              <Typography variant="caption">Price:</Typography>
              <Typography>{formatCurrency(params.row.price)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Quantity</Typography>
              <Typography>
                {Intl.NumberFormat('en-US').format(
                  params.row.quantityRemaining
                )}
                / {Intl.NumberFormat('en-US').format(params.row.quantity)}
              </Typography>
              <Typography variant="caption">Sales Start</Typography>

              <Typography>{formatDate(params.row.saleStartDate)}</Typography>
              <Typography variant="caption">Sales End</Typography>

              <Typography>{formatDate(params.row.saleEndDate)}</Typography>
            </Grid>
            <Stack>
              <IconButton onClick={() => showEditModal(params.row)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => showDeleteModal(params.row)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Stack>
          </Grid>
        );
      },
    },
  ];

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Sponsorship Type Name',
    },
  ];

  const showCreateSponsorshipModal = () => {
    showDialog({
      title: 'Create A Sponsorship',
      size: 'md',
      content: (
        <CreateSponsorship
          eventId={Number(id)}
          onSuccess={getSponsorship}
          hideDialog={hideDialog}
        />
      ),
    });
  };

  const showEditSponsorshipModal = () => {
    showDialog({
      title: 'Edit Sponsorship',
      size: 'md',
      content: (
        <EditSponsorship
          eventId={Number(id)}
          sponsorship={sponsorship}
          onSuccess={getSponsorship}
          hideDialog={hideDialog}
        />
      ),
    });
  };

  const showCreateModal = () => {
    showDialog({
      title: 'Add Sponsorship Type',
      content: (
        <CreateSponsorshipType
          eventId={Number(id)}
          eventDetails={eventDetails}
          sponsorshipId={sponsorship.id}
          onSuccess={getSponsorshipTypes}
          hideDialog={hideDialog}
        />
      ),
    });
  };

  const showEditModal = (values: ISponsorshipTypeFormInputs) => {
    showDialog({
      title: 'Edit Sponsorship Type',
      content: (
        <EditSponsorshipType
          eventId={Number(id)}
          eventDetails={eventDetails}
          sponsorshipId={sponsorship.id}
          sponsorshipTypeId={Number(values.id)}
          onSuccess={getSponsorshipTypes}
          hideDialog={hideDialog}
        />
      ),
    });
  };

  const showDeleteModal = (value: ISponsorshipTypeFormInputs) => {
    showDialog({
      title: 'Delete Sponsorship Type',
      size: 'xs',
      content: (
        <DeleteSponsorshipType
          sponsorshipId={Number(value.id)}
          sponsorship={value}
          onSuccess={getSponsorshipTypes}
          hideDialog={hideDialog}
        />
      ),
    });
  };

  return (
    <PageContainer>
      <Stack
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={1}
      >
        <Button
          variant={sponsorship ? 'text' : 'contained'}
          onClick={() => {
            if (sponsorship) showEditSponsorshipModal();
            else showCreateSponsorshipModal();
          }}
          startIcon={<SponsorshipIcon />}
        >
          {sponsorship ? 'Edit Sponsorship' : 'Create Sponsorship'}
        </Button>
        <Button
          variant="contained"
          onClick={showCreateModal}
          startIcon={<AddIcon />}
          disabled={!sponsorship}
        >
          Add Sponsorship Type
        </Button>
      </Stack>
      <DataGrid
        tableTitle="Event Sponsorship Types"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
      />
    </PageContainer>
  );
};

const EventSponsorshipTypes = () => (
  <ControlledDataGrid>
    <EventSponsorshipTypesGrid />
  </ControlledDataGrid>
);

export default EventSponsorshipTypes;
