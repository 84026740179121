import { RouteObject, Navigate, useParams } from 'react-router-dom';

import {
  ForgotPassword,
  Login,
  ResetPassword,
  ValidateEmail,
} from 'features/auth/pages';

import Legal from 'public/Legal';
import RefundPolicyPage from 'public/RefundPolicy';

import { Features } from 'public';
import EventSponsorships from 'features/upcomingEvents/pages/EventSponsorship';
import { HomePage, EventDetails } from 'features/upcomingEvents/pages';
import { Login as AdminLogin } from 'features/admin/pages';
import Create from 'features/events/components/Create';

const EventRedirect = () => {
  const { slug } = useParams();

  return <Navigate to={`/events/${slug}`} replace />;
};

export const PublicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/event/:slug',
    element: <EventRedirect />,
  },
  {
    path: '/events/:slug',
    element: <EventDetails />,
  },
  {
    path: '/events/:slug/sponsorship',
    element: <EventSponsorships />,
  },
  {
    path: '/events/create',
    element: <Create />,
  },
  {
    path: '/features',
    element: <Features />,
  },
  {
    path: '/legal',
    element: <Legal />,
  },
  {
    path: '/terms-and-conditions',
    element: <Legal />,
  },
  {
    path: '/refund-policy',
    element: <RefundPolicyPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/admin/login',
    element: <AdminLogin />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/validate-email/:token',
    element: <ValidateEmail />,
  },
];
