import React from 'react';

import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { TextField } from '@mui/material';

interface NumberFieldProps {
  label: string;
  placeholder?: string;
  icon?: React.ReactElement;
  position?: 'start' | 'end';
  margin?: 'normal' | 'dense' | 'none';
  multiline?: boolean;
  minRows?: number;
  disabled?: boolean;
  required?: boolean;
  onBlur?: (e: any) => void;
  id?: string;
  rest?: any;
  muted?: boolean;
}

export type NumberFormFieldProps<T extends FieldValues> = NumberFieldProps &
  UseControllerProps<T>;

const NumberFormField = <T extends FieldValues>(
  props: NumberFormFieldProps<T>
) => {
  const {
    label,
    name,
    control,
    margin = 'normal',
    multiline,
    minRows = 1,
    placeholder,
    position,
    icon,
    disabled,
    required,
    onBlur,
    id,
    rest,
    muted,
  } = props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });
  let inputProps = {};

  if (icon) {
    if (position === 'start') {
      inputProps = {
        startAdornment: icon,
      };
    }

    if (position === 'end') {
      inputProps = {
        endAdornment: icon,
      };
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          type="number"
          id={id}
          {...field}
          {...rest}
          variant={muted ? 'filled' : 'outlined'}
          label={label}
          fullWidth
          margin={margin}
          multiline={multiline}
          minRows={minRows}
          error={Boolean(errors[name])}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          helperText={
            errors[name] ? (
              <span color="error">{errors[name]?.message?.toString()}</span>
            ) : (
              ' '
            )
          }
          onBlur={onBlur}
          InputProps={inputProps}
        />
      )}
    />
  );
};

export default NumberFormField;
