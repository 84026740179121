import React, { memo, useCallback } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import IconButton from 'components/buttons/IconButton';
import { CloseIcon } from 'material-icons';

export interface RightDrawerProps {
  isOpen: boolean;
  title?: string;
  content: React.ReactNode;
  setIsOpen: (value: boolean) => void;
  disableEscapeKeyDown?: boolean;
  enableBackdropClose?: boolean;
  width?: number; // add sizes here
}

const RightDrawer = ({
  isOpen,
  title,
  content,
  setIsOpen,
  disableEscapeKeyDown,
  enableBackdropClose = true,
  width = 360,
}: RightDrawerProps) => {
  const handleBackdropClick = useCallback(() => {
    setIsOpen(false);
  }, [enableBackdropClose]);

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const respWidth = matchDownSM ? '100%' : width;
  console.log('Resp Width: ', respWidth);

  if (matchDownSM) {
    return (
      <MuiDrawer
        open={isOpen}
        anchor="right"
        disableEscapeKeyDown={disableEscapeKeyDown}
        onBackdropClick={handleBackdropClick}
        sx={{ zIndex: 5000 }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ m: 0.5 }}>
            <IconButton icon={<CloseIcon />} onClick={handleBackdropClick} />
          </Box>
          <Divider />
          {title && (
            <>
              <Typography variant="h3" sx={{ mt: 2, ml: 2, mb: 2 }}>
                {title}
              </Typography>
              <Divider />
            </>
          )}
          {content}
        </Box>
      </MuiDrawer>
    );
  } else {
    return (
      <MuiDrawer
        open={isOpen}
        anchor="right"
        disableEscapeKeyDown={disableEscapeKeyDown}
        onBackdropClick={handleBackdropClick}
        sx={{ zIndex: 5000 }}
      >
        <Box sx={{ width: { width } }}>
          <Box sx={{ m: 0.5 }}>
            <IconButton icon={<CloseIcon />} onClick={handleBackdropClick} />
          </Box>
          <Divider />
          {title && (
            <>
              <Typography variant="h3" sx={{ mt: 2, ml: 2, mb: 2 }}>
                {title}
              </Typography>
              <Divider />
            </>
          )}
          {content}
        </Box>
      </MuiDrawer>
    );
  }
};

export default memo(RightDrawer);
