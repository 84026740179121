import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material';

import Logo from 'components/Logo';

import { useAuthState } from 'providers/AuthProvider/hooks';

import AuthWrapper from '../../../auth/components/AuthWrapper';
import AdminAuthLogin from '../components/AdminAuthLogin';

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const { authState } = useAuthState();

  const onSuccess = () => {
    navigate('/admin/events');
  };

  useEffect(() => {
    if (authState.authenticated) {
      onSuccess();
    }
  }, [authState]);

  return (
    <AuthWrapper>
      <Grid
        container
        maxWidth="sm"
        justifyContent="center"
        sx={{ margin: 'auto', pt: 3 }}
      >
        <Paper elevation={1} sx={{ p: 2 }}>
          <Grid container justifyContent="center">
            <Grid item sx={{ mb: 3 }} justifyContent="center">
              <Logo />
            </Grid>
            <Grid item xs={12}>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <>
                  <Typography
                    color={theme.palette.secondary.main}
                    gutterBottom
                    fontWeight="bold"
                    fontFamily="Montserrat"
                    fontSize={matchDownSM ? '24px' : '36px'}
                    sx={{
                      textShadow: '1px 1px 1px rgb(122, 122, 122, 0.45)',
                    }}
                  >
                    EventHi Admin
                  </Typography>
                  <Typography
                    variant="caption"
                    fontSize="16px"
                    pt={3}
                    textAlign={matchDownSM ? 'center' : 'inherit'}
                  >
                    Enter your credentials to continue
                  </Typography>
                </>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <AdminAuthLogin onSuccess={onSuccess} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
