import { useEffect, useState } from 'react';
import fileSaver from 'file-saver';

import { Button, Divider, Grid, Typography } from '@mui/material';

import LoadingButton from 'components/buttons/LoadingButton';

import DisplayText from 'components/DisplayText';
import Loading from 'components/Loading';

import EditOrder from '../dialogs/EditOrder';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useRightDrawerDispatcher } from 'providers/RightDrawerProvider/hooks/useRightDrawerDispatcher';

import EventProps from 'types/Event';
import TransactionProps from 'types/Transactions';
import TransactionItemProps from 'types/TransactionItems';

import { DownloadIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';

import { REACT_APP_CLOUDFRONT_URL } from 'config/config';
import { EventFeeTypes } from 'utils/calculateCCFees';

export interface OrderDetailsProps extends Partial<TransactionProps> {
  id: number;
  subtotal: string;
  discount: number;
  itemFee: string;
  serviceFee: string;
  processingFee: string;
  total: string;
  date: Date;
  status: string;
  event: EventProps;
  transactionItem: Partial<TransactionItemProps>[];
  type: 'Sponsorship' | 'Ticket' | 'Refund';
  fees: EventFeeTypes;
}

export const INITIAL_STATE: OrderDetailsProps = {
  id: 0,
  subtotal: '',
  discount: 0,
  itemFee: '',
  serviceFee: '',
  processingFee: '',
  total: '',
  date: new Date(),
  status: '',
  transactionItem: [],
  event: {
    id: 0,
    name: '',
    imageUrl: '',
    startsAt: new Date(),
    endsAt: new Date(),
  },
  type: 'Ticket',
  fees: {
    itemFee: 1.99,
    serviceFee: 0.039,
    processingFee: 0.04,
  },
};

type LoadingType = {
  data: boolean;
  downloading: boolean;
};
const loadingState: LoadingType = { data: true, downloading: false };

const OrderSummary = ({ orderId }: { orderId: number }) => {
  const [loading, setLoading] = useState<LoadingType>(loadingState);
  const [details, setDetails] = useState<OrderDetailsProps>(INITIAL_STATE);

  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();
  const { hideRightDrawer } = useRightDrawerDispatcher();

  const downloadTickets = async () => {
    setLoading((prevState) => ({ ...prevState, downloading: true }));

    try {
      const { response } = await callService({
        resource: httpRoutes.user.downloadTickets(orderId),
      });

      if (response) {
        // update file name
        const _fileName = `${details.event.name}-Tickets.pdf`;

        const _fileData = new Blob([response], { type: 'application/pdf' });

        fileSaver.saveAs(_fileData, _fileName);
      }
    } finally {
      setLoading((prevState) => ({ ...prevState, downloading: false }));
    }
  };

  const editOrder = () => {
    hideRightDrawer();

    showDialog({
      title: 'Edit Order',
      size: 'md',
      content: <EditOrder order={details} hideDialog={hideDialog} />,
    });
  };

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    const getOrderDetails = async (orderId: number) => {
      const { response } = await callService({
        resource: httpRoutes.user.getOrderDetails(orderId),
      });

      if (response) {
        const imageUrl = response.imageUrl || 'EventHiDefault.jpeg';
        setDetails({ imageUrl, fees: response.fees, ...response.result });
        setLoading((prevState) => ({ ...prevState, data: false }));
      }
    };

    getOrderDetails(orderId);
  }, [orderId]);

  if (loading) <Loading />;

  return (
    <>
      <Grid container justifyContent="center" sx={{ m: 0, p: 0 }}>
        <Grid item xs={12} p={1}>
          <Typography align="center" variant="h2">
            {details.event.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ background: 'white' }}>
          {/* Image */}
          <img
            src={`${REACT_APP_CLOUDFRONT_URL}/${details.event.imageUrl}`}
            alt={details.event.name}
            style={{
              top: '0',
              left: '0',
              position: 'relative',
              aspectRatio: '2/1',
              objectFit: 'contain',
              height: '24vh',
              width: '100%',
            }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <LoadingButton
            variant="text"
            icon={<DownloadIcon />}
            onClick={downloadTickets}
            loading={loading.downloading}
          >
            Download{' '}
            {details.type === 'Sponsorship' ? 'Sponsorships' : 'Tickets'}
          </LoadingButton>
        </Grid>
        {details.type !== 'Refund' && (
          <Grid item>
            <Button onClick={editOrder}>Edit Order</Button>
          </Grid>
        )}
      </Grid>
      {/* Cart Summary */}
      <Grid item p={2} mt={3}>
        <Typography mb={2} fontWeight="semibold">
          Items
        </Typography>
        {/* Cart Items */}
        <Grid item sx={{ height: '38vh', overflowY: 'scroll' }}>
          {details.transactionItem.map((item: any) => {
            if (item.quantity > 0) {
              return (
                <Grid
                  key={item.id}
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                  mb={1}
                >
                  <Grid item xs={9}>
                    {item.eventTickets.length > 0 && (
                      <Typography fontSize="12px">
                        {item.quantity} X{' '}
                        {item.eventTickets[0].eventTicketType.name}
                      </Typography>
                    )}
                    {item.eventSponsorships.length > 0 && (
                      <Typography fontSize="12px">
                        {item.quantity} X{' '}
                        {item.eventSponsorships[0].eventSponsorshipType.name}
                      </Typography>
                    )}
                    {item.discountCode && (
                      <DisplayText
                        style={{ marginLeft: 2, marginTop: 2, color: 'blue' }}
                        type="bodyXSmall"
                        text={`${
                          item.discountCode.discountCode
                        } (${formatCurrency(
                          item.discountCode.discountAmount
                        )}) applied`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography textAlign="right" alignSelf="center">
                      {formatCurrency(Number(item.subtotal))}
                    </Typography>
                    {item.discount > 0 && (
                      <>
                        <Typography
                          textAlign="right"
                          alignSelf="center"
                          sx={{ color: 'blue' }}
                        >
                          - {formatCurrency(item.discount)}
                        </Typography>
                        <Divider />
                        <Typography textAlign="right" alignSelf="center">
                          {formatCurrency(
                            Number(item.subtotal) - Number(item.discount)
                          )}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              );
            }
          })}
        </Grid>
        {/* Cart Totals */}
        <Divider />
        <Grid
          mt={2}
          container
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography>Subtotal</Typography>
          <Typography>
            {formatCurrency(
              Number(details.subtotal) + Number(details.discount)
            )}
          </Typography>{' '}
        </Grid>
        <Grid
          mt={1}
          mb={1}
          container
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography>Discounts</Typography>
          <Typography>- {formatCurrency(details.discount)}</Typography>
        </Grid>
        <Grid
          mt={1}
          mb={1}
          container
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography>Fees</Typography>
          <Typography>
            {formatCurrency(
              Number(details.itemFee) +
                Number(details.processingFee) +
                Number(details.serviceFee)
            )}
          </Typography>
        </Grid>
        <Divider />
        <Grid
          mt={2}
          container
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography fontWeight="bold" textTransform="uppercase">
            Total
          </Typography>
          <Typography fontWeight="bold">
            {formatCurrency(details.total)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderSummary;
