import { useTheme } from '@mui/material/styles';

import { Grid, Typography, useMediaQuery } from '@mui/material';

const HeroImage = ({
  imgUrl,
  lineOne,
  lineTwo,
}: {
  imgUrl: string;
  lineOne?: string;
  lineTwo?: string;
}) => {
  const theme = useTheme();

  return (
    <Grid
      justifyContent="center"
      sx={{
        padding: '-8px',
        left: 0,
        top: 0,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 'inherit',
        // zIndex: 0,
        backgroundImage: `url(${imgUrl})`,
      }}
    >
      <Grid item sx={{ height: 'inherit' }}>
        <Typography
          sx={{
            margin: 0,
            zIndex: 5,
            color: 'white',
            // [theme.breakpoints.down('xs')]: {
            //   lineHeight: '2rem',
            //   height: '20vh',
            //   padding: '8vh 1vh',
            // },
            [theme.breakpoints.down('sm')]: {
              height: '16vh',
              padding: '8vh 2vh',
              fontSize: '18px',
              lineHeight: '1.5rem',
            },
            [theme.breakpoints.only('sm')]: {
              height: '38vh',
              padding: '16vh 4vh',
              fontSize: '24px',
              lineHeight: '2rem',
            },
            [theme.breakpoints.only('md')]: {
              height: '50vh',
              padding: '18vh 4vh',
              fontSize: '48px',
              lineHeight: '5rem',
            },
            [theme.breakpoints.up('md')]: {
              height: '60vh',
              padding: '20vh 5vh',
              fontSize: '72px',
              lineHeight: '5rem',
            },
            fontFamily: 'Myriad Pro',
            fontWeight: 'bold',
            textShadow: '3px 3px 3px  rgba(0,0,0,.5)',
          }}
        >
          {lineOne && (
            <>
              {lineOne}
              <br />
            </>
          )}
          {lineTwo && <>{lineTwo}</>}

          {lineTwo}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeroImage;
