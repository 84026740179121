import { useCallService } from 'hooks';

import SponsorshipForm, {
  ISponsorshipFormInputs,
} from 'features/sponsorships/forms/SponsorshipForm';

import httpRoutes from 'utils/httpRoutes';

type EditSponsorshipProps = {
  eventId: number;
  sponsorship: ISponsorshipFormInputs;
  onSuccess: () => void;
  hideDialog: () => void;
};

const EditSponsorship = ({
  eventId,
  sponsorship,
  onSuccess,
  hideDialog,
}: EditSponsorshipProps) => {
  const { callService } = useCallService();

  const editSponsorship = async (values: ISponsorshipFormInputs) => {
    const { response } = await callService({
      resource: httpRoutes.sponsorships.updateSponsorship(values),
      successMessage: 'Sponsorship updated successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  return (
    <SponsorshipForm
      eventId={eventId}
      sponsorship={sponsorship}
      onSubmit={editSponsorship}
    />
  );
};

export default EditSponsorship;
