import { REACT_APP_GOOGLE_API_KEY } from 'config/config';

export const DEFAULT_LOCATION = {
  latitude: 34.0522,
  longitude: 118.2437,
};

export const loadMapApi = () => {
  const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places`;
  const scripts = document.getElementsByTagName('script');

  // loop through script tags to see if it exists
  for (let i = 0; i < scripts.length; i++) {
    const element = scripts[i];

    if (element.src.indexOf(mapsUrl) === 0) {
      return element;
    }
  }

  const googleMapsScript = document.createElement('script');
  googleMapsScript.src = mapsUrl;
  googleMapsScript.async = true;
  googleMapsScript.defer = true;

  window.document.body.appendChild(googleMapsScript);

  return googleMapsScript;
};

export default loadMapApi;
