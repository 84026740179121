import { PaginationInterface } from 'components/dataGrid/components/Pagination';
import { FilterState } from 'components/filters/Filters';
import { ILoginFormInputs } from 'features/auth/components/AuthLogin';
import { IRegisterFormInputs } from 'features/auth/components/AuthRegister';

import { IEventDetailFormInputs } from 'features/events/forms/EventDetailsForm';
import { IEventFormInputs } from 'features/events/forms/EventGeneralInfoForm';
import { ISponsorshipFormInputs } from 'features/sponsorships/forms/SponsorshipForm';
import { ISponsorshipTypeFormInputs } from 'features/sponsorships/forms/SponsorshipTypeForm';
import { ITicketTypeFormInputs } from 'features/ticketTypes/forms/TicketTypeForm';
import { GetUpcomingEventsRequest } from 'features/upcomingEvents/partials/EventListPartial';
import { ProfileFormInputs } from 'features/userProfile/components/Profile';
import { ISecurityFormInputs } from 'features/userProfile/components/Security';

import { instance as apiClient, multipartInstance } from 'utils/axios';

type TicketTypePaginatedRequest = {
  eventId: number;
  params: PaginationInterface;
  filters?: FilterState;
};

const apiBase = '/api';

const httpRoutes = {
  admin: {
    transactions: {
      resendOrderConfirmation(transactionId: number) {
        return apiClient.get(
          `${apiBase}/transactions/resend-order-email/${transactionId}`
        );
      },
      updateTransaction(payload: any) {
        return apiClient.put(`${apiBase}/admin/transaction/`, payload);
      },
    },
    login(payload: any) {
      return apiClient.post(`${apiBase}/auth/admin-login`, payload);
    },
    events: {
      cancelEvent(eventId: number) {
        return apiClient.get(`${apiBase}/admin/events/${eventId}/cancel`);
      },
    },
    getEventPayout(eventId: number) {
      return apiClient.get(`${apiBase}/admin/transaction/payout/${eventId}`);
    },
    getEvents({ controller, payload }: { controller?: any; payload: any }) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        status: _filters.get('status'),
        name: _filters.get('name'),
        virtual: _filters.get('virtual'),
        isPrivate: _filters.get('isPrivate'),
        organizerName: _filters.get('organizerName'),
        state: _filters.get('state'),
        salesStatus: _filters.get('salesStatus'),
      };

      return apiClient.get(`${apiBase}/admin/events/`, {
        params,
        signal: controller.signal,
      });
    },
    searchEvents(value: string) {
      return apiClient.get(`${apiBase}/admin/events/search/${value}`);
    },
    getUsers(payload: any) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        name: _filters.get('name'),
        email: _filters.get('email'),
      };
      return apiClient.get(`${apiBase}/admin/users/`, { params });
    },
    getTransactions({
      controller,
      payload,
    }: {
      controller?: any;
      payload: any;
    }) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        attendeeEmail: _filters.get('attendeeEmail'),
        eventName: _filters.get('eventName'),
        attendeeName: _filters.get('attendeeName'),
        paid: _filters.get('paid'),
        paymentMethod: _filters.get('paymentMethod'),
        status: _filters.get('status'),
      };

      return apiClient.get(`${apiBase}/admin/transaction/`, {
        params,
        signal: controller.signal,
      });
    },
    getUserTransactions(userId: number, payload: any) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        eventName: _filters.get('eventName'),
      };
      return apiClient.get(`${apiBase}/admin/transaction/by-user/${userId}`, {
        params,
      });
    },
    getUserEvents(userId: number, payload: any) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        status: _filters.get('status'),
        name: _filters.get('name'),
        startDate: _filters.get('startDate'),
        endDate: _filters.get('endDate'),
        virtual: _filters.get('virtual'),
        isPrivate: _filters.get('isPrivate'),
      };

      return apiClient.get(`${apiBase}/admin/events/by-user/${userId}`, {
        params,
      });
    },
    getTicketTypes(payload: any) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        name: _filters ? _filters.get('name') : undefined,
      };

      return apiClient.get(`${apiBase}/ticket-types/admin/paginated`, {
        params,
      });
    },
  },
  authentication: {
    resetPasswordRequest(payload: any) {
      return apiClient.post(`${apiBase}/auth/recover/reset`, payload);
    },
    resetPasswordConfirmation(payload: any) {
      return apiClient.post(`${apiBase}/auth/recover/password`, payload);
    },
    validateResetPasswordToken(token: string) {
      return apiClient.get(`${apiBase}/auth/recover/${token}`);
    },
    createUserAccount(payload: IRegisterFormInputs) {
      return apiClient.post(`${apiBase}/auth/signup`, payload);
    },
    sendVerificationEmail(payload: any) {
      return apiClient.post(`${apiBase}/auth/send-verification-email`, payload);
    },
    login(payload: ILoginFormInputs) {
      return apiClient.post(`${apiBase}/auth/login`, payload);
    },
    validateEmail(token: string) {
      return apiClient.get(`${apiBase}/auth/validate-email/${token}`);
    },
  },
  events: {
    getUpcomingEvents({
      controller,
      payload,
    }: {
      controller: any;
      payload: GetUpcomingEventsRequest;
    }) {
      return apiClient.get(`${apiBase}/upcoming-events/paginated`, {
        params: payload,
        signal: controller.signal,
      });
    },
    getAllTicketTypes(eventId: number) {
      return apiClient.get(`${apiBase}/upcoming-events/tickets/${eventId}`);
    },
    getAllSponsorshipTypes(eventId: number) {
      return apiClient.get(
        `${apiBase}/upcoming-events/sponsorships/${eventId}`
      );
    },
    getEventDetailsBySlug(nameSlug: string) {
      return apiClient.get(`${apiBase}/upcoming-events/name-slug/${nameSlug}`);
    },
    getEventSponsorshipByNameSlug(nameSlug: string) {
      return apiClient.get(
        `${apiBase}/upcoming-events/sponsorship/${nameSlug}`
      );
    },
    purchaseTickets(values: any) {
      return apiClient.post(`${apiBase}/transactions/purchase-tickets`, {
        ...values,
      });
    },
    purchaseSponsorships(values: any) {
      return apiClient.post(`${apiBase}/transactions/purchase-sponsorships`, {
        ...values,
      });
    },
    getEventCategories() {
      return apiClient.get(`${apiBase}/upcoming-events/categories`);
    },
    validateTicketDiscountCode(ticketId: number, discountCode: string) {
      return apiClient.get(
        `${apiBase}/upcoming-events/validate-ticket-discount/${ticketId}/${discountCode}`
      );
    },
    validateSponsorshipDiscountCode(
      sponsorshipTypeId: number,
      discountCode: string
    ) {
      return apiClient.get(
        `${apiBase}/upcoming-events/validate-sponsorship-discount/${sponsorshipTypeId}/${discountCode}`
      );
    },
  },
  organizer: {
    createEvent(payload: IEventFormInputs) {
      return apiClient.post(`${apiBase}/events/`, payload);
    },
    updateEvent(payload: IEventFormInputs) {
      return apiClient.put(`${apiBase}/events/`, payload);
    },
    updateEventStatus(eventId: number, status: string) {
      return apiClient.put(`${apiBase}/events/${eventId}/status`, { status });
    },
    updateEventDetails(payload: IEventDetailFormInputs) {
      return apiClient.put(`${apiBase}/events/${payload.id}/details`, payload);
    },
    getEvent(id: number) {
      return apiClient.get(`${apiBase}/events/${id}`);
    },
    getEventStats(id: number) {
      return apiClient.get(`${apiBase}/transactions/events/${id}/summary`);
    },
    getEvents(payload: any) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        status: _filters.get('status'),
        name: _filters.get('name'),
        startDate: _filters.get('startDate'),
        endDate: _filters.get('endDate'),
        virtual: _filters.get('virtual'),
        isPrivate: _filters.get('isPrivate'),
      };

      return apiClient.get(`${apiBase}/events/paginated`, { params });
    },
    getEventAttendees({
      eventId,
      params,
      filters,
    }: {
      eventId: number;
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        eventId,
        ...params,
        name: filters.get('name'),
        email: filters.get('email'),
        ticketTypeId: filters.get('ticketTypeId'),
      };

      return apiClient.get(`${apiBase}/events/attendees`, {
        params: formattedParams,
      });
    },
    getTicketDetails(ticketCode: string, eventId: number) {
      return apiClient.get(
        `${apiBase}/tickets/ticket-code/${eventId}/${ticketCode}`
      );
    },
    exportEventAttendees({
      eventId,
      params,
      filters,
    }: {
      eventId: number;
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        eventId,
        ...params,
        name: filters.get('name'),
        email: filters.get('email'),
        ticketTypeId: filters.get('ticketTypeId'),
      };

      return apiClient.get(`${apiBase}/events/attendees/export`, {
        params: formattedParams,
      });
    },
    getEventSponsors(payload: TicketTypePaginatedRequest) {
      const params = {
        eventId: payload.eventId,
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        name: payload.filters?.get('name'),
        email: payload.filters?.get('email'),
        sponsorshipTypeId: payload.filters?.get('sponsorshipTypeId'),
      };

      return apiClient.get(`${apiBase}/events/sponsors`, { params });
    },
    exportEventSponsors(payload: TicketTypePaginatedRequest) {
      const params = {
        eventId: payload.eventId,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        name: payload.filters?.get('name'),
        email: payload.filters?.get('email'),
        sponsorshipTypeId: payload.filters?.get('sponsorshipTypeId'),
      };

      return apiClient.get(`${apiBase}/events/sponsors/export`, { params });
    },
    getEventSponsorship(eventId: number) {
      return apiClient.get(`${apiBase}/sponsorships/event/${eventId}`);
    },
    getSponsorshipTypes({
      eventId,
      params,
      filters,
    }: {
      eventId: number;
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        ...params,
        name: filters.get('name'),
      };

      return apiClient.get(
        `${apiBase}/sponsorship-types/paginated/${eventId}`,
        {
          params: formattedParams,
        }
      );
    },
    getAllSponsorshipTypes(eventId: number) {
      return apiClient.get(`${apiBase}/sponsorship-types/all/${eventId}`);
    },
    getTicketTypes(payload: TicketTypePaginatedRequest) {
      const _filters = payload.filters;

      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        name: _filters ? _filters.get('name') : undefined,
      };

      return apiClient.get(
        `${apiBase}/ticket-types/paginated/${payload.eventId}`,
        {
          params,
        }
      );
    },
    getAllTicketTypes(eventId: number) {
      return apiClient.get(`${apiBase}/ticket-types/${eventId}`);
    },
    getTransactions({
      eventId,
      params,
      filters,
    }: {
      eventId: number;
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        ...params,
        customerName: filters.get('customerName'),
        customerEmail: filters.get('customerEmail'),
      };
      return apiClient.get(`${apiBase}/transactions/events/${eventId}`, {
        params: formattedParams,
      });
    },
    getTransactionDetails(transactionId: number) {
      return apiClient.get(`${apiBase}/transactions/${transactionId}`);
    },
    exportTransactions({
      eventId,
      params,
      filters,
    }: {
      eventId: number;
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        ...params,
        customerName: filters.get('customerName'),
        customerEmail: filters.get('customerEmail'),
      };
      return apiClient.get(`${apiBase}/transactions/events/${eventId}/export`, {
        params: formattedParams,
      });
    },
    getTicketSales(eventId: number, groupBy?: string) {
      const params = { groupBy };

      return apiClient.get(`${apiBase}/tickets/sales/${eventId}`, { params });
    },
    getSponsorshipSales(eventId: number) {
      return apiClient.get(`${apiBase}/sponsorships/sales/${eventId}`);
    },
    checkInAttendee(ticketId: number) {
      return apiClient.put(`${apiBase}/tickets/check-in/${ticketId}`);
    },
    undoCheckInAttendee(ticketId: number) {
      return apiClient.put(`${apiBase}/tickets/undo-check-in/${ticketId}`);
    },
    getEventCategoryTypes() {
      return apiClient.get(`${apiBase}/events/categories`);
    },
  },
  ticketTypes: {
    createTicketType(payload: ITicketTypeFormInputs) {
      return apiClient.post(`${apiBase}/ticket-types/`, payload);
    },
    updateTicketType(payload: ITicketTypeFormInputs) {
      return apiClient.put(`${apiBase}/ticket-types/`, payload);
    },
    deleteTicketType(id: number) {
      return apiClient.put(`${apiBase}/ticket-types/delete/${id}`);
    },
    getTicketTypes(payload: TicketTypePaginatedRequest) {
      const params = {
        page: payload.params.page,
        limit: payload.params.limit,
        order: payload.params.order ?? 'desc',
        orderBy: payload.params.orderBy,
        filters: payload.filters ? payload.filters : [],
      };

      return apiClient.get(
        `${apiBase}/ticket-types/paginated/${payload.eventId}`,
        { params }
      );
    },
    getAllTicketTypes(eventId: number) {
      return apiClient.get(`${apiBase}/ticket-types/${eventId}`);
    },
    getTicketTypeDetails(ticketTypeId: number) {
      return apiClient.get(`${apiBase}/ticket-types/details/${ticketTypeId}`);
    },
  },
  sponsorships: {
    createSponsorship(payload: ISponsorshipFormInputs) {
      return apiClient.post(`${apiBase}/sponsorships/`, payload);
    },
    updateSponsorship(payload: ISponsorshipFormInputs) {
      return apiClient.put(`${apiBase}/sponsorships/`, payload);
    },
    deleteSponsorship(id: number) {
      return apiClient.put(`${apiBase}/sponsorships/delete/${id}`);
    },
  },
  sponsorshipTypes: {
    createSponsorshipType(payload: ISponsorshipTypeFormInputs) {
      return apiClient.post(`${apiBase}/sponsorship-types/`, payload);
    },
    updateSponsorshipType(payload: ISponsorshipTypeFormInputs) {
      return apiClient.put(`${apiBase}/sponsorship-types/`, payload);
    },
    getSponsorshipType(id: number) {
      return apiClient.get(`${apiBase}/sponsorship-types/${id}`);
    },
    deleteSponsorshipType(id: number) {
      return apiClient.put(`${apiBase}/sponsorship-types/delete/${id}`);
    },
  },
  user: {
    getUserProfile() {
      return apiClient.get(`${apiBase}/users/profile`);
    },
    updateUserProfile(payload: ProfileFormInputs) {
      return apiClient.put(`${apiBase}/users/profile`, payload);
    },
    changeUserPassword(payload: ISecurityFormInputs) {
      return apiClient.put(`${apiBase}/users/change-password`, payload);
    },
    getOrderHistory({
      params,
      filters,
    }: {
      params: PaginationInterface;
      filters: FilterState;
    }) {
      const formattedParams = {
        ...params,
        eventName: filters.get('eventName'),
      };

      return apiClient.get(`${apiBase}/users/order-history`, {
        params: formattedParams,
      });
    },
    getOrderDetails(orderId: number) {
      return apiClient.get(`${apiBase}/users/order-details/${orderId}`);
    },
    downloadTickets(orderId: number) {
      multipartInstance.defaults.responseType = 'arraybuffer';

      return multipartInstance.get(
        `${apiBase}/users/tickets/download/${orderId}`
      );
    },
  },
};

export default httpRoutes;
