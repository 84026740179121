import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { PasswordFormField, TextFormField } from 'components/formFields';

import { useCallService } from 'hooks';

import { SaveIcon } from 'material-icons';

import resources from '../resources';

export interface ISecurityFormInputs {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const INITIAL_SECURITY_FORM_STATE: ISecurityFormInputs = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const Security = ({ onSuccess }: { onSuccess?: VoidFunction }) => {
  const theme = useTheme();

  const { callService } = useCallService();

  const onSubmit = async (values: ISecurityFormInputs) => {
    const { response } = await callService({
      resource: resources.changeUserPassword(values),
      successMessage: 'Password successfully changed!',
    });

    if (response) {
      reset(INITIAL_SECURITY_FORM_STATE);

      if (onSuccess) onSuccess();
    }
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Password is required'),
    newPassword: Yup.string()
      .min(5, 'Password must be at least 5 digits')
      .required(''),
    newPasswordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'New password must match')
      .required('New password must be confirmed'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ISecurityFormInputs>({
    mode: 'onBlur',
    defaultValues: INITIAL_SECURITY_FORM_STATE,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item sm={6} md={8}>
        <Typography variant="h4">Change Password</Typography>
        <Grid item xs={12}>
          <Divider
            sx={{
              opacity: 0.5,
              m: 1,
              borderColor: theme.palette.primary.light,
            }}
          />
          <Grid container sx={{ p: 2.5 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <PasswordFormField
                    name="currentPassword"
                    control={control}
                    label="Current Password"
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordFormField
                    name="newPassword"
                    control={control}
                    label="New Password"
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordFormField
                    name="newPasswordConfirmation"
                    control={control}
                    label="Confirm New Password"
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Security;
