import { CircularProgress, Box } from '@mui/material';

const Loading = () => {
  return (
    <Box
      sx={{ width: 'inherit', height: '100vh' }}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size="5rem" />
    </Box>
  );
};

export default Loading;
