import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

interface CallServiceProps {
  resource: any;
  throwOnError?: boolean;
  errorMessage?: string;
  successMessage?: string;
  showSnackbar?: boolean;
}

const useCallService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const callService = async ({
    resource,
    throwOnError = false,
    errorMessage, // optional and overrides
    successMessage, // optional and overrides,
    showSnackbar = false,
  }: CallServiceProps) => {
    try {
      const response = await resource;

      if (successMessage) {
        enqueueSnackbar(successMessage, {
          variant: 'success',
          autoHideDuration: 1500,
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }

      return {
        response: response.data,
        error: null,
      };
    } catch (error: any) {
      console.log('Error: ', error);
      let messages = [];

      if (errorMessage) {
        messages.push(errorMessage);
      }

      if (!errorMessage && error) {
        if (error.response && error.response.data) {
          messages = getResponseErrors(error);
        }
      }

      if (!throwOnError) {
        messages.forEach((_errorMessage: string) =>
          enqueueSnackbar(_errorMessage, {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          })
        );

        if (error.status === 401) {
          navigate('/login');
        }

        return {
          response: null,
          error,
        };
      }

      if (showSnackbar) {
        if (typeof messages === 'string') {
          enqueueSnackbar(messages, {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });
        } else {
          messages.forEach((_errorMessage: string) =>
            enqueueSnackbar(_errorMessage, {
              variant: 'error',
              autoHideDuration: 2000,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            })
          );
        }
      }
      throw messages;
    }
  };

  return { callService };
};

export const getResponseErrors = (error: any) => {
  if (error && error.response && error.response.data) {
    return [`Error: ${error.response.data}`];
  }
  if (error && error.message) {
    return [error.message];
  }
  return ['An Unexpected error has occurred. Please try again later.'];
};

export default useCallService;
