import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import useCallService from 'hooks/useCallService';

// project imports
import AuthWrapper from 'features/auth/components/AuthWrapper';
import AuthCardWrapper from 'features/auth/components/AuthCardWrapper';

import Logo from 'components/Logo';
import { useParams } from 'react-router-dom';
import httpRoutes from 'utils/httpRoutes';

export type ValidateEmailProps = {
  firstName: string;
  lastName: string;
  token: string;
};

const ValidateEmail = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { token = '' } = useParams();
  const navigate = useNavigate();
  const { callService } = useCallService();

  const validate = async () => {
    const { response } = await callService({
      resource: httpRoutes.authentication.validateEmail(token),
      successMessage: 'Email successfully verified!',
    });

    if (response) {
      navigate('/login');
    }
  };

  useEffect(() => {
    validate();
  }, [token]);

  return (
    <AuthWrapper>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 'calc(100vh - 68px)' }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? 'column-reverse' : 'row'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? 'h3' : 'h2'}
                            textAlign="center"
                          >
                            Success, your email has been validated!
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize="16px"
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                          >
                            Please log in.
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ValidateEmail;
