import React from 'react';

import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@mui/material';

interface RadioOptionProps {
  value: string | number | boolean;
  label: string | number;
  abbr?: string;
}

interface RadioFieldProps {
  label: string;
  options: RadioOptionProps[];
  margin?: 'normal' | 'dense' | 'none';
  row?: boolean;
}

export type RadioFormFieldProps<T extends FieldValues> = RadioFieldProps &
  UseControllerProps<T>;

const RadioFormField = <T extends FieldValues>(
  props: RadioFormFieldProps<T>
) => {
  const {
    label,
    name,
    control,
    margin = 'normal',
    row = true,
    options,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl sx={{ minWidth: '100%' }} margin={margin}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup {...field} row={row}>
            {options.map((o) => (
              <FormControlLabel
                key={o.label}
                value={o.value}
                label={o.label}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
          <FormHelperText> </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default RadioFormField;
