import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Skeleton } from '@mui/material';

import { useCallService } from 'hooks';

import PageContainer from 'components/PageContainer';
import SEO from 'components/SEO';

import { REACT_APP_CLIENT_URL, REACT_APP_CLOUDFRONT_URL } from 'config/config';

import EventSponsorshipType from 'types/EventSponsorshipType';

import EventSponsorship from '../components/EventSponsorship';
import { EventDetailsProps, INITIAL_EVENT_DETAILS_STATE } from './EventDetails';
import httpRoutes from 'utils/httpRoutes';

export type SponsorshipProps = {
  id: number;
  description: string;
  eventId: number;
  nameSlug: string;
  event: EventDetailsProps;
  eventSponsorshipTypes?: EventSponsorshipType[];
};

export const INITIAL_FORM_STATE: SponsorshipProps = {
  id: 0,
  description: '',
  eventId: 0,
  nameSlug: '',
  event: INITIAL_EVENT_DETAILS_STATE,
};

function EventSponsorships() {
  const [loading, setLoading] = useState(true);
  const [eventSponsorship, setEventSponsorship] =
    useState<SponsorshipProps>(INITIAL_FORM_STATE);

  const { callService } = useCallService();

  const { slug } = useParams();

  const imageUrl = eventSponsorship.event.imageUrl || 'EventHiDefault.jpeg';

  useEffect(() => {
    const getEventSponsorship = async () => {
      if (slug) {
        setLoading(true);

        const { response } = await callService({
          resource: httpRoutes.events.getEventSponsorshipByNameSlug(slug),
        });

        if (response) {
          setEventSponsorship(response.eventSponsorship);

          setLoading(false);
        }
      }
    };

    getEventSponsorship();
  }, [slug]);

  if (loading)
    return (
      <PageContainer maxWidth="md" elevation={5}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="25rem" width="inherit" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="3rem" width="inherit" />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Skeleton
                sx={{ m: 1 }}
                variant="circular"
                width="3rem"
                height="3rem"
              ></Skeleton>
              <Skeleton
                sx={{ m: 1 }}
                variant="circular"
                width="3rem"
                height="3rem"
              ></Skeleton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="3rem" width="inherit" />
          </Grid>
          <Grid item xs={9} pr={1}>
            <Skeleton variant="rectangular" height="50rem" width="inherit" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" height="40rem" width="inherit" />
          </Grid>
        </Grid>
      </PageContainer>
    );

  return (
    <PageContainer maxWidth="md" elevation={5}>
      <SEO
        title={`EventHi - ${eventSponsorship.event.name} - Sponsorships`}
        description={eventSponsorship.description}
        keywords={['cannabis event']}
        image={`${REACT_APP_CLOUDFRONT_URL}/${imageUrl}`}
        url={`${REACT_APP_CLIENT_URL}/events/${eventSponsorship.event.nameSlug}/sponsorship`}
      />
      <EventSponsorship eventSponsorship={eventSponsorship} />
    </PageContainer>
  );
}

export default EventSponsorships;
