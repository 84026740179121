import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Button, Grid, IconButton, Typography } from '@mui/material';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';
import { FilterState } from 'components/filters/Filters';
import { FilterType } from 'components/filters/filterTypes';

import { Create, Delete, Edit } from 'features/ticketTypes/components/Modals';
import { ITicketTypeFormInputs } from 'features/ticketTypes/forms/TicketTypeForm';

import { useCallService } from 'hooks';

import { DeleteIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';
import { IEvent, INITIAL_EVENT_STATE } from 'layout/OrganizerEventLayout';
import { formatCurrency } from 'utils/helpers';
import { formatDate } from 'utils/formatDateTime';
import { Stack } from '@mui/system';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

const EventTicketTypesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();

  const params = useParams();

  const { id = 0 } = params;

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [eventDetails, setEventDetails] = useState<IEvent>(INITIAL_EVENT_STATE);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getTicketTypes();
  }, [filters, state]);

  const getTicketTypes = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.getTicketTypes({
          eventId: Number(id),
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  useEffect(() => {
    const getEvent = async () => {
      try {
        const { response } = await callService({
          resource: httpRoutes.organizer.getEvent(Number(id)),
        });

        if (response) {
          setEventDetails(response.eventDetails);
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (id > 0) getEvent();
  }, []);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Ticket Name',
      field: 'name',
      sortable: true,
      filterable: true,
      flex: 1,
    },
    {
      headerName: 'Ticket Type',
      field: 'type',
      sortable: true,
      filterable: true,
      flex: 0.25,
      ColumnComponent: (params: any) => {
        if (params.value === 'free') return <MoneyOffOutlinedIcon />;
        if (params.value === 'donation')
          return <VolunteerActivismOutlinedIcon />;
        if (params.value === 'paid') return <AttachMoneyOutlinedIcon />;
        return params.value;
      },
    },
    {
      headerName: 'Status',
      field: 'active',
      sortable: true,
      filterable: true,
      type: 'boolean',
      flex: 0.25,
    },
    {
      headerName: 'Sales Start',
      field: 'saleStartDate',
      sortable: true,
      filterable: false,
      hide: true,
      type: 'dateTime',
      flex: 0.5,
    },
    {
      headerName: 'Sales End',
      field: 'saleEndDate',
      sortable: true,
      filterable: false,
      hide: true,
      type: 'dateTime',
      flex: 0.5,
    },
    {
      headerName: 'Price',
      field: 'price',
      sortable: true,
      filterable: true,
      type: 'usdCurrency',
      flex: 0.5,
      align: 'right',
    },
    {
      headerName: 'Remaining/Quantity',
      field: 'quantityRemaining',
      filterable: false,
      sortable: false,
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return (
          <>
            {Intl.NumberFormat('en-US').format(params.row.quantityRemaining)}/
            {Intl.NumberFormat('en-US').format(params.row.quantity)}
          </>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton onClick={() => showEditModal(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => showDeleteModal(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
    {
      headerName: 'Ticket Types',
      field: 'mobile',
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <Grid
            container
            height="100%"
            m={0.5}
            p={1}
            justifyContent="space-between"
            flexDirection="row"
            spacing={1}
          >
            <Grid item xs={4}>
              <Typography variant="caption">Name</Typography>
              <Typography fontWeight="bold">{params.row.name}</Typography>
              <Typography variant="caption">Type:</Typography>

              {params.row.type === 'free' && <Typography> Free</Typography>}
              {params.row.type === 'donation' && (
                <Typography> Donation</Typography>
              )}
              {params.row.type === 'paid' && <Typography> Paid</Typography>}
              <Typography variant="caption">Price:</Typography>
              <Typography>{formatCurrency(params.row.price)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Quantity</Typography>
              <Typography>
                {Intl.NumberFormat('en-US').format(
                  params.row.quantityRemaining
                )}
                / {Intl.NumberFormat('en-US').format(params.row.quantity)}
              </Typography>
              <Typography variant="caption">Sales Start</Typography>

              <Typography>{formatDate(params.row.saleStartDate)}</Typography>
              <Typography variant="caption">Sales End</Typography>

              <Typography>{formatDate(params.row.saleEndDate)}</Typography>
            </Grid>
            <Stack>
              <IconButton onClick={() => showEditModal(params.row)}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => showDeleteModal(params.row)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Stack>
          </Grid>
        );
      },
    },
  ];

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Ticket Type Name',
    },
  ];

  const showCreateModal = () => {
    showDialog({
      title: 'Add ticket',
      size: 'sm',
      content: (
        <Create
          eventId={Number(id)}
          eventDetails={eventDetails}
          onSuccess={getTicketTypes}
          hideDialog={hideDialog}
        />
      ),
    });
  };

  const showEditModal = (values: ITicketTypeFormInputs) => {
    if (values.id) {
      showDialog({
        title: 'Edit Ticket',
        size: 'sm',
        content: (
          <Edit
            eventId={Number(id)}
            eventDetails={eventDetails}
            ticketId={values.id}
            onSuccess={getTicketTypes}
            hideDialog={hideDialog}
          />
        ),
      });
    }
  };

  const showDeleteModal = (value: ITicketTypeFormInputs) => {
    showDialog({
      title: 'Delete Ticket',
      size: 'xs',
      content: (
        <Delete
          ticketId={Number(value.id)}
          ticket={value}
          onSuccess={getTicketTypes}
          hideDialog={hideDialog}
        />
      ),
    });
  };

  return (
    <PageContainer>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={showCreateModal}
          startIcon={<AddOutlinedIcon />}
        >
          Create Ticket
        </Button>
      </Grid>
      <DataGrid
        tableTitle="Event Ticket Types"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
      />
    </PageContainer>
  );
};

const EventTicketTypes = () => (
  <ControlledDataGrid>
    <EventTicketTypesGrid />
  </ControlledDataGrid>
);

export default EventTicketTypes;
