import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, Outlet, useParams } from 'react-router-dom';

import { useCallService } from 'hooks';

import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Popover,
  Snackbar,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Drawer from './Drawer';

import {
  BackArrowIcon,
  CannabisProhibitedIcon,
  CopyContentIcon,
  InActivePlantIcon,
  PrivateEventIcon,
  PublicEventIcon,
} from 'material-icons';
import DisplayText from 'components/DisplayText';

import { CalendarMonthOutlined, Visibility } from '@mui/icons-material';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';

import { REACT_APP_CLIENT_URL } from 'config/config';
import EventInfo from 'features/upcomingEvents/components/EventInfo';
import DashboardBottomNavigation from './BottomNavigation';

export interface IEvent {
  status: string;
  id: number;
  name: string;
  userId: number;
  description: string;
  dateAdded: Date;
  hostname: string;
  contactEmail: string;
  cannabisConsumption: boolean;
  eventType: number;
  venueName: string;
  eventCategory: string[];
  private: boolean;
  isVirtual: boolean;
  virtualUrl?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
  placeId?: string;
  hideLocation: boolean;
  startsAt: Date;
  startTime: Date;
  endsAt: Date;
  endTime: Date;
  timezone: string;
  allowShare: boolean;
  refundPolicy: number;
  expandTicketDescriptions: boolean;
  nameSlug: string;
  isCopy: boolean;
  advancedPayments: boolean;
}

export const INITIAL_EVENT_STATE: IEvent = {
  id: 0,
  userId: 0,
  isVirtual: false,
  cannabisConsumption: false,
  hideLocation: false,
  description: '',
  expandTicketDescriptions: false,
  private: false,
  name: '',
  hostname: '',
  contactEmail: '',
  venueName: '',
  state: '',
  status: 'Draft',
  eventCategory: [],
  virtualUrl: '',
  streetAddress: '',
  city: '',
  postalCode: '',
  isCopy: false,
  latitude: 0,
  longitude: 0,
  refundPolicy: 0,
  placeId: '',
  startsAt: new Date(),
  startTime: new Date(),
  endsAt: new Date(),
  endTime: new Date(),
  dateAdded: new Date(),
  timezone: '',
  allowShare: false,
  eventType: 1,
  nameSlug: '',
  advancedPayments: false,
};

const EventStatusWidget = ({
  icon,
  title,
  description,
}: {
  icon: ReactNode;
  title: string;
  description: string;
}) => {
  return (
    <Grid container columnSpacing={1} ml={1}>
      <Grid item alignSelf="center">
        <Avatar sx={{ backgroundColor: 'inherit' }}>{icon}</Avatar>
      </Grid>
      <Grid item alignSelf="center">
        <Typography variant="caption">{description}</Typography>
      </Grid>
    </Grid>
  );
};

const UrlSlug = ({ nameSlug, label }: { nameSlug: string; label: string }) => {
  const [copySlug, setCopySlug] = useState(false);

  const handleCopyClick = () => {
    setCopySlug(true);
  };

  const handleCloseSnackbar = (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopySlug(false);
  };

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(
            `${REACT_APP_CLIENT_URL}/events/${nameSlug}`
          );
          handleCopyClick();
        }}
        variant="text"
        startIcon={<CopyContentIcon />}
      >
        {label}
      </Button>
      <Snackbar
        open={copySlug}
        autoHideDuration={1500}
        message="URL copied!"
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </Box>
  );
};

const OrganizerEventLayout = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));
  const [isMobile, setIsMobile] = useState<boolean>(matchDownSm);

  const { showDialog, hideDialog } = useDialogDispatcher();
  const [open, setOpen] = useState(true);

  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState<IEvent>(INITIAL_EVENT_STATE);

  const { callService } = useCallService();
  const params = useParams();
  const navigate = useNavigate();

  const _eventId = Number(params.id);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const cancelEventOpen = Boolean(anchorEl);

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
  }, [matchDownLG]);

  // set media wise responsive drawer
  useEffect(() => {
    setIsMobile(matchDownSm);
  }, [matchDownSm]);

  const showEventPreview = () => {
    showDialog({
      size: 'md',
      content: <EventInfo eventDetails={eventDetails} />,
    });
  };

  const cancelEvent = async () => {
    const { response } = await callService({
      resource: httpRoutes.organizer.updateEventStatus(
        eventDetails.id,
        'Canceled'
      ),
      successMessage: 'This event has been canceled.',
    });

    if (response) {
      handleClose();
      setEventDetails((prevState) => ({ ...prevState, status: 'Canceled' }));
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getEventDetails = async () => {
      if (_eventId) {
        setLoading(true);
        const { response } = await callService({
          resource: httpRoutes.organizer.getEvent(_eventId),
        });

        if (response) {
          setEventDetails(response.eventDetails);
          setLoading(false);
        }
      }
    };

    getEventDetails();
  }, []);

  if (loading) return <>...</>;

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {!isMobile && (
        <Drawer
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          isMobile={isMobile}
        />
      )}

      <Card
        sx={{ p: { xs: 1, sm: 2, md: 3, lg: 4 }, borderRadius: '15px' }}
        elevation={2}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  variant="text"
                  startIcon={<BackArrowIcon />}
                  onClick={() => navigate('/organizer/events')}
                  sx={{ mb: 2 }}
                >
                  Back To Events
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  color="error"
                  onClick={handleClick}
                  sx={{ mb: 2 }}
                  disabled={eventDetails.status === 'Canceled'}
                >
                  {eventDetails.status === 'Canceled'
                    ? 'Event Canceled'
                    : 'Cancel Event'}
                </Button>
                <Popover
                  id="cancel-event"
                  open={cancelEventOpen}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  sx={{ p: 1, m: 1 }}
                >
                  <Typography sx={{ p: 2 }}>
                    Are you sure you want to cancel this event?
                  </Typography>
                  <Grid container justifyContent="space-between">
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      color="primary"
                      sx={{ m: 1 }}
                    >
                      No
                    </Button>
                    <Button
                      onClick={cancelEvent}
                      variant="outlined"
                      color="error"
                      sx={{ m: 1 }}
                    >
                      Yes, Cancel Event
                    </Button>
                  </Grid>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/* Start container */}

            <Grid container justifyContent="space-between">
              <Grid item md={9}>
                <Typography variant="h1" sx={{ color: '#777777' }}>
                  {eventDetails.name}
                  <span style={{ paddingLeft: '1rem' }}>
                    {eventDetails.private && (
                      <Tooltip
                        title={
                          <>
                            <DisplayText
                              type="bodySmall"
                              text="Private Event"
                            />
                            <DisplayText
                              type="bodyXSmall"
                              text="This event is private and will not be searchable."
                            />
                          </>
                        }
                      >
                        <PrivateEventIcon fontSize="small" />
                      </Tooltip>
                    )}
                    {!eventDetails.private && (
                      <Tooltip
                        title={
                          <>
                            <DisplayText type="bodySmall" text="Public Event" />
                            <DisplayText
                              type="bodyXSmall"
                              text={
                                eventDetails.status === 'Published'
                                  ? 'This event is now searchable in our upcoming events list.'
                                  : 'This event will be searchable once published.'
                              }
                            />
                          </>
                        }
                      >
                        <PublicEventIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </span>
                  <span style={{ paddingLeft: '.5rem' }}>
                    {eventDetails.cannabisConsumption && (
                      <Tooltip
                        title={
                          <>
                            <DisplayText
                              type="bodySmall"
                              text="Cannabis Friendly"
                            />
                            <DisplayText
                              type="bodyXSmall"
                              text="This event allows the consumption of cannabis."
                            />
                          </>
                        }
                      >
                        <SvgIcon fontSize="small">
                          <InActivePlantIcon fontSize="small" />
                        </SvgIcon>
                      </Tooltip>
                    )}
                    {!eventDetails.cannabisConsumption && (
                      <Tooltip
                        title={
                          <>
                            <DisplayText
                              type="bodySmall"
                              text="No cannabis allowed"
                            />
                            <DisplayText
                              type="bodyXSmall"
                              text="This event does not allow cannabis consumption."
                            />
                          </>
                        }
                      >
                        <CannabisProhibitedIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </span>
                </Typography>
              </Grid>
              <Grid item md={3}>
                <EventStatusWidget
                  icon={<CalendarMonthOutlined />}
                  title="Event Date"
                  description={new Date(eventDetails.startsAt).toLocaleString()}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'inline-block' }}>
              <UrlSlug nameSlug={eventDetails.nameSlug} label="Event Link" />
              <UrlSlug
                nameSlug={`${eventDetails.nameSlug}/sponsorship`}
                label="Sponsorship Link"
              />
              <Button
                variant="text"
                onClick={showEventPreview}
                startIcon={<Visibility />}
              >
                Preview
              </Button>
            </Grid>
          </Grid>

          <Divider />

          <Outlet />
          {/* End Container */}
        </Grid>
        {isMobile && <DashboardBottomNavigation eventId={_eventId} />}
      </Card>
    </Box>
  );
};

export default OrganizerEventLayout;
