import { ReturnTransactionItemProps } from 'features/admin/dialogs/EditOrder';
import { CheckoutFees, CheckoutItemState } from 'states/checkoutState';

export type EventFeeTypes = {
  itemFee: number;
  processingFee: number;
  serviceFee: number;
};

export function calculateServiceFee(subtotal: number, fees: EventFeeTypes) {
  // if total is zero, return zeroes
  if (subtotal === 0) {
    return 0;
  }

  const _serviceFee = fees.serviceFee;

  // serviceFee
  const serviceFee = subtotal * _serviceFee;

  return bankersRound(serviceFee);
}

export function calculateProcessingFee(
  preProcessingTotal: number,
  processingFee: number
) {
  if (preProcessingTotal === 0) return 0;

  return bankersRound(preProcessingTotal * processingFee);
}

// used to display fees on each ticket type
export function calculateItemFees(item: any, fees: EventFeeTypes) {
  const baseTotal = Number(item.price);

  const quantity = 1;

  const subtotal = baseTotal * quantity;

  const itemFee = fees.itemFee * quantity;

  // Calculate Service Fee
  const serviceFee = calculateServiceFee(subtotal, fees);

  // Calculate processingFee
  // Combine perTicketFee and serviceFee
  const preProcessingTotal = subtotal + itemFee + serviceFee;

  const processingFee = calculateProcessingFee(
    preProcessingTotal,
    fees.processingFee
  );

  return (
    bankersRound(itemFee) +
    bankersRound(serviceFee) +
    bankersRound(processingFee)
  );
}

export async function calculateCCFees(
  items: CheckoutItemState[],
  fees: CheckoutFees
) {
  // return list of items with subtotal, serviceFees, ccFees
  const totals = {
    subtotal: 0,
    discount: 0,
    itemFee: 0,
    processingFee: 0,
    serviceFee: 0,
    total: 0,
    displayFees: 0,
  };

  for (let i = 0; i < items.length; i += 1) {
    const _item = items[i];
    const _itemDiscountAmount = _item.amountToDiscount
      ? Number(_item.amountToDiscount)
      : 0;

    if (_item.quantity > 0) {
      const subtotal =
        (Number(_item.price) - _itemDiscountAmount) * Number(_item.quantity);

      _item.subtotal = subtotal;
      _item.discountAmount =
        _itemDiscountAmount > 0
          ? _itemDiscountAmount * Number(_item.quantity)
          : 0;
      totals.subtotal += _item.subtotal;
      totals.discount += Number(_item.discountAmount);

      const itemFee = subtotal > 0 ? _item.quantity * fees.itemFee : 0;

      // calculate service fee
      const serviceFee = calculateServiceFee(subtotal, fees);

      _item.itemFee = itemFee;

      _item.serviceFee = serviceFee;

      const preProcessingTotal = subtotal + itemFee + serviceFee;

      const processingFee = calculateProcessingFee(
        preProcessingTotal,
        fees.processingFee
      );

      _item.processingFee = processingFee;

      _item.total =
        _item.itemFee + _item.subtotal + _item.serviceFee + _item.processingFee;

      totals.processingFee += processingFee;
      totals.serviceFee += serviceFee;
      totals.itemFee += itemFee;

      if (_item.feesType === 'absorb') {
        _item.total = subtotal;
      } else {
        totals.displayFees =
          _item.serviceFee + _item.processingFee + _item.itemFee;
      }

      totals.total += bankersRound(_item.total) || 0;
    }
  }

  return {
    items,
    totals,
  };
}

export async function calculateRefundedCCFees(
  items: ReturnTransactionItemProps[],
  fees: EventFeeTypes
): Promise<any> {
  // return list of items with subtotal, serviceFees, ccFees
  const totals = {
    subtotal: 0,
    discount: 0,
    itemFee: 0,
    processingFee: 0,
    serviceFee: 0,
    total: 0,
    displayFees: 0,
    chargebackFees: 0,
  };

  for (let i = 0; i < items.length; i += 1) {
    const _item = items[i];
    const _itemDiscountAmount = _item.discount ? Number(_item.discount) : 0;

    if (_item.returnQuantity > 0) {
      const subtotal =
        (Number(_item.price) - _itemDiscountAmount) *
        Number(_item.returnQuantity) *
        -1;

      _item.subtotal = subtotal;
      _item.discount =
        _itemDiscountAmount > 0
          ? _itemDiscountAmount * Number(_item.returnQuantity)
          : 0;
      totals.subtotal += _item.subtotal;
      totals.discount += Number(_item.discount);

      const itemFee =
        subtotal < 0 ? _item.returnQuantity * fees.itemFee * -1 : 0;

      // calculate service fee
      const serviceFee = calculateServiceFee(subtotal, fees) * -1;

      _item.itemFee = itemFee;

      _item.serviceFee = serviceFee * -1;

      const preProcessingTotal =
        _item.subtotal + _item.itemFee + _item.serviceFee;

      const processingFee =
        calculateProcessingFee(preProcessingTotal, fees.processingFee) * -1;

      _item.processingFee = processingFee * -1;

      _item.total =
        _item.itemFee + _item.subtotal + _item.serviceFee + _item.processingFee;

      totals.processingFee += _item.processingFee;
      totals.serviceFee += _item.serviceFee;
      totals.itemFee += _item.itemFee;

      const chargeFee = _item.chargeFee
        ? _item.chargeFee.toString().replaceAll('$', '').replaceAll(',', '')
        : 0;

      if (_item.chargeFee && _item.chargeFee !== '-') {
        totals.chargebackFees += Number(chargeFee) * -1;
      }

      if (_item.feesType === 'absorb') {
        _item.total = subtotal;
      } else {
        totals.displayFees =
          _item.serviceFee + _item.processingFee + _item.itemFee;
      }

      totals.total += _item.total || 0;
    }
  }

  return {
    items,
    totals,
  };
}

export function bankersRound(num: number, decimalPlaces = 2) {
  const d = decimalPlaces || 0;
  // eslint-disable-next-line no-restricted-properties
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  // eslint-disable-next-line no-nested-ternary
  const r =
    f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);

  return d ? r / m : r;
}
