// material-ui
import { Card, CardContent, Grid, Skeleton, Stack } from '@mui/material';

// ===========================|| EventCard Skeleton ||=========================== //

const EventCardSkeleton = () => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    <Card
      sx={{
        transition: '.5s',
        boxShadow: 5, // theme.shadows[20]
        border: '.5px solid #CCCCCC',
        ':hover': {
          boxShadow: 10, // theme.shadows[20]
        },
      }}
    >
      <Skeleton variant="rectangular" height={150} />
      <CardContent sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={20} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12} sx={{ pt: '8px !important' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Skeleton variant="rectangular" height={20} width={90} />
              <Skeleton variant="rectangular" height={20} width={38} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default EventCardSkeleton;
