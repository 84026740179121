import React, { useEffect, useState } from 'react';

import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';

import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import TimePicker from 'components/timePicker';
import DatePicker from 'components/datePicker';
import { NumberFormField, TextFormField } from 'components/formFields';
import { formatCurrency } from 'utils/helpers';
import { LoadingButton } from '@mui/lab';
import { AddIcon, DeleteIcon, SaveIcon } from 'material-icons';
import {
  DiscountCodeProps,
  INITIAL_DISCOUNT_CODE_STATE,
} from 'types/DiscountCode';
import { IEvent } from 'layout/OrganizerEventLayout';

export interface ITicketTypeFormInputs {
  id?: number;
  eventId: number;
  name: string;
  description: string;
  saleStartDate: Date;
  saleEndDate: Date;
  saleStartTime: Date;
  saleEndTime: Date;
  type: string;
  feesType: 'absorb' | 'pass';
  price: string;
  quantity: number;
  quantityRemaining?: number;
  minPerOrder: number;
  maxPerOrder: number;
  showQuantity: boolean;
  discountCodes: DiscountCodeProps[];
}

type TicketTypeFormProps = {
  eventId: number;
  eventDetails: IEvent;
  onSubmit: (values: ITicketTypeFormInputs, dashboard?: boolean) => void;
  hideDialog: () => void;
  ticket?: ITicketTypeFormInputs;
};

function TicketForm({
  eventId,
  eventDetails,
  ticket,
  onSubmit,
  hideDialog,
}: TicketTypeFormProps) {
  const edit = ticket && ticket.id;
  const [ticketType, setTicketType] = useState('paid');

  const _endDate = ticket?.saleEndDate || eventDetails?.endsAt;

  const preSubmit = async (
    values: ITicketTypeFormInputs,
    dashboard?: boolean
  ) => {
    values.saleStartDate.setHours(
      values.saleStartTime.getHours(),
      values.saleStartTime.getMinutes()
    );

    values.saleEndDate.setHours(
      values.saleEndTime.getHours(),
      values.saleEndTime.getMinutes()
    );

    onSubmit(values, dashboard);
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(2, 'Ticket names must be at least 2 characters')
      .required('A ticket name is required'),
    description: yup.string().nullable(),
    saleStartDate: yup
      .date()
      .nullable()
      .required('Sales start date is required'),
    saleEndDate: yup
      .date()
      .nullable()
      .test(
        'test-saleEndDate',
        'End date must be after start date',
        function checkEndDate() {
          const { saleStartDate, saleEndDate } = this.parent;

          return Date.parse(saleStartDate) <= Date.parse(saleEndDate);
        }
      ),
    saleStartTime: yup.date(),
    saleEndTime: yup.date(),
    type: yup.string().oneOf(['paid', 'free', 'donation']),
    feesType: yup.string().oneOf(['absorb', 'pass']),
    price: yup.string(),
    // .matches(regex.currencyValidatorRegex, 'Please enter a new price'),
    quantity: yup
      .number()
      .integer()
      .min(1, 'Must have at least one ticket available')
      .required('A total quantity is required'),
    minPerOrder: yup
      .number()
      .integer()
      .min(1, 'We can&rsquo;t sell any less than one ticket')
      .max(yup.ref('maxPerOrder'), 'The minimum must be less than the maximum')
      .required('A minimum quantity is required'),
    maxPerOrder: yup
      .number()
      .integer()
      .min(yup.ref('minPerOrder'), 'The maximum must be more than the minimum')
      .required('A maximum quantity is required'),
    showQuantity: yup.boolean(),
    discountCodes: yup.array().of(
      yup.object().shape({
        discountCode: yup.string().required('A code is required'),
        discountAmount: yup.string().required('A discount amount is required'),
        discountLimit: yup.mixed().nullable(),
      })
    ),
  });

  const {
    clearErrors,
    setError,
    setValue,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ITicketTypeFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      id: edit ? ticket.id : undefined,
      eventId: edit ? ticket.eventId : eventId,
      name: edit ? ticket.name : '',
      description: edit ? ticket.description : '',
      saleStartDate: edit ? ticket.saleStartDate : new Date(),
      saleEndDate: _endDate,
      saleStartTime: edit ? ticket.saleStartDate : new Date(),
      saleEndTime: _endDate,
      type: edit ? ticket.type : 'paid',
      feesType: edit ? ticket.feesType : 'pass',
      price: edit ? formatCurrency(ticket.price) : '',
      quantity: edit ? ticket.quantity : 100,
      minPerOrder: edit ? ticket.minPerOrder : 1,
      maxPerOrder: edit ? ticket.maxPerOrder : 10,
      showQuantity: edit ? ticket.showQuantity : false,
      discountCodes: edit ? ticket.discountCodes : [],
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'discountCodes',
  });

  useEffect(() => {
    console.log(isValid);
    console.log(errors);
  }, [isValid, errors]);

  return (
    <Grid container maxWidth="sm" sx={{ p: 4 }}>
      <form onSubmit={handleSubmit((values) => preSubmit(values))}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <ToggleButtonGroup
                  fullWidth
                  {...field}
                  onChange={(_, value) => {
                    if (value === null) return;
                    field.onChange(value);

                    setTicketType(value);
                  }}
                  exclusive
                  color="primary"
                >
                  <ToggleButton value="paid">
                    <AttachMoneyOutlinedIcon />{' '}
                    <Typography ml={2}>Paid</Typography>
                  </ToggleButton>
                  <ToggleButton value="free">
                    <MoneyOffOutlinedIcon />{' '}
                    <Typography ml={2}>Free</Typography>
                  </ToggleButton>
                  <ToggleButton value="donation">
                    <VolunteerActivismOutlinedIcon />{' '}
                    <Typography ml={2}>Donation</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <TextFormField
              name="name"
              control={control}
              label="Name"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {ticketType !== 'free' && (
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onClick={() => setValue('price', '')}
                    variant="outlined"
                    label={
                      ticketType === 'donation' ? 'Minimum Donation' : 'Price'
                    }
                    onBlur={(e: any) => {
                      const _formatted = formatCurrency(e.target.value);
                      if (Number(e.target.value)) {
                        setValue('price', _formatted);
                        clearErrors('price');
                      } else {
                        setError(
                          'price',
                          { message: 'Please enter a valid price' },
                          { shouldFocus: true }
                        );
                      }
                    }}
                    fullWidth
                    required
                    placeholder="$10.00"
                    helperText={
                      errors.price ? (
                        <Typography color="error">
                          {errors.price.message}
                        </Typography>
                      ) : (
                        ' '
                      )
                    }
                    disabled={ticketType === 'free'}
                  />
                )}
              />
            )}
            {ticketType === 'free' && (
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Price"
                    fullWidth
                    required
                    value="FREE"
                    helperText={
                      errors.price ? (
                        <Typography color="error">
                          {errors.price.message}
                        </Typography>
                      ) : (
                        ' '
                      )
                    }
                    disabled
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="Total Quantity"
                  fullWidth
                  required
                  helperText={
                    errors.quantity ? (
                      <Typography color="error">
                        {errors.quantity.message}
                      </Typography>
                    ) : (
                      ' '
                    )
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="feesType"
              control={control}
              render={({ field }) => (
                <FormControl disabled={ticketType === 'free'}>
                  <FormLabel>Pass processing fees or absorb them?</FormLabel>
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="absorb"
                      control={<Radio />}
                      label="Absorb processing fees"
                    />
                    <FormControlLabel
                      value="pass"
                      control={<Radio />}
                      label="Pass fees to attendees"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Typography variant="body1" mb={1}>
              Ticket Sales
            </Typography>
            <Grid container justifyContent="space-between" spacing={1.5}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="saleStartDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker {...field} label="Sales Start" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="saleStartTime"
                  control={control}
                  render={({ field }) => (
                    <TimePicker {...field} label="Start Time" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" pt={2} spacing={1.5}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="saleEndDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker {...field} label="Sales End" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="saleEndTime"
                  control={control}
                  render={({ field }) => (
                    <TimePicker {...field} label="End Time" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider />
          <Grid item xs={12} mt={3}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  helperText={
                    errors.description ? (
                      <Typography color="error">
                        {errors.description.message}
                      </Typography>
                    ) : (
                      ' '
                    )
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" mb={1}>
              Ticket Purchase Limits
            </Typography>
            <Grid
              container
              flexDirection="row"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item xs={6}>
                <Controller
                  name="minPerOrder"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Minimum Quantity"
                      fullWidth
                      type="number"
                      helperText={
                        errors.minPerOrder ? (
                          <Typography color="error">
                            {errors.minPerOrder.message}
                          </Typography>
                        ) : (
                          ' '
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="maxPerOrder"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label="Maximum Quantity"
                      fullWidth
                      type="number"
                      helperText={
                        errors.maxPerOrder ? (
                          <Typography color="error">
                            {errors.maxPerOrder.message}
                          </Typography>
                        ) : (
                          ' '
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="showQuantity"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Show remaining quantity on checkout?</FormLabel>
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Typography variant="body1" mb={1} mt={1}>
                Discount Codes (optional)
              </Typography>
              <Grid item xs={12}>
                <Button
                  onClick={() => append(INITIAL_DISCOUNT_CODE_STATE)}
                  startIcon={<AddIcon />}
                >
                  Add Discount
                </Button>
              </Grid>
              {fields.map((_field, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    flexDirection="row"
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={3}>
                      <TextFormField
                        control={control}
                        name={`discountCodes.${index}.discountCode`}
                        label="Code"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name={`discountCodes.${index}.discountAmount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label="Amount"
                            margin="normal"
                            fullWidth
                            onBlur={(e: any) => {
                              const _value = Number(
                                e.target.value.replaceAll('$', '')
                              );
                              const _formatted = formatCurrency(_value);
                              const _price = Number(
                                getValues('price').replaceAll('$', '')
                              );

                              if (_value > _price) {
                                setError(
                                  `discountCodes.${index}.discountAmount`,
                                  { message: 'Please lower amount' },
                                  { shouldFocus: true }
                                );
                              } else if (Number(_value)) {
                                setValue(
                                  `discountCodes.${index}.discountAmount`,
                                  _formatted
                                );
                                clearErrors(
                                  `discountCodes.${index}.discountAmount`
                                );
                              }
                            }}
                            helperText={
                              errors.discountCodes &&
                              errors.discountCodes[index] &&
                              errors.discountCodes[index]?.discountAmount &&
                              errors.discountCodes[index]?.discountAmount
                                ?.message ? (
                                <Typography variant="caption" color="error">
                                  {errors.discountCodes[
                                    index
                                  ]?.discountAmount?.message?.toString()}
                                </Typography>
                              ) : (
                                ' '
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <NumberFormField
                        control={control}
                        name={`discountCodes.${index}.discountLimit`}
                        label="Limit"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item mt={3}>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button variant="text" onClick={hideDialog}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={isSubmitting}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  type="submit"
                  sx={{ mr: 2 }}
                >
                  Save
                </LoadingButton>
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={handleSubmit((data) => preSubmit(data, true))}
                >
                  Save & Go To Dashboard
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default TicketForm;
