import { ReactNode } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

interface FormSectionProps {
  children: ReactNode;
  title: string;
  subtitle: string;
  icon: ReactNode;
}

const FormSection = ({ children, icon, title, subtitle }: FormSectionProps) => {
  return (
    <>
      {/* Header */}
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid
          item
          xs={2}
          md={1}
          mt={1}
          alignContent="center"
          sx={{ opacity: '50%' }}
        >
          {icon}
        </Grid>
        <Grid item xs={10} md={11} mt={2}>
          <Typography fontSize="24px" fontFamily="Montserrat" fontWeight="bold">
            {title}
          </Typography>
          <Typography fontFamily="Roboto" ml={1}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      {/* Fields */}
      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item xs={12}>
          {children}
          <Divider light sx={{ margin: 2 }} />
        </Grid>
      </Grid>
    </>
  );
};

export default FormSection;
