import { useState } from 'react';

import { Grid, useTheme } from '@mui/material';
import { useMediaQuery, Button } from '@mui/material';
import { Box } from '@mui/system';

import Filters from 'components/filters/Filters';
import { FilterType } from 'components/filters/filterTypes';

interface IHeader {
  filters?: FilterType[];
  handleFilterChange?: (value: any) => void;
  handleExport?: () => void;
  tableTitle?: string;
}

const Header = ({
  filters,
  handleFilterChange,
  handleExport,
  tableTitle,
}: IHeader) => {
  const [exportLoading, setExportLoading] = useState(false);
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const onExport = async () => {
    if (handleExport) {
      try {
        setExportLoading(true);
        await handleExport();
      } finally {
        setExportLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '15px',
        width: '100%',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          flexDirection: matchDownSM ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '130%',
            width: '100%',
          }}
        >
          {tableTitle || ''}
        </Box>

        {handleExport && (
          <Box
            sx={{
              ...(matchDownSM && { width: '100%' }),
            }}
          >
            <Button
              sx={{
                p: 2,
                borderRadius: '30px',
                ...(matchDownSM && { width: '100%' }),
                textTransform: 'uppercase',
              }}
              onClick={onExport}
            >
              {exportLoading ? 'Exporting..' : 'Export'}
            </Button>
          </Box>
        )}
      </Box>
      {filters && handleFilterChange && (
        <Filters filters={filters} onFilterChange={handleFilterChange} />
      )}
    </Box>
  );
};

export default Header;
