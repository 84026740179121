import { useEffect, useState } from 'react';
import fileSaver from 'file-saver';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  CalendarMonthOutlined,
  ExpandLessOutlined,
  PinDropOutlined,
} from '@mui/icons-material';

import Amex from 'assets/payment-types/amex.png';
import Discover from 'assets/payment-types/discover.png';
import MasterCard from 'assets/payment-types/mastercard.png';
import Visa from 'assets/payment-types/visa.png';

import LoadingButton from 'components/buttons/LoadingButton';

import DisplayText from 'components/DisplayText';
import Loading from 'components/Loading';

import EventProps from 'types/Event';
import TransactionProps from 'types/Transactions';
import TransactionItemProps from 'types/TransactionItems';

import { DownloadIcon } from 'material-icons';
import { REACT_APP_CLOUDFRONT_URL } from 'config/config';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';
import { formatEventDateTime } from 'utils/formatDateTime';

export interface OrderDetailsProps extends Partial<TransactionProps> {
  id: number;
  subtotal: string;
  discount: number;
  itemFee: string;
  serviceFee: string;
  processingFee: string;
  total: string;
  date: Date;
  status: string;
  event: EventProps;
  transactionItem: Partial<TransactionItemProps>[];
  type: 'Sponsorship' | 'Ticket';
}

export const INITIAL_STATE: OrderDetailsProps = {
  id: 0,
  subtotal: '',
  discount: 0,
  itemFee: '',
  serviceFee: '',
  processingFee: '',
  total: '',
  date: new Date(),
  status: '',
  transactionItem: [],
  event: {
    id: 0,
    name: '',
    imageUrl: '',
    startsAt: new Date(),
    endsAt: new Date(),
  },
  type: 'Ticket',
};

type LoadingType = {
  downloading: boolean;
};
const loadingState: LoadingType = { downloading: false };

const OrderSummary = ({ details }: { details: OrderDetailsProps }) => {
  const [loading, setLoading] = useState<LoadingType>(loadingState);
  const [paymentType, setPaymentType] = useState<string>();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const { callService } = useCallService();

  useEffect(() => {
    if (details.paymentMethod) {
      setPaymentType(details.paymentMethod);
    }

    switch (details.creditCardType) {
      case 'visa':
        setPaymentType(Visa);
        break;
      case 'discover':
        setPaymentType(Discover);
        break;
      case 'mastercard':
        setPaymentType(MasterCard);
        break;
      case 'american_express':
        setPaymentType(Amex);
        break;
      default:
        break;
    }
  }, []);

  const downloadTickets = async () => {
    setLoading((prevState) => ({ ...prevState, downloading: true }));

    try {
      const { response } = await callService({
        resource: httpRoutes.user.downloadTickets(details.id),
      });

      if (response) {
        // update file name
        const _fileName = `${details.event.name}-Tickets.pdf`;

        const _fileData = new Blob([response], { type: 'application/pdf' });

        fileSaver.saveAs(_fileData, _fileName);
      }
    } finally {
      setLoading((prevState) => ({ ...prevState, downloading: false }));
    }
  };

  if (loading) <Loading />;

  return (
    <>
      <Grid container justifyContent="center" sx={{ m: 0, p: 0 }}>
        <Grid item xs={12} sx={{ background: 'white' }}>
          {/* Image */}
          <img
            src={`${REACT_APP_CLOUDFRONT_URL}/${details.event.imageUrl}`}
            alt={details.event.name}
            style={{
              top: '0',
              left: '0',
              position: 'relative',
              aspectRatio: '2/1',
              objectFit: 'contain',
              height: '24vh',
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <Typography variant="h2">{details.event.name}</Typography>
          <Grid container alignItems="center">
            <CalendarMonthOutlined sx={{ mr: 0.5 }} />
            <Typography>
              {formatEventDateTime(
                details.event.startsAt,
                details.event.timezone
              )}
            </Typography>
          </Grid>

          {details.event.eventLocation && (
            <Grid container alignItems="center">
              <PinDropOutlined sx={{ mr: 0.5 }} />

              <Typography variant="caption">
                {details.event.eventLocation.venueName}{' '}
                {details.event.eventLocation.city},{' '}
                {details.event.eventLocation.state}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ m: 0, p: 1 }}
      >
        <Grid item>
          <Typography>Order # {details.id}</Typography>
        </Grid>
        <Grid item>
          <LoadingButton
            variant="text"
            icon={<DownloadIcon />}
            onClick={downloadTickets}
            loading={loading.downloading}
          >
            Download{' '}
            {details.type === 'Sponsorship' ? 'Sponsorships' : 'Tickets'}
          </LoadingButton>
        </Grid>
      </Grid>
      {/* Cart Summary */}
      <Grid item p={2} mt={3}>
        <Typography mb={2} fontWeight="semibold">
          Items
        </Typography>
        {/* Cart Items */}
        <Grid item>
          {details.transactionItem.map((item: any) => {
            if (item.quantity > 0) {
              return (
                <Grid
                  key={item.id}
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                  mb={1}
                >
                  <Grid item xs={9}>
                    {item.eventTickets.length > 0 && (
                      <Typography fontSize="12px">
                        {item.quantity} X{' '}
                        {item.eventTickets[0].eventTicketType.name}
                      </Typography>
                    )}
                    {item.eventSponsorships.length > 0 && (
                      <Typography fontSize="12px">
                        {item.quantity} X{' '}
                        {item.eventSponsorships[0].eventSponsorshipType.name}
                      </Typography>
                    )}
                    {item.discountCode && (
                      <DisplayText
                        style={{ marginLeft: 2, marginTop: 2, color: 'blue' }}
                        type="bodyXSmall"
                        text={`${
                          item.discountCode.discountCode
                        } (${formatCurrency(
                          item.discountCode.discountAmount
                        )}) applied`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography textAlign="right" alignSelf="center">
                      {formatCurrency(
                        Number(item.subtotal) + Number(item.discount)
                      )}
                    </Typography>
                    {item.discount > 0 && (
                      <>
                        <Typography
                          textAlign="right"
                          alignSelf="center"
                          sx={{ color: 'blue' }}
                        >
                          - {formatCurrency(item.discount)}
                        </Typography>
                        <Divider />
                        <Typography textAlign="right" alignSelf="center">
                          {formatCurrency(Number(item.subtotal))}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {/* Cart Totals */}
          <Accordion
            sx={{
              position: 'fixed',
              bottom: 0,
              width: matchDownSM ? '100%' : '30rem',
              paddingBottom: 2,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandLessOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid
                container
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography fontWeight="bold" textTransform="uppercase">
                  Total
                </Typography>
                <Typography fontWeight="bold">
                  {formatCurrency(details.total)}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <Grid
                mt={2}
                container
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography>Subtotal</Typography>
                <Typography>
                  {formatCurrency(
                    Number(details.subtotal) + Number(details.discount)
                  )}
                </Typography>{' '}
              </Grid>
              {details.discount > 0 && (
                <Grid
                  mt={1}
                  mb={1}
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Typography>Discounts</Typography>
                  <Typography>- {formatCurrency(details.discount)}</Typography>
                </Grid>
              )}

              <Grid
                mt={1}
                mb={1}
                container
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography>Fees</Typography>
                <Typography>
                  {formatCurrency(
                    Number(details.itemFee) +
                      Number(details.processingFee) +
                      Number(details.serviceFee)
                  )}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                mt={2}
                container
                justifyContent="space-between"
                flexDirection="row"
              >
                <Typography fontWeight="bold" textTransform="uppercase">
                  Total
                </Typography>
                <Typography fontWeight="bold">
                  {formatCurrency(details.total)}
                </Typography>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderSummary;
