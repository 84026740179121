import { Outlet, RouteObject } from 'react-router-dom';

import {
  Attendees,
  Dashboard,
  Events,
  EventDetails,
  GeneralInfo,
  Tickets,
  Transactions,
  Sponsors,
  Sponsorships,
} from 'features/events/pages';

import Create from 'features/events/components/Create';

import { OrderHistory, UserProfile } from 'features/userProfile/pages';

import OrganizerEventLayout from 'layout/OrganizerEventLayout/index';

const RequireAuth = () => {
  return <Outlet />;
};

const PrivateRoutes: RouteObject[] = [
  {
    path: '/organizer',
    element: <RequireAuth />,
    children: [
      {
        path: '/organizer/events',
        element: <Events />,
      },
      {
        path: '/organizer/events/create',
        element: <Create />,
      },
      {
        path: '/organizer/events/:id',
        element: <OrganizerEventLayout />,
        children: [
          {
            path: '/organizer/events/:id/attendees',
            element: <Attendees />,
            children: [
              {
                path: '/organizer/events/:id/attendees/:ticketCode',
                element: <Attendees />,
              },
            ],
          },
          {
            path: '/organizer/events/:id/dashboard',
            element: <Dashboard />,
            index: true,
          },
          {
            path: '/organizer/events/:id/details',
            element: <EventDetails />,
          },
          {
            path: '/organizer/events/:id/general-info',
            element: <GeneralInfo />,
          },
          {
            path: '/organizer/events/:id/tickets',
            element: <Tickets />,
          },
          {
            path: '/organizer/events/:id/transactions',
            element: <Transactions />,
          },
          {
            path: '/organizer/events/:id/sponsors',
            element: <Sponsors />,
          },
          {
            path: '/organizer/events/:id/sponsorships',
            element: <Sponsorships />,
          },
        ],
      },
    ],
  },
  {
    path: '/user',
    element: <RequireAuth />,
    children: [
      {
        path: '/user/profile',
        element: <UserProfile />,
      },
      {
        path: '/user/order-history',
        element: <OrderHistory />,
      },
    ],
  },
];

export default PrivateRoutes;
