import { useState } from 'react';

import {
  Alert as MUIAlert,
  AlertProps,
  Collapse,
  IconButton,
} from '@mui/material';

import { CloseIcon } from 'material-icons';

interface IAlertProps extends AlertProps {
  text: string;
}

const Alert = ({ action, severity, text, ...rest }: IAlertProps) => {
  const [open, setOpen] = useState(true);

  const defaultAction = (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        setOpen(false);
      }}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  return (
    <Collapse in={open}>
      <MUIAlert severity={severity} action={action || defaultAction} {...rest}>
        {text}
      </MUIAlert>
    </Collapse>
  );
};

export default Alert;
