import { Outlet } from 'react-router-dom';

import { Box, Fade, Stack } from '@mui/material';

import AppBar from './AppBar';
import Footer from 'components/Footer';

const MainLayout = () => {
  return (
    <Stack sx={{ margin: 0, padding: 0 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: '#f4f2f5',
        }}
      >
        <AppBar />
        <Fade in={true} easing={{ enter: '400', exit: '400' }}>
          <Box
            component="main"
            sx={{
              backgroundAttachment: 'fixed',
              backgroundSize: 'cover',
              backgroundColor: '#f4f2f5',
              width: '100%',
              flexGrow: 1,
              p: { xs: 0, md: 1 },
            }}
          >
            <Outlet />
          </Box>
        </Fade>
      </Box>
      <Footer />
    </Stack>
  );
};

export default MainLayout;
