import { Container, Grid, Typography, Divider, Button } from '@mui/material';
import { fontWeight } from '@mui/system';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

export interface CancelEventProps {
  id: number;
  name: string;
  hideDialog: VoidFunction;
}

const CancelEvent = ({ id, name, hideDialog }: CancelEventProps) => {
  const { callService } = useCallService();

  console.log(id, name);

  const cancelEvent = async () => {
    try {
      const { response } = await callService({
        resource: httpRoutes.admin.events.cancelEvent(id),
        successMessage: 'Event successfully canceled!',
      });

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container flexDirection="column" spacing={1} mb={2}>
        <Grid item>
          <Typography variant="h1" sx={{ pb: 4, textAlign: 'center' }}>
            Cancel Event
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            Are you sure you want to cancel
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            {name}?
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container justifyContent={'space-between'} p={3}>
        <Button variant="text" onClick={() => hideDialog()}>
          Close
        </Button>
        <Button variant="contained" onClick={() => cancelEvent()}>
          Cancel Event
        </Button>
      </Grid>
    </>
  );
};

export default CancelEvent;
