export function formatDate(dateTime) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const date = new Date(dateTime);

  return date.toLocaleDateString('en-US', options);
}

export function formatDateTime(dateTime, timezone) {
  const timeOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timezone: timezone,
  };

  const date = new Date(dateTime);

  return new Intl.DateTimeFormat('en-US', timeOptions).format(date);
}

export function formatEventStartDate(dateTime) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(dateTime);

  return date.toLocaleDateString('en-US', options);
}

export function formatEventDate(startDate, timeZone) {
  const date = new Date(startDate);

  return date.toLocaleDateString('en-US', {
    dateStyle: 'long',
    timeZone: timeZone,
  });
}

export function formatEventTime(dateTime, timeZone) {
  const date = new Date(dateTime);

  return new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    hour: 'numeric',
    minute: '2-digit',
  }).format(date);
}

export function formatEventDateTime(startDate, timeZone) {
  const date = new Date(startDate);
  // return new Intl.DateTimeFormat('en-US', {
  //   dateStyle: 'long',
  //   timeStyle: 'long',
  //   timeZone: timeZone,
  // }).format(startDate);
  const formattedDate = date.toLocaleDateString('en-US', {
    dateStyle: 'long',
    timeZone: timeZone,
  });

  const time = date.toLocaleTimeString('en-US', {
    timeZone: timeZone,
    hour: 'numeric',
    minute: '2-digit',
  });

  return formattedDate + ' at ' + time;
}

export function endsAt(endDate, timeZone) {
  // return new Intl.DateTimeFormat('en-US', {
  //   dateStyle: 'long',
  //   timeStyle: 'long',
  //   timeZone: timeZone,
  // }).format(endDate);
  return endDate.toLocaleString('en-US', { timeZone: timeZone });
}
