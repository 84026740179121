import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper from 'features/auth/components/AuthWrapper';
import AuthCardWrapper from 'features/auth/components/AuthCardWrapper';
import AuthResetPassword from 'features/auth/components/AuthResetPassword';

import Logo from 'components/Logo';
import { useParams } from 'react-router-dom';

export type ResetPasswordProps = {
  firstName: string;
  lastName: string;
  token: string;
};

const ResetPassword = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { token = '' } = useParams();
  const navigate = useNavigate();

  return (
    <AuthWrapper>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 'calc(100vh - 68px)' }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction={matchDownSM ? 'column-reverse' : 'row'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          {!isSubmitted && (
                            <>
                              <Typography
                                variant="caption"
                                fontSize="16px"
                                textAlign={matchDownSM ? 'center' : 'inherit'}
                              >
                                Please create your new password below
                              </Typography>
                            </>
                          )}
                          {isSubmitted && (
                            <>
                              <Typography
                                color={theme.palette.secondary.main}
                                gutterBottom
                                variant={matchDownSM ? 'h3' : 'h2'}
                                textAlign="center"
                              >
                                Success, your password has been updated!
                              </Typography>
                              <Typography
                                variant="caption"
                                fontSize="16px"
                                textAlign={matchDownSM ? 'center' : 'inherit'}
                              >
                                Password successfully changed. Please log in.
                              </Typography>
                              <Button
                                sx={{ mt: 2 }}
                                variant="contained"
                                onClick={() => navigate('/')}
                              >
                                Click here to go home
                              </Button>
                            </>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {!isSubmitted && (
                      <AuthResetPassword
                        setIsSubmitted={setIsSubmitted}
                        token={token}
                      />
                    )}
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ResetPassword;
