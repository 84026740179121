import { useEffect, useState } from 'react';

import Loading from 'components/Loading';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import OrderSummary, {
  INITIAL_STATE,
  OrderDetailsProps,
} from 'shared/OrderSummary';

const UserOrderSummary = ({ orderId }: { orderId: number }) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState<OrderDetailsProps>(INITIAL_STATE);

  const { callService } = useCallService();

  useEffect(() => {
    setLoading(true);

    const getOrderDetails = async (orderId: number) => {
      const { response } = await callService({
        resource: httpRoutes.user.getOrderDetails(orderId),
      });

      if (response) {
        const imageUrl = response.imageUrl || 'EventHiDefault.jpeg';

        setDetails({ imageUrl, ...response });
        setLoading(false);
      }
    };

    getOrderDetails(orderId);
  }, [orderId]);

  if (loading) <Loading />;

  return <OrderSummary details={details} />;
};

export default UserOrderSummary;
