import { TableRow, TableCell, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

interface ITableBodyRow {
  row: any;
  columns: any[];
  actionColumn: any;
  renderFormattedValue: (field: any, type: any, label: any) => any;
  rowIdentifier: string | number;
  handleCellClick?: (params: any) => void;
  handleRowClick?: (params: any) => void;
}

const TableBodyRow = ({
  row,
  columns,
  actionColumn: ActionColumn,
  renderFormattedValue,
  rowIdentifier,
  handleCellClick,
  handleRowClick,
}: ITableBodyRow) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const onRowClick = () => {
    if (handleRowClick) {
      handleRowClick(row);
    }
  };

  const onCellClick = (field: string) => {
    if (handleCellClick) {
      handleCellClick({ row, field });
    }
  };

  return (
    <TableRow
      sx={{ cursor: handleRowClick ? 'pointer' : 'auto', height: '54px' }}
      onClick={onRowClick}
    >
      {columns.map(
        ({ field, ColumnComponent, flex, type, label, align }, index) => {
          const textAlign = align || (type === 'number' ? 'right' : 'left');

          const _isClickable =
            (handleRowClick && index === 0) || handleCellClick;

          if (field === 'mobile' && matchDownSM) {
            return (
              <TableCell
                key={`${field}-${row[rowIdentifier]}`}
                sx={{
                  py: 0,
                  flex,
                  color: _isClickable ? '#508AC2' : 'black',
                  fontSize: _isClickable ? '16px' : '14px',
                  cursor: _isClickable ? 'pointer' : 'auto',
                }}
                align={textAlign}
                onClick={() => {
                  onCellClick(field);
                }}
              >
                {ColumnComponent ? (
                  <ColumnComponent
                    value={row[field]}
                    type={type}
                    label={label}
                    row={row}
                  />
                ) : (
                  <Box
                    sx={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '130%',
                    }}
                  >
                    {renderFormattedValue(row[field], type, label)}
                  </Box>
                )}
              </TableCell>
            );
          }

          if (field !== 'mobile' && !matchDownSM) {
            return (
              <TableCell
                key={`${field}-${row[rowIdentifier]}`}
                sx={{
                  py: 0,
                  flex,
                  color: _isClickable ? '#508AC2' : 'black',
                  fontSize: _isClickable ? '16px' : '14px',
                  cursor: _isClickable ? 'pointer' : 'auto',
                }}
                align={textAlign}
                onClick={() => {
                  onCellClick(field);
                }}
              >
                {ColumnComponent ? (
                  <ColumnComponent
                    value={row[field]}
                    type={type}
                    label={label}
                    row={row}
                  />
                ) : (
                  <Box
                    sx={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '130%',
                    }}
                  >
                    {renderFormattedValue(row[field], type, label)}
                  </Box>
                )}
              </TableCell>
            );
          }
        }
      )}
    </TableRow>
  );
};

export default TableBodyRow;
