import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useCallService from 'hooks/useCallService';

import EventGeneralInfo, {
  IEventFormInputs,
} from 'features/events/forms/EventGeneralInfoForm';

import PageContainer from 'components/PageContainer';

import httpRoutes from 'utils/httpRoutes';
import { useAuthState } from 'providers/AuthProvider/hooks';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import SignUp from 'features/auth/dialogs/SignUp';

const CreateEvent = () => {
  const { callService } = useCallService();
  const { authState } = useAuthState();
  const { hideDialog, showDialog } = useDialogDispatcher();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState.authenticated) {
      showDialog({
        size: 'sm',
        allowClose: false,
        content: (
          <SignUp customMessage="You must create an account before creating an event" />
        ),
      });
    }

    if (authState.authenticated) {
      hideDialog();
    }
  }, [authState]);

  const onCancel = async () => {
    navigate(-1);
  };

  const createEvent = async (values: IEventFormInputs) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.createEvent(values),
      });

      if (response) {
        navigate(
          `/organizer/events/${encodeURIComponent(response.id)}/details`
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageContainer title="General Info" maxWidth="md">
      <EventGeneralInfo onSubmit={createEvent} onBack={onCancel} />
    </PageContainer>
  );
};

export default CreateEvent;
