import React, { useEffect, useState } from 'react';
import { Avatar, Box, Card, Grid, Skeleton, Typography } from '@mui/material';

import { useCallService } from 'hooks';
import { EmptyIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';

const TicketSalesLoading = () => (
  <Box sx={{ width: '100%', height: '100%' }}>
    <Skeleton variant="rectangular" height="100%" width="100%" />
  </Box>
);

export interface StatValues {
  name: string;
  value: string;
  quantity: string;
}

const TicketSales = ({ eventId }: { eventId: number }) => {
  const { callService } = useCallService();
  const [topSales, setTicketSales] = useState<StatValues[]>([]);
  const [loading, setLoading] = useState(true);

  const getTicketSales = async () => {
    try {
      if (eventId) {
        const { response } = await callService({
          resource: httpRoutes.organizer.getTicketSales(eventId),
        });

        if (response && response.length > 0) {
          const stats: StatValues[] = [];
          let totalRevenue = 0;
          let totalTickets = 0;

          for (let i = 0; i < response.length; i++) {
            const item = response[i];

            stats.push({
              name: item.etv_ticket_type_name,
              value: item.etv_total_revenue,
              quantity: item.etv_quantity,
            });

            totalRevenue += Number(item.etv_total_revenue);
            totalTickets += Number(item.etv_quantity);
          }

          stats.push({
            name: 'TOTALS',
            value: totalRevenue.toString(),
            quantity: totalTickets.toString(),
          });

          setTicketSales(stats);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTicketSales();
  }, [eventId]);

  return (
    <Card
      elevation={2}
      sx={{
        p: 4,
        borderRadius: '15px',
        backgroundColor: 'white',
        flex: '0.34 0 auto',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          TICKET SALES
        </Box>
        <Box
          sx={{
            fontFamily: 'RobotoSlab',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by ticket type
        </Box>
      </Box>
      {!loading && topSales.length < 1 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            mt: '25%',
          }}
        >
          <Avatar
            sx={{
              height: '80px',
              width: '80px',
              color: 'white',

              backgroundColor: 'lightGrey',
            }}
          >
            <EmptyIcon sx={{ fontSize: '3rem' }} />
          </Avatar>
          <Typography fontWeight="300">Nothing here yet.</Typography>
        </Box>
      )}
      {loading ? (
        <TicketSalesLoading />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {topSales.map((state, index) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom:
                  index !== topSales.length - 1 ? '1px solid #BCC6CF' : 'none',
                pb: 1,
              }}
              key={state.name}
            >
              <Box
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '32px',
                  color: '#162738',
                }}
              >
                <>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      fontSize: state.name === 'TOTALS' ? '20px' : '16px',
                      lineHeight: '16px',
                      color: '#162738',
                    }}
                  >
                    {state.name}
                  </Typography>

                  <Grid container>
                    <Grid item alignSelf="center">
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'inherit',
                        }}
                        align="right"
                      >
                        {new Intl.NumberFormat('en-US').format(
                          Number(state.quantity)
                        )}{' '}
                        <span style={{ color: 'grey' }}>tickets sold</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              </Box>
              <Box
                sx={{
                  fontWeight: state.name === 'TOTALS' ? '600' : '300',
                  fontSize: '40px',
                  lineHeight: '120%',
                  color: '#287E7D',
                }}
              >
                <>{formatCurrency(state.value)}</>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Card>
  );
};

export default TicketSales;
