import { useEffect, useState } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

// third-party
import useCallService from 'hooks/useCallService';

import EventDetailsForm, {
  IEventDetailFormInputs,
  INITIAL_EVENT_DETAIL_FORM_STATE,
} from '../forms/EventDetailsForm';

import Loading from 'components/Loading';
import PageContainer from 'components/PageContainer';

import httpRoutes from 'utils/httpRoutes';

const EventDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValues, setDefaultValues] = useState(
    INITIAL_EVENT_DETAIL_FORM_STATE
  );

  const { callService } = useCallService();

  const params = useParams();
  const navigate = useNavigate();

  const { id } = params;
  const _eventId = Number(id);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    const getEvent = async () => {
      setIsLoading(true);

      try {
        const { response } = await callService({
          resource: httpRoutes.organizer.getEvent(_eventId),
        });

        if (response) {
          const _editValues: IEventDetailFormInputs = {
            id: response.eventDetails.id,
            description: response.eventDetails.description,
            twitterLink: response.eventDetails.twitterLink,
            facebookLink: response.eventDetails.facebookLink,
            instagramLink: response.eventDetails.instagramLink,
            imageUrl: response.eventDetails.imageUrl,
            websiteLink: response.eventDetails.websiteLink,
          };

          setDefaultValues(_editValues);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (_eventId > 0) getEvent();
  }, []);

  const onSubmit = async (
    values: IEventDetailFormInputs,
    tickets?: boolean
  ) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.updateEventDetails(values),
        successMessage: 'Changes saved!',
      });

      if (response) {
        if (tickets) {
          navigate(`/organizer/events/${_eventId}/tickets`);
        } else {
          navigate(`/organizer/events/${_eventId}/dashboard`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageContainer title="Additional Details" maxWidth="md">
      {isLoading ? (
        <Loading />
      ) : (
        <EventDetailsForm
          eventId={_eventId}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      )}
    </PageContainer>
  );
};

export default EventDetails;
