import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

// project imports
import AuthWrapper from 'features/auth/components/AuthWrapper';
import AuthForgotPassword from 'features/auth/components/AuthForgotPassword';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Logo from 'components/Logo';

import Login from '../dialogs/Login';
import SignUp from '../dialogs/SignUp';

const ForgotPassword = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const { hideDialog, showDialog } = useDialogDispatcher();

  const showLogin = () => {
    showDialog({
      size: 'sm',
      content: <Login />,
    });
  };

  const showSignUp = () => {
    showDialog({
      size: 'sm',
      content: <SignUp />,
    });
  };

  return (
    <AuthWrapper>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item sx={{ mb: 3 }}>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction={matchDownSM ? 'column-reverse' : 'row'}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                {!isSubmitted && (
                  <>
                    <Typography
                      color={theme.palette.secondary.main}
                      gutterBottom
                      variant={matchDownSM ? 'h3' : 'h2'}
                      textAlign="center"
                    >
                      Hey, no worries about your password.
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize="16px"
                      textAlign={matchDownSM ? 'center' : 'inherit'}
                    >
                      We&apos;ll send you a link to set a new one.
                    </Typography>
                  </>
                )}
                {isSubmitted && (
                  <>
                    <Typography
                      color={theme.palette.secondary.main}
                      gutterBottom
                      variant={matchDownSM ? 'h3' : 'h2'}
                      textAlign="center"
                    >
                      Reset password email sent!
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize="16px"
                      textAlign={matchDownSM ? 'center' : 'inherit'}
                    >
                      Click the link in your email to reset your password
                    </Typography>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AuthForgotPassword setIsSubmitted={setIsSubmitted} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container direction="column" alignItems="center" xs={12}>
          <Button variant="text" onClick={showLogin}>
            Oh wait! I remember it now.
          </Button>
          <Button variant="text" onClick={showSignUp}>
            Don&apos;t have an account?
          </Button>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ForgotPassword;
