// Application variables
export const REACT_APP_CLIENT_URL: string =
  process.env.REACT_APP_CLIENT_URL || 'https://eventhi.io';
export const REACT_APP_API_URL: string =
  process.env.REACT_APP_API_URL || 'http://localhost:5001';
export const NODE_ENV: string = process.env.NODE_ENV || 'development';

// AWS variables
export const REACT_APP_AWS_REGION: string =
  process.env.REACT_APP_AWS_REGION || 'us-west-2';
export const REACT_APP_S3_URL =
  process.env.REACT_APP_S3_URL ||
  'http://eventhi-production.s3.us-west-2.amazonaws.com/';
export const REACT_APP_AWS_BUCKET =
  process.env.REACT_APP_AWS_BUCKET || 'eventhi-production';

export const REACT_APP_CLOUDFRONT_URL =
  process.env.REACT_APP_CLOUDFRONT_URL || '';

export const REACT_APP_MUI_KEY = process.env.REACT_APP_MUI_KEY || '';

// Third-Party variables
export const REACT_APP_GOOGLE_API_KEY: string =
  process.env.REACT_APP_GOOGLE_API_KEY || '';

// Spreedly Key
export const REACT_APP_SPREEDLY_KEY: string =
  process.env.REACT_APP_SPREEDLY_KEY || '';

// Staging
// export const REACT_APP_SQUARE_APPLICATION_ID: string =
//   process.env.REACT_APP_SQUARE_APPLICATION_ID ||
//   'sandbox-sq0idb-ftwmGHNpO7zWYLFrwOm3Yg';

// export const REACT_APP_SQUARE_LOCATION_ID: string =
//   process.env.REACT_APP_SQUARE_LOCATION_ID || 'L9Y105KJEEDNS';

// Production
// export const REACT_APP_SQUARE_APPLICATION_ID: string =
//   process.env.REACT_APP_SQUARE_APPLICATION_ID ||
//   'sq0idp-qeCgyUh94bm79LAQSJ4kZA';

// export const REACT_APP_SQUARE_LOCATION_ID: string =
//   process.env.REACT_APP_SQUARE_LOCATION_ID || '545SAMRYZY14B';

// export const REACT_APP_GOOGLE_ANALYTICS: string =
//   process.env.REACT_APP_GOOGLE_ANALYTICS || 'G-NSYY0BZVHF';
