import React, { memo, useCallback } from 'react';
import DialogContent from '@mui/material/DialogContent';
import MuiDialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from 'components/buttons/IconButton';
import { CloseIcon } from 'material-icons';
import {
  Container,
  DialogActions,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

export interface DialogProps {
  isOpen: boolean;
  title?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  content: React.ReactNode;
  actions?: React.ReactNode;
  setIsOpen: (value: boolean) => void;
  fullScreen?: boolean;
  disableEscapeKeyDown?: boolean;
  enableBackdropClose?: boolean;
  allowClose?: boolean;
  onClose?: () => void;
  sx?: any;
}

const Dialog = ({
  isOpen,
  title,
  size = 'lg',
  content,
  actions,
  setIsOpen,
  fullScreen,
  disableEscapeKeyDown,
  enableBackdropClose = true,
  allowClose = true,
  onClose,
  sx,
}: DialogProps) => {
  const { hideDialog } = useDialogDispatcher();
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const closeDialog = () => {
    if (allowClose) {
      setIsOpen(false);
      hideDialog();

      if (onClose) {
        onClose();
      }
    }
  };

  const handleBackdropClick = useCallback(() => {
    closeDialog();
  }, [enableBackdropClose]);

  return (
    <MuiDialog
      open={isOpen}
      maxWidth={size}
      fullScreen={matchDownMD || fullScreen}
      fullWidth
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={closeDialog}
      sx={sx}
    >
      <Grid container justifyContent="end" sx={{ p: 0.5 }}>
        <IconButton
          color="inherit"
          icon={<CloseIcon />}
          onClick={closeDialog}
        />
      </Grid>

      {title && (
        <Typography variant="h3" sx={{ mt: 2, ml: 2, mb: 2 }}>
          {title}
        </Typography>
      )}

      <DialogContent>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
};

export default memo(Dialog);
