import React from 'react';

import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { Switch, FormControl, FormControlLabel, SxProps } from '@mui/material';

interface SwitchFieldProps {
  label: string | React.ReactElement;
  size?: 'small' | 'medium';
  labelPlacement?: 'top' | 'bottom' | 'end' | 'start';
  checkedIcon?: React.ReactElement;
  icon?: React.ReactElement;
  sx?: SxProps;
}

export type SwitchFormFieldProps<T extends FieldValues> = SwitchFieldProps &
  UseControllerProps<T>;

const SwitchFormField = <T extends FieldValues>(
  props: SwitchFormFieldProps<T>
) => {
  const { label, name, control, labelPlacement, size, sx } = props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });

  return (
    <FormControl error={Boolean(errors[name])} sx={sx}>
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                // onChange={(e) => {
                //   console.log(e);
                //   field.onChange(e.target.checked);
                // }}
                // value={field.value}
                checked={field.value === true}
                size={size}
              />
            )}
          />
        }
      />
    </FormControl>
  );
};

export default SwitchFormField;
