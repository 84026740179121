import { useNavigate } from 'react-router-dom';

import MuiCard from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from 'components/Typography';

import { formatEventDate, formatEventTime } from 'utils/formatDateTime';
import { REACT_APP_CLOUDFRONT_URL } from 'config/config';

export type EventCardProps = {
  id: number;
  name: string;
  startsAt: string;
  ticketsStartingAt: string;
  nameSlug: string;
  cannabisConsumption: boolean;
  headerImageUrl: string;
  timezone: string;
  placeId?: string;
  longitude?: string;
  latitude?: string;
  location?: string;
};

const EventCard = ({ item }: { item: EventCardProps }) => {
  const navigate = useNavigate();

  const imageUrl = item.headerImageUrl || 'EventHiDefault.jpeg';

  const startsAtFormatted = new Intl.DateTimeFormat('en-US', {
    timeZone: item.timezone,
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'shortGeneric',
  }).format(new Date(item.startsAt));

  return (
    <MuiCard
      sx={{
        transition: '.5s',
        boxShadow: 5, // theme.shadows[20]
        border: '.5px solid #CCCCCC',
        borderRadius: 2,
        ':hover': {
          boxShadow: 10, // theme.shadows[20]
        },
        height: '100%',
      }}
    >
      <>
        <CardActionArea onClick={() => navigate(`/events/${item.nameSlug}`)}>
          <CardMedia
            component="img"
            image={`${REACT_APP_CLOUDFRONT_URL}/${imageUrl}`}
            sx={{
              top: '0',
              left: '0',
              position: 'relative',
              aspectRatio: '2/1',
              width: 'inherit',
            }}
          />

          <CardContent sx={{ mb: 'none' }}>
            <Typography variant="h5" content={item.name} noWrap />
            <br />
            <Typography
              variant="body1"
              color="#ec009c"
              content={`${formatEventDate(
                item.startsAt,
                item.timezone
              )} at ${startsAtFormatted}`}
            />
            {item.location && (
              <Typography paragraph variant="caption" content={item.location} />
            )}

            {item.ticketsStartingAt && (
              <Typography
                paragraph
                variant="caption"
                content={`Tickets starting at $${item.ticketsStartingAt}`}
              />
            )}
          </CardContent>
        </CardActionArea>
      </>
    </MuiCard>
  );
};
export default EventCard;
