import HomeHeaderImage from 'assets/images/headers/bg-gateway-to-cannabis-culture.png';

import HeroImage from 'components/HeroImage';

import EventListPartial from 'features/upcomingEvents/partials/EventListPartial';

import SEO from 'components/SEO';
import { REACT_APP_CLIENT_URL } from 'config/config';

const HomePage = () => {
  return (
    <>
      <SEO
        title="EventHi - Your gateway to the Cannabis Culture"
        description="EventHi - Discover Cannabis Events or Create Your Own Experience.
      EventHi has the tools and support you need to make your next Cannabis event a success.
      www.eventhi.io"
        url={REACT_APP_CLIENT_URL}
      />
      <HeroImage imgUrl={HomeHeaderImage} />
      <EventListPartial />
    </>
  );
};

export default HomePage;
