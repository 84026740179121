import { Divider, Grid, Typography } from '@mui/material';

import DisplayText from 'components/DisplayText';

import useCheckoutStore from 'states/checkoutState';

import { formatCurrency } from 'utils/helpers';
import { formatEventDate, formatEventTime } from 'utils/formatDateTime';

const OrderSummary = () => {
  const checkoutStore = useCheckoutStore();

  const eventStart =
    checkoutStore.state.timezone !== ''
      ? `${formatEventDate(
          checkoutStore.state.startsAt,
          checkoutStore.state.timezone
        )} 
  ${formatEventTime(
    checkoutStore.state.startsAt,
    checkoutStore.state.timezone
  )}`
      : '';

  return (
    <Grid
      item
      sm={5}
      xs={12}
      p={2}
      sx={{
        border: '1px solid gray',
        borderRadius: '8px',
        height: 'inherit',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" textAlign="center">
            {checkoutStore.state.eventName}
          </Typography>
          <Typography textAlign="center">
            {eventStart}
            {checkoutStore.state.timezone && (
              <span style={{ fontSize: '.5rem' }}>
                ({checkoutStore.state.timezone})
              </span>
            )}
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <Typography mb={2} fontWeight="semibold">
        Items
      </Typography>
      <Divider />
      {/* Cart Items */}
      <Grid container>
        {checkoutStore.state.items.length > 0 &&
          checkoutStore.state.items.map((item: any) => {
            let _quantityText = `${item.quantity} ${
              checkoutStore.state.type === 'sponsorship'
                ? 'Sponsorship'
                : 'Ticket'
            }`;

            _quantityText += item.quantity > 1 ? 's' : '';

            if (item.quantity > 0) {
              return (
                <Grid
                  key={item.id}
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                  sx={{
                    paddingBottom: '8px',
                  }}
                >
                  <Grid item xs={9}>
                    <DisplayText type="bodySmall" text={`${item.name}`} />
                    <DisplayText
                      style={{ marginLeft: 1 }}
                      type="bodyXSmall"
                      text={_quantityText}
                    />
                    {item.discountAmount > 0 && item.discountCode && (
                      <DisplayText
                        style={{
                          marginLeft: 2,
                          marginTop: 2,
                          color: '#2196f3',
                        }}
                        type="bodyXSmall"
                        text={`${item.discountCode} (${formatCurrency(
                          item.discountAmount
                        )}) applied`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography textAlign="right" alignSelf="center">
                      {formatCurrency(item.subtotal + item.discountAmount)}
                    </Typography>
                    {item.discountAmount > 0 && (
                      <Typography
                        textAlign="right"
                        alignSelf="center"
                        sx={{ color: '#2196f3' }}
                      >
                        - {formatCurrency(item.discountAmount)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              );
            }
          })}
      </Grid>
      {/* Cart Totals */}
      <Divider />
      <Grid
        mt={2}
        container
        justifyContent="flex-end"
        flexDirection="row"
        columnSpacing={2}
      >
        <Grid item>
          <Typography>Subtotal</Typography>
        </Grid>
        <Grid item>
          <Typography>
            {formatCurrency(checkoutStore.state.subtotal)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        mt={2}
        container
        justifyContent="flex-end"
        flexDirection="row"
        columnSpacing={2}
      >
        <Grid item>
          <Typography>Fees</Typography>
        </Grid>
        <Grid item>
          <Typography>
            {formatCurrency(
              Number(checkoutStore.state.itemFee) +
                Number(checkoutStore.state.processingFee) +
                Number(checkoutStore.state.serviceFee)
            )}
          </Typography>{' '}
        </Grid>
      </Grid>
      <Divider />
      <Grid mt={2} container justifyContent="flex-end" flexDirection="row">
        <Grid
          item
          xs={6}
          sx={{
            height: 'inherit',
            mt: 0.5,
            margin: 'auto 0',
          }}
        >
          <Grid
            container
            justifyContent="flex-end"
            flexDirection="row"
            columnSpacing={2}
            alignItems="center"
          >
            <Grid item>
              <Typography fontSize="16px">Total Due</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {formatCurrency(checkoutStore.state.total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
