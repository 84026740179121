import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

// project imports
import AuthWrapper from 'features/auth/components/AuthWrapper';
import AuthRegister from 'features/auth/components/AuthRegister';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Logo from 'components/Logo';

import Login from './Login';

const Register = ({ customMessage }: { customMessage?: string }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [message, setMessage] = useState('Enter your credentials to continue');

  const { hideDialog, showDialog } = useDialogDispatcher();

  const showLogin = () => {
    showDialog({
      size: 'sm',
      content: <Login />,
    });
  };

  const onSuccess = () => {
    hideDialog();
  };

  const cancel = () => {
    navigate(-1);
    onSuccess();
  };

  useEffect(() => {
    if (customMessage) {
      setMessage(customMessage);
    }
  }, [customMessage]);

  return (
    <AuthWrapper>
      <Grid container justifyContent="center">
        <Grid item>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction={matchDownSM ? 'column-reverse' : 'row'}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <Typography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  fontWeight="bold"
                  fontFamily="Montserrat"
                  fontSize={matchDownSM ? '16px' : '48px'}
                  sx={{
                    textShadow: '1px 1px 1px rgb(122, 122, 122, 0.45)',
                  }}
                >
                  Sign Up
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="16px"
                  textAlign={matchDownSM ? 'center' : 'inherit'}
                >
                  {message}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AuthRegister onSuccess={onSuccess} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button variant="outlined" onClick={cancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={showLogin}>
                Already have an account?
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Register;
