import { useState, useEffect } from 'react';

import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
} from '@mui/material';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { useRightDrawerDispatcher } from 'providers/RightDrawerProvider/hooks/useRightDrawerDispatcher';

import OrderSummary from '../rightDrawer/OrderSummary';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import PageContainer from 'components/PageContainer';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';
import { formatDate } from 'utils/formatDateTime';
import { EmailIcon, MoneyIcon } from 'material-icons';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

let transactionsRequest: AbortController | undefined;

const TransactionsGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showRightDrawer } = useRightDrawerDispatcher();

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getEventTransactions();
  }, [state, filters]);

  const getOrderDetails = (orderId: number) => {
    showRightDrawer({
      content: <OrderSummary orderId={orderId} />,
    });
  };

  const getEventTransactions = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    if (transactionsRequest) {
      transactionsRequest.abort();
    }

    try {
      transactionsRequest = new AbortController();

      const { response } = await callService({
        resource: httpRoutes.admin.getTransactions({
          payload: {
            params: { ...state },
            filters,
          },
          controller: transactionsRequest,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
        setLoading((prevState) => ({ ...prevState, data: false }));
      }
    } catch (e) {
      console.log(e);
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  const resendConfirmationEmail = async (transactionId: number) => {
    await callService({
      resource:
        httpRoutes.admin.transactions.resendOrderConfirmation(transactionId),
      successMessage: 'Order confirmation email sent!',
    });
  };

  const filterModel: FilterType[] = [
    {
      id: 'attendeeEmail',
      type: 'text',
      label: 'Attendee/Sponsor Email',
    },
    {
      id: 'eventName',
      type: 'text',
      label: 'Event Name',
    },
    {
      id: 'status',
      type: 'select',
      label: 'Purchase Status',
      options: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 'authorized',
          label: 'Authorized',
        },
        {
          value: 'purchased',
          label: 'Purchased',
        },
        {
          value: 'refunded',
          label: 'Refunded',
        },
        {
          value: 'chargeBack',
          label: 'Charge Back',
        },
        {
          value: 'cancelled',
          label: 'Cancelled',
        },
      ],
    },
    {
      id: 'paymentMethod',
      type: 'select',
      label: 'Payment Method',
      options: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 'Credit Card',
          label: 'Credit Card',
        },
        {
          value: 'ACH',
          label: 'ACH',
        },
      ],
    },
    {
      id: 'paid',
      type: 'select',
      label: 'Paid',
      options: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 'true',
          label: 'Paid',
        },
        {
          value: 'false',
          label: 'Free',
        },
      ],
    },
  ];

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'ID',
      field: 'id',
      sortable: false,
    },
    {
      headerName: 'Name',
      field: 'customerName',
      sortable: true,
    },
    {
      headerName: 'Email',
      field: 'customerEmail',
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Event',
      field: 'event',
      sortable: true,
    },
    {
      headerName: 'Date',
      field: 'datePurchased',
      sortable: true,
      type: 'dateTime',
    },
    {
      headerName: 'Type',
      field: 'type',
      sortable: true,
      type: 'string',
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Payment Method',
      field: 'paymentMethod',
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Tickets Sold',
      field: 'ticketsSold',
      type: 'number',
    },
    {
      headerName: 'Subtotal',
      field: 'subtotal',
      sortable: true,
      type: 'usdCurrency',
      headerAlign: 'center',
      align: 'right',
    },
    {
      headerName: 'Discount',
      field: 'discount',
      sortable: true,
      type: 'usdCurrency',
      headerAlign: 'center',
      align: 'right',
    },
    {
      headerName: 'Fees',
      field: 'totalFees',
      sortable: true,
      headerAlign: 'center',
      type: 'usdCurrency',
      align: 'right',
    },
    {
      headerName: 'Total',
      field: 'total',
      sortable: true,
      type: 'usdCurrency',
      headerAlign: 'center',
      align: 'right',
    },
    {
      headerName: 'Transactions',
      field: 'mobile',
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <Grid
            container
            height="100%"
            m={0.5}
            p={1}
            justifyContent="space-between"
            flexDirection="row"
            spacing={1}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Name</Typography>
              <Typography fontWeight="bold">
                {params.row.customerName}
              </Typography>
              <Typography>{params.row.customerEmail}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Date Purchased</Typography>
              <Typography>{formatDate(params.row.datePurchased)}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Totals</Typography>
              {Number(params.row.subtotal) > 0 && (
                <Typography>
                  Subtotal: {formatCurrency(params.row.subtotal)}
                </Typography>
              )}
              {Number(params.row.totalFees) > 0 && (
                <Typography>
                  Fees: {formatCurrency(params.row.totalFees)}
                </Typography>
              )}
              {Number(params.row.total) > 0 && (
                <Typography>
                  Total: {formatCurrency(params.row.total)}
                </Typography>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <Tooltip title="Resend Email">
            <IconButton onClick={() => resendConfirmationEmail(params.row.id)}>
              <EmailIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <PageContainer maxWidth="xl">
      <DataGrid
        tableTitle="Event Transactions"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
        onCellClick={(params) => {
          if (params.field === 'id') {
            getOrderDetails(Number(params.row.id));
          }
        }}
      />
    </PageContainer>
  );
};

const Transactions = () => (
  <ControlledDataGrid>
    <TransactionsGrid />
  </ControlledDataGrid>
);

export default Transactions;
