import {
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import FooterBackground from 'assets/images/footer/footer-background.png';
import FacebookIcon from 'assets/images/footer/icon-facebook.png';
import InstagramIcon from 'assets/images/footer/icon-instagram.png';
import LinkedInIcon from 'assets/images/footer/icon-linkedin.png';
import TwitterIcon from 'assets/images/footer/icon-twitter.png';
import YoutubeIcon from 'assets/images/footer/icon-youtube.png';
import TikTokIcon from 'assets/images/footer/icon-tiktok.png';

import EventHiLogoShort from 'assets/images/footer/footer-EventHi-mark.png';

import Copyright from './Copyright';

const resourceLinks = {
  title: 'Resources',
  links: [
    {
      label: 'Contact Us',
      value: 'https://eventhi.zendesk.com/hc/en-us/requests/new',
    },
    { label: 'Legal Policies', value: '/legal' },
    { label: 'Refund Policy', value: '/refund-policy' },
  ],
};

const socialLinks1 = {
  title: 'Find Us On Social',
  links: [
    {
      type: 'twitter',
      value: 'https://twitter.com/eventhi_io',
      icon: TwitterIcon,
    },
    {
      type: 'instagram',
      value: 'https://www.instagram.com/eventhi.io/',
      icon: InstagramIcon,
    },
    {
      type: 'facebook',
      value: 'https://www.facebook.com/eventhi.io/',
      icon: FacebookIcon,
    },
  ],
};

const socialLinks2 = [
  {
    type: 'youtube',
    value: 'https://www.youtube.com/channel/UCmnoecfO_FgiUSV0h88kK4w',
    icon: YoutubeIcon,
  },
  {
    type: 'linkedin',
    value: 'https://www.linkedin.com/company/eventhi',
    icon: LinkedInIcon,
  },
  {
    type: 'tiktok',
    value: 'https://www.tiktok.com/@eventhi_io',
    icon: TikTokIcon,
  },
];

export default function Footer() {
  const theme = useTheme();

  return (
    <Container>
      <Grid
        container
        sx={{
          position: 'absolute',
          left: 0,
          display: 'flex',
          [theme.breakpoints.only('xs')]: {
            height: '90vh',
            width: '100%',
          },
          [theme.breakpoints.up('xs')]: {
            height: '70vh',
            width: '100%',
          },

          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          zIndex: -1,
          backgroundImage: `url(${FooterBackground})`,
        }}
      ></Grid>
      <Container maxWidth="md">
        <Grid container justifyContent="center" pt={5}>
          <img src={EventHiLogoShort} alt="EventHi Logo" />
        </Grid>
        <Grid
          pt={6}
          container
          flexDirection="row"
          justifyContent="center"
          alignContent="center"
          spacing={1}
        >
          {/* Site Resources */}
          <Grid item xs={12} sm={12} md={4} sx={{ mt: 2 }}>
            <Stack>
              <Typography
                fontSize="14px"
                fontWeight="bold"
                color="#ec009c"
                textTransform="uppercase"
                textAlign="center"
                mb={3}
              >
                {resourceLinks.title}
              </Typography>
              {resourceLinks.links.map((item, i) => (
                <Grid key={i} margin="auto" mt={1}>
                  <Link
                    href={item.value}
                    sx={{ textDecoration: 'none', color: 'white' }}
                  >
                    {item.label}
                  </Link>
                </Grid>
              ))}
            </Stack>
          </Grid>
          {/* Social Media Links */}
          <Grid item xs={12} sm={12} md={4} sx={{ mt: 2 }}>
            <Stack>
              <Typography
                fontSize="14px"
                fontWeight="bold"
                color="#ec009c"
                pb={3}
                textTransform="uppercase"
                textAlign="center"
              >
                {socialLinks1.title}
              </Typography>
              <Grid container flexDirection="column">
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {socialLinks1.links.map((item, i) => (
                    <IconButton
                      onClick={() => window.open(item.value, '_blank')}
                      key={i}
                    >
                      <img src={item.icon} alt={item.type}></img>
                    </IconButton>
                  ))}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {socialLinks2.map((item, i) => (
                    <IconButton
                      onClick={() => window.open(item.value, '_blank')}
                      key={i}
                    >
                      <img src={item.icon} alt={item.type}></img>
                    </IconButton>
                  ))}
                </Stack>
              </Grid>
              <Grid margin="auto" mt={3}>
                <Typography sx={{ color: 'white' }}>Contact Info</Typography>
              </Grid>
              <Grid margin="auto" mt={1}>
                <Typography sx={{ color: 'white' }}>
                  4410 Glacier Ave, Suite 106
                </Typography>
              </Grid>
              <Grid margin="auto">
                <Typography sx={{ color: 'white' }}>
                  San Diego, CA 92120, USA
                </Typography>
              </Grid>
              <Grid margin="auto">
                <Typography sx={{ color: 'white' }}>+1 619-773-0771</Typography>
              </Grid>
            </Stack>
          </Grid>
          {/* Newsletter Signup */}
          <Grid item xs={12} sm={12} md={4} sx={{ mt: 2 }}>
            <Stack justifyContent="center">
              <Typography
                fontSize="14px"
                fontWeight="bold"
                color="#ec009c"
                pb={3}
                textTransform="uppercase"
                textAlign="center"
              >
                STAY IN THE KNOW
              </Typography>
              <Typography textAlign="center" color="white" paddingBottom={2}>
                Sign up to receive updates
                <br />
                on events, industry news,
                <br />
                and important updates
              </Typography>
              <Button
                sx={{
                  width: '50%',
                  margin: 'auto',
                  borderRadius: '24px',
                  backgroundColor: 'rgba(255,255,255,.65)',
                  color: 'purple',
                  ':hover': {
                    backgroundColor: 'rgba(255,255,255,1)',
                  },
                }}
                onClick={() =>
                  window.open(
                    'https://eventhi.zendesk.com/hc/en-us/requests/new',
                    '_blank'
                  )
                }
              >
                SIGN UP
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Grid mt={8}>
        <Copyright />
      </Grid>
    </Container>
  );
}
