import { CheckoutItemState, CheckoutFees } from 'states/checkoutState';

export function calculateServiceFee(subtotal: number, fees: CheckoutFees) {
  // if total is zero, return zeroes
  if (subtotal === 0) {
    return 0;
  }

  const _serviceFee = fees.serviceFee;

  // serviceFee
  const serviceFee = subtotal * _serviceFee;

  return bankersRound(serviceFee);
}

export function calculateProcessingFee(
  preProcessingTotal: number,
  processingFee: number
) {
  if (preProcessingTotal === 0) return 0;

  return bankersRound(preProcessingTotal * processingFee);
}

// used to display fees on each ticket type
export function calculateItemFees(item: CheckoutItemState, fees: CheckoutFees) {
  const baseTotal = Number(item.price);

  const quantity = 1;

  const subtotal = baseTotal * quantity;

  const itemFee = fees.itemFee * quantity;

  // Calculate Service Fee
  const serviceFee = calculateServiceFee(subtotal, fees);

  // Calculate processingFee
  // Combine perTicketFee and serviceFee
  const preProcessingTotal = subtotal + itemFee + serviceFee;

  const processingFee = calculateProcessingFee(
    preProcessingTotal,
    fees.processingFee
  );

  return (
    bankersRound(itemFee) +
    bankersRound(serviceFee) +
    bankersRound(processingFee)
  );
}

export async function calculateFees(
  items: CheckoutItemState[],
  fees: CheckoutFees
) {
  // return list of items with subtotal, serviceFees, ccFees
  const totals = {
    subtotal: 0,
    itemFee: 0,
    processingFee: 0,
    serviceFee: 0,
    total: 0,
  };

  for (let i = 0; i < items.length; i += 1) {
    const _item = items[i];

    if (_item.quantity > 0) {
      const subtotal = Number(_item.price) * Number(_item.quantity);

      _item.subtotal = subtotal;
      totals.subtotal += _item.subtotal;

      const itemFee = subtotal > 0 ? _item.quantity * fees.itemFee : 0;

      // calculate service fee
      const serviceFee = calculateServiceFee(subtotal, fees);

      _item.itemFee = itemFee;
      totals.itemFee += itemFee;

      _item.serviceFee = serviceFee;
      totals.serviceFee += serviceFee;

      const preProcessingTotal = subtotal + itemFee + serviceFee;

      const processingFee = calculateProcessingFee(
        preProcessingTotal,
        fees.processingFee
      );

      _item.processingFee = processingFee;
      totals.processingFee += processingFee;

      _item.total =
        _item.itemFee + _item.subtotal + _item.serviceFee + _item.processingFee;
      totals.total += _item.total;
    }
  }

  return {
    items,
    totals,
  };
}

export function bankersRound(num: number, decimalPlaces = 2) {
  const d = decimalPlaces || 0;
  // eslint-disable-next-line no-restricted-properties
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  // eslint-disable-next-line no-nested-ternary
  const r =
    f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);

  return d ? r / m : r;
}
