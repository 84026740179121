import { useState, useEffect } from 'react';
import fileSaver from 'file-saver';

import { Button, Divider, Grid, Typography } from '@mui/material';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import Amex from 'assets/payment-types/amex.png';
import Discover from 'assets/payment-types/discover.png';
import MasterCard from 'assets/payment-types/mastercard.png';
import Visa from 'assets/payment-types/visa.png';

import DisplayText from 'components/DisplayText';
import LoadingButton from 'components/buttons/LoadingButton';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import { useCallService } from 'hooks';

import { DownloadIcon } from 'material-icons';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';
import useCheckoutStore from 'states/checkoutState';

const PurchaseConfirmation = ({
  purchaseConfirmation,
}: {
  purchaseConfirmation: any;
}) => {
  const [paymentType, setPaymentType] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();

  const { state } = useCheckoutStore();

  useEffect(() => {
    if (purchaseConfirmation.paymentMethod) {
      setPaymentType(purchaseConfirmation.paymentMethod);

      const cardUsed = purchaseConfirmation.creditCardType.toLowerCase();

      switch (cardUsed) {
        case 'visa':
          setPaymentType(Visa);
          break;
        case 'discover':
          setPaymentType(Discover);
          break;
        case 'mastercard':
          setPaymentType(MasterCard);
          break;
        case 'american_express':
          setPaymentType(Amex);
          break;
        default:
          break;
      }
    }
  }, []);

  const downloadTickets = async () => {
    setLoading((prevState) => !prevState);

    try {
      const { response, error } = await callService({
        resource: httpRoutes.user.downloadTickets(purchaseConfirmation.id),
      });

      if (response) {
        // update file name
        const _fileName = `${state.eventName}-Tickets.pdf`;

        const _fileData = new Blob([response], { type: 'application/pdf' });

        fileSaver.saveAs(_fileData, _fileName);
      }

      if (error) {
        console.log(error);
      }
    } finally {
      setLoading((prevState) => !prevState);
    }
  };

  return (
    <Grid
      container
      maxWidth="sm"
      sx={{
        height: 'inherit',
        margin: '0 auto',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: '#56C596',
          color: 'white',
          height: 'inherit',
          borderRadius: 8,
        }}
      >
        <Grid item pt={1}>
          <Typography fontSize={24} textAlign="center">
            {state.eventName}
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between" mt={1} p={2}>
          <Grid item xs={5}>
            <Typography fontSize={16}>
              Order # {purchaseConfirmation.id}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Grid
              container
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item pr={1}>
                <CheckCircleOutlineOutlinedIcon
                  sx={{ fontSize: '24px', color: 'white' }}
                />
              </Grid>
              <Grid item>
                <Typography fontSize={16} fontWeight="bold" mb={0.5}>
                  Purchase Complete!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" spacing={1} mr={1} mt={2}>
        <LoadingButton
          variant="outlined"
          icon={<DownloadIcon />}
          onClick={downloadTickets}
          loading={loading}
        >
          Download Passes
        </LoadingButton>
      </Grid>

      {/* Cart Summary */}
      <Grid item p={4} xs={12} sx={{ minHeight: '40vh' }}>
        <Typography mb={2} fontWeight="semibold">
          Items
        </Typography>
        <Divider />
        {/* Cart Items */}
        <Grid item sx={{ width: 'inherit' }}>
          {state.items.length > 0 &&
            state.items.map((item: any) => {
              if (item.quantity > 0) {
                return (
                  <Grid
                    key={item.id}
                    container
                    justifyContent="space-between"
                    flexDirection="row"
                    sx={{
                      paddingBottom: '8px',
                    }}
                  >
                    <Grid item xs={9}>
                      <DisplayText type="bodySmall" text={`${item.name}`} />
                      <DisplayText
                        style={{ marginLeft: 1 }}
                        type="bodyXSmall"
                        text={`${item.quantity} Items`}
                      />
                      {item.discountAmount > 0 && item.discountCode && (
                        <DisplayText
                          style={{
                            marginLeft: 2,
                            marginTop: 2,
                            color: '#2196f3',
                          }}
                          type="bodyXSmall"
                          text={`${item.discountCode} (${formatCurrency(
                            item.discountAmount
                          )}) applied`}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography textAlign="right" alignSelf="center">
                        {formatCurrency(item.subtotal + item.discountAmount)}
                      </Typography>
                      {item.discountAmount > 0 && (
                        <Typography
                          textAlign="right"
                          alignSelf="center"
                          sx={{ color: '#2196f3' }}
                        >
                          - {formatCurrency(item.discountAmount)}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                );
              }
            })}
        </Grid>
        {/* Cart Totals */}
        <Divider />
        <Grid
          mt={2}
          container
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography>Subtotal</Typography>
          <Typography>
            {formatCurrency(state.subtotal + state.discount)}
          </Typography>
        </Grid>
        {state.discount > 0 && (
          <Grid
            mt={1}
            mb={1}
            container
            justifyContent="space-between"
            flexDirection="row"
          >
            <Typography>Discounts</Typography>
            <Typography>- {formatCurrency(state.discount)}</Typography>
          </Grid>
        )}

        <Grid
          mt={2}
          container
          justifyContent="space-between"
          flexDirection="row"
          columnSpacing={2}
        >
          <Grid item>
            <Typography>Fees</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {formatCurrency(
                Number(purchaseConfirmation.itemFee) +
                  Number(purchaseConfirmation.processingFee) +
                  Number(purchaseConfirmation.serviceFee)
              )}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          mt={2}
          container
          justifyContent={state.total > 0 ? 'space-between' : 'flex-end'}
          flexDirection="row"
        >
          {state.total > 0 && (
            <Grid item xs={6}>
              <Grid container alignItems="center" spacing={1}>
                {purchaseConfirmation.paymentMethod === 'ACH' && (
                  <Grid item>
                    <Typography>ACH</Typography>
                  </Grid>
                )}
                {purchaseConfirmation.paymentMethod === 'Credit Card' && (
                  <Grid item>
                    <img
                      src={paymentType}
                      style={{
                        objectFit: 'contain',
                        height: '30px',
                        maxWidth: '70px',
                        margin: '9 auto',
                      }}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Typography>
                    {purchaseConfirmation.lastFour.length > 2
                      ? ` x ${purchaseConfirmation.lastFour}`
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            xs={6}
            sx={{
              height: 'inherit',
              mt: 0.5,
              margin: 'auto 0',
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              flexDirection="row"
              columnSpacing={2}
              alignItems="center"
            >
              <Grid item>
                <Typography fontSize="16px">Total Paid</Typography>
              </Grid>
              <Grid item>
                <Typography>{formatCurrency(state.total)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ paddingLeft: 4, paddingRight: 4 }}>
        <Typography m={4} textAlign="center">
          Your order was successfully completed. An order confirmation was sent
          to your email.
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button variant="contained" onClick={hideDialog}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default PurchaseConfirmation;
