import { useEffect } from 'react';

import { FilterType } from '../filterTypes';

import SelectFilter from './SelectFilter';
import SwitchFilter from './SwitchFilter';
import TextFilter from './TextFilter';
import DateFilter from './DateFilter';

const Filter = ({
  filter,
  filterValue,
  setFilterValue,
}: {
  filter: FilterType;
  filterValue: any;
  setFilterValue: (id: string, value: any) => void;
}) => {
  useEffect(() => {
    setFilterValue(filter.id, filter.initialValue);
  }, []);

  const handleChange = (value: any) => {
    setFilterValue(filter.id, value);
  };

  return (
    <>
      {filter.type === 'select' && (
        <SelectFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
          options={filter.options || []}
        />
      )}
      {filter.type === 'text' && (
        <TextFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
        />
      )}
      {filter.type === 'switch' && (
        <SwitchFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
        />
      )}
      {filter.type === 'date' && (
        <DateFilter
          id={filter.id}
          label={filter.label}
          onChange={handleChange}
          value={filterValue}
        />
      )}
    </>
  );
};

export default Filter;
