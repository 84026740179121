import { ReactNode, createContext } from 'react';

export type DialogProviderState = {
  title?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  open?: boolean;
  content?: ReactNode;
  fullScreen?: boolean;
  disableEscapeKeyDown?: boolean;
  enableBackdropClose?: boolean;
  allowClose?: boolean;
  onClose?: void;
};

export type DialogContextProps = {
  dialogState: DialogProviderState;
  showDialog: (params: DialogProviderState) => void;
  hideDialog: () => void;
};

export const DIALOG_INITIAL_STATE: DialogProviderState = {
  open: false,
  title: '',
  size: 'md',
  content: null,
  fullScreen: false,
  disableEscapeKeyDown: false,
  enableBackdropClose: true,
  allowClose: true,
};

export const DIALOG_CONTEXT_INITIAL_STATE: DialogContextProps = {
  dialogState: DIALOG_INITIAL_STATE,
  showDialog: () => null,
  hideDialog: () => null,
};

const DialogContext = createContext<DialogContextProps>(
  DIALOG_CONTEXT_INITIAL_STATE
);

export default DialogContext;
