import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, Typography, Stack } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import { SaveIcon } from 'material-icons';
import AttractionsIcon from '@mui/icons-material/Attractions';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';

// third-party
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import DropzoneImage from 'components/dropzone/DropzoneImage';
import RichTextEditor from 'components/RichText';
import FormSection from 'components/form/FormSection';
import { TextFormField } from 'components/formFields';

import fileManagement from 'utils/s3';
import regex from 'utils/regex';

import { REACT_APP_AWS_BUCKET, REACT_APP_CLOUDFRONT_URL } from 'config/config';

export interface IEventDetailFormInputs {
  id: number;
  imageUrl?: string;
  description: string;
  twitterLink?: string;
  instagramLink?: string;
  facebookLink?: string;
  websiteLink?: string;
  videoUrl?: string;
}

const validationSchema = yup.object({
  id: yup.number(),
  description: yup.string(),
  imageUrl: yup.string().nullable(),
  twitterLink: yup
    .string()
    .matches(
      regex.urlValidatorRegex,
      'Url must be in format twitter.com/eventhi_io'
    )
    .nullable(),
  facebookLink: yup
    .string()
    .matches(
      regex.urlValidatorRegex,
      'Url must be in format facebook.com/eventhi_io'
    )
    .nullable(),
  instagramLink: yup
    .string()
    .default(null)
    .matches(
      regex.urlValidatorRegex,
      'Url must be in format instagram.com/eventhi_io'
    )
    .nullable(),
  websiteLink: yup
    .string()
    .matches(
      regex.urlValidatorRegex,
      'Url must be in format website.com/eventhi_io'
    )
    .nullable(),
  videoUrl: yup
    .string()
    .matches(
      regex.urlValidatorRegex,
      'Url must be in format website.com/eventhi_io'
    )
    .nullable(),
});

interface EventDetailFormProps {
  eventId: number;
  onSubmit: (values: IEventDetailFormInputs, tickets?: boolean) => void;
  defaultValues?: IEventDetailFormInputs;
}

export const INITIAL_EVENT_DETAIL_FORM_STATE: IEventDetailFormInputs = {
  id: 0,
  imageUrl: '',
  description: '',
  twitterLink: '',
  instagramLink: '',
  facebookLink: '',
  websiteLink: '',
  videoUrl: '',
};

const EventDetailsForm = ({
  eventId,
  onSubmit,
  defaultValues,
}: EventDetailFormProps) => {
  const [files, setFiles] = useState<File[]>([]);

  const navigate = useNavigate();

  const _fileUrl = defaultValues?.imageUrl
    ? `${REACT_APP_CLOUDFRONT_URL}/${defaultValues?.imageUrl}`
    : '';

  const preSubmitUpload = async (
    values: IEventDetailFormInputs,
    tickets?: boolean
  ) => {
    // upload image to S3
    if (files.length > 0) {
      const headerPath = encodeURIComponent(values.id) + '/images/';
      const fileKey = new Date().getTime();
      // const _fileTypeIndex = files[0].name.lastIndexOf('.');
      // const _fileType = files[0].name.slice(_fileTypeIndex);

      const response = await fileManagement.putItemWithPresignedUrl({
        key: headerPath + fileKey,
        body: files[0],
      });

      if (response) {
        values.imageUrl = headerPath + fileKey;
      }
    }

    onSubmit(values, tickets);
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IEventDetailFormInputs>({
    mode: 'onBlur',
    defaultValues: defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <Grid p={1} pb={4}>
      <form onSubmit={handleSubmit((values) => preSubmitUpload(values))}>
        {/* Details */}
        <FormSection
          title="Details"
          subtitle="Here is where bragging is encouraged."
          icon={
            <AttractionsIcon
              sx={{
                fontSize: {
                  xs: '36px',
                  md: '64px',
                },
                color: 'gray',
              }}
            />
          }
        >
          <Typography variant="h4" mt={2}>
            Header Image
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            This will be your main image.
          </Typography>
          <Stack alignItems="center">
            <DropzoneImage
              maxFiles={1}
              maxSize={2000000}
              defaultValue={_fileUrl}
              handleAcceptedFiles={(acceptedFiles: File[]) => {
                setFiles(acceptedFiles);
              }}
            />
            <Typography variant="caption">
              Pro Tip: Your image should fill this box completely. We recommend
              2160x1080 (2:1 ratio).
            </Typography>
          </Stack>
          <Typography variant="h4" sx={{ mt: 3 }}>
            Description
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Create a vibrant description of your event. You can also drag and
            drop images here!
          </Typography>
          {RichTextEditor && (
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <RichTextEditor
                  eventId={eventId}
                  onChange={field.onChange}
                  value={field.value}
                  readOnly={false}
                />
              )}
            />
          )}
          {!RichTextEditor && <div>Loading...</div>}
        </FormSection>
        {/* Social/Promo */}
        <FormSection
          title="Social/Promo"
          subtitle="Add your social media, website, and video links to improve engagement."
          icon={
            <ConnectWithoutContactIcon
              sx={{
                fontSize: '64px',
                color: 'gray',
              }}
            />
          }
        >
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={12}>
              <TextFormField
                name="twitterLink"
                label="Twitter Link"
                control={control}
                icon={<TwitterIcon />}
                position="start"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFormField
                name="websiteLink"
                label="Website"
                control={control}
                icon={<LanguageIcon />}
                position="start"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFormField
                name="instagramLink"
                label="Instagram"
                control={control}
                icon={<InstagramIcon />}
                position="start"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFormField
                name="facebookLink"
                label="Facebook"
                control={control}
                icon={<FacebookIcon />}
                position="start"
              />
            </Grid>
          </Grid>
        </FormSection>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              color="primary"
              variant="text"
              sx={{ marginLeft: 2 }}
              onClick={() =>
                navigate(`/organizer/events/${eventId}/general-info`)
              }
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isSubmitting}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              type="submit"
              sx={{ mr: 2 }}
            >
              Save
            </LoadingButton>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              onClick={handleSubmit((data) => preSubmitUpload(data, true))}
            >
              Save & Go To Tickets
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EventDetailsForm;
