import { useEffect } from 'react';

import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  DateFormField,
  RadioFormField,
  TextFormField,
  TimeFormField,
} from 'components/formFields';

import {
  DiscountCodeProps,
  INITIAL_DISCOUNT_CODE_STATE,
} from 'types/DiscountCode';
import { AddIcon, DeleteIcon } from 'material-icons';
import { formatCurrency } from 'utils/helpers';
import { IEvent } from 'layout/OrganizerEventLayout';

export interface ISponsorshipTypeFormInputs {
  eventId: number;
  sponsorshipId: string;
  name: string;
  description: string;
  saleStartDate: Date;
  saleEndDate: Date;
  saleStartTime: Date;
  saleEndTime: Date;
  type: string;
  feesType: 'absorb' | 'pass';
  price: string;
  quantity: number;
  maxPerOrder: number;
  showQuantity: boolean;
  id?: number;
  discountCodes: DiscountCodeProps[];
}

type SponsorshipTypeFormProps = {
  eventId: number;
  eventDetails: IEvent;
  sponsorshipId: string;
  onSubmit: (values: ISponsorshipTypeFormInputs) => void;
  hideDialog: () => void;
  sponsorshipType?: ISponsorshipTypeFormInputs;
};

function SponsorshipTypeForm({
  eventId,
  eventDetails,
  sponsorshipId,
  sponsorshipType,
  onSubmit,
  hideDialog,
}: SponsorshipTypeFormProps) {
  const edit = sponsorshipType && sponsorshipType.id;

  const _endDate = sponsorshipType?.saleEndDate || eventDetails?.endsAt;

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(2, 'Sponsorship names must be at least 2 characters')
      .required('A sponsorship name is required'),
    description: yup.string().nullable(),
    saleStartDate: yup
      .date()
      .nullable()
      .required('Sales start date is required'),
    saleEndDate: yup
      .date()
      .nullable()
      .test(
        'test-saleEndDate',
        'End date must be after start date',
        function checkEndDate() {
          const { saleStartDate, saleEndDate } = this.parent;

          return Date.parse(saleStartDate) <= Date.parse(saleEndDate);
        }
      ),
    saleStartTime: yup.date(),
    saleEndTime: yup.date(),
    feesType: yup.string().oneOf(['absorb', 'pass']),
    price: yup.string(),
    quantity: yup
      .number()
      .integer()
      .min(1, 'Must have at least one sponsorship available')
      .required('A total quantity is required'),
    maxPerOrder: yup.number().integer().nullable(),
    showQuantity: yup.boolean(),
    discountCodes: yup.array().of(
      yup.object().shape({
        discountCode: yup.string().required('A code is required'),
        discountAmount: yup.string().required('A discount amount is required'),
      })
    ),
  });

  const {
    clearErrors,
    setError,
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<ISponsorshipTypeFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      id: edit ? sponsorshipType.id : undefined,
      eventId: edit ? sponsorshipType.eventId : eventId,
      sponsorshipId: edit ? sponsorshipType.sponsorshipId : sponsorshipId,
      name: edit ? sponsorshipType.name : '',
      description: edit ? sponsorshipType.description : '',
      saleStartDate: edit ? sponsorshipType.saleStartDate : new Date(),
      saleEndDate: _endDate,
      saleStartTime: edit ? sponsorshipType.saleStartTime : new Date(),
      saleEndTime: _endDate,
      feesType: edit ? sponsorshipType.feesType : 'pass',
      price: edit ? sponsorshipType.price : '',
      quantity: edit ? sponsorshipType.quantity : 100,
      maxPerOrder: edit ? sponsorshipType.maxPerOrder : 10,
      showQuantity: edit ? sponsorshipType.showQuantity : true,
      discountCodes: edit ? sponsorshipType.discountCodes : [],
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'discountCodes',
  });

  const watchStartDate = watch('saleStartDate');

  useEffect(() => {
    if (getValues('saleEndDate') < getValues('saleStartDate')) {
      setValue('saleEndDate', getValues('saleStartDate'));
    }
  }, [watchStartDate]);

  return (
    <Grid container sx={{ p: 4 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} mt={2}>
            <TextFormField name="name" control={control} label="Name" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onClick={() => setValue('price', '')}
                  variant="outlined"
                  margin="normal"
                  label="Price"
                  onBlur={(e: any) => {
                    const _formatted = formatCurrency(e.target.value);
                    if (Number(e.target.value)) {
                      setValue('price', _formatted);
                      clearErrors('price');
                    } else {
                      setError(
                        'price',
                        { message: 'Please enter a valid price' },
                        { shouldFocus: true }
                      );
                    }
                  }}
                  fullWidth
                  required
                  placeholder="$10.00"
                  helperText={
                    errors.price ? (
                      <Typography color="error">
                        {errors.price.message}
                      </Typography>
                    ) : (
                      ' '
                    )
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFormField
              name="quantity"
              control={control}
              label="Total Quantity"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFormField
              name="maxPerOrder"
              label="Purchase Limit"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={7}>
            <Typography variant="body1" mb={1.5}>
              Sponsorship Sales
            </Typography>
            <Grid container spacing={1.5}>
              <Grid item>
                <DateFormField
                  name="saleStartDate"
                  control={control}
                  label="Start Date"
                />
              </Grid>
              <Grid item>
                <TimeFormField
                  name="saleStartTime"
                  control={control}
                  label="Start Time"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1.5}>
              <Grid item>
                <DateFormField
                  name="saleEndDate"
                  control={control}
                  label="End Date"
                />
              </Grid>
              <Grid item>
                <TimeFormField
                  name="saleEndTime"
                  control={control}
                  label="End Time"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            <Grid container>
              <RadioFormField
                name="feesType"
                control={control}
                label="Pass processing fees or absorb them?"
                options={[
                  { label: 'Pass fees to sponsors', value: 'pass' },
                  { label: 'Absorb fees', value: 'absorb' },
                ]}
              />
              <RadioFormField
                name="showQuantity"
                control={control}
                label="Show remaining quantity on checkout?"
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </Grid>
          </Grid>

          <Divider />
          <Grid item xs={12}>
            <TextFormField
              name="description"
              label="Description"
              control={control}
              multiline
              minRows={5}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Typography variant="body1" mb={1} mt={1}>
                Discount Codes (optional)
              </Typography>
              <Grid item xs={12}>
                <Button
                  onClick={() => append(INITIAL_DISCOUNT_CODE_STATE)}
                  startIcon={<AddIcon />}
                >
                  Add Discount
                </Button>
              </Grid>
              {fields.map((_field, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    flexDirection="row"
                    justifyContent="space-evenly"
                  >
                    <Grid item>
                      <TextFormField
                        control={control}
                        name={`discountCodes.${index}.discountCode`}
                        label="Discount Code"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name={`discountCodes.${index}.discountAmount`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label="Amount"
                            margin="normal"
                            fullWidth
                            onBlur={(e: any) => {
                              const _value = Number(
                                e.target.value.replaceAll('$', '')
                              );
                              const _formatted = formatCurrency(_value);
                              const _price = Number(
                                getValues('price').replaceAll('$', '')
                              );

                              if (_value > _price) {
                                setError(
                                  `discountCodes.${index}.discountAmount`,
                                  { message: 'Please lower amount' },
                                  { shouldFocus: true }
                                );
                              } else if (Number(_value)) {
                                setValue(
                                  `discountCodes.${index}.discountAmount`,
                                  _formatted
                                );
                                clearErrors(
                                  `discountCodes.${index}.discountAmount`
                                );
                              } else {
                                setError(
                                  `discountCodes.${index}.discountAmount`,
                                  { message: 'Please enter a valid amount' },
                                  { shouldFocus: true }
                                );
                              }
                            }}
                            helperText={
                              errors.discountCodes &&
                              errors.discountCodes[index] &&
                              errors.discountCodes[index]?.discountAmount &&
                              errors.discountCodes[index]?.discountAmount
                                ?.message ? (
                                <Typography variant="caption" color="error">
                                  {errors.discountCodes[
                                    index
                                  ]?.discountAmount?.message?.toString()}
                                </Typography>
                              ) : (
                                ' '
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item mt={3}>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Button variant="text" onClick={() => hideDialog()}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default SponsorshipTypeForm;
