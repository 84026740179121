import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

export interface SelectOptionProps {
  value: string | number;
  label: string | number;
  abbr?: string;
}

interface SelectFieldProps {
  label: string;
  options: SelectOptionProps[];
  margin?: 'normal' | 'dense' | 'none';
  disabled?: boolean;
  multiple?: boolean;
  required?: boolean;
  variant?: 'filled' | 'standard' | 'outlined';
  id?: string;
}

export type SelectFormFieldProps<T extends FieldValues> = SelectFieldProps &
  UseControllerProps<T>;

const SelectFormField = <T extends FieldValues>(
  props: SelectFormFieldProps<T>
) => {
  const {
    label,
    name,
    control,
    disabled,
    margin = 'normal',
    multiple,
    required,
    options,
    variant,
    id,
  } = props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl sx={{ minWidth: '100%' }} margin={margin}>
          <InputLabel id={id} required={required}>
            {label}
          </InputLabel>
          <Select
            {...field}
            label={label}
            labelId={`select-list-${name}`}
            error={Boolean(errors[name])}
            disabled={disabled}
            multiple={multiple}
            variant={variant}
          >
            {options.map((o) => (
              <MenuItem key={o.label} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ color: 'red' }}>
            {errors[name] ? (
              <span>{errors[name]?.message?.toString()}</span>
            ) : (
              ' '
            )}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SelectFormField;
