import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Lottie from 'lottie-react';

import { Button, Grid, Typography } from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import bookingTickets from 'assets/animations/booking-tickets.json';

import CreditCardsAccepted from 'assets/payment-types/credit-cards-accepted.png';

import PurchaseConfirmation from 'features/upcomingEvents/modals/PurchaseConfirmation';

import { useCallService } from 'hooks';

import useCheckoutStore from 'states/checkoutState';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';
import { months, years } from 'utils/helpers';

import statelist from 'data/states';

import { SelectFormField, TextFormField } from 'components/formFields';
import { LoadingButton } from '@mui/lab';
import useAuthState from 'providers/AuthProvider/hooks/useAuthState';

export type CreditCardFormProps = {
  firstName: string;
  lastName: string;
  email: string;
  number: string;
  cvv: string;
  month: string;
  year: string;
  address1: string;
  city: string;
  state: string;
  zip: string;
  country?: string;
};

export type CCPurchaseRequest = {
  creditCard: CreditCardFormProps;
  amount: number;
  currencyCode: 'AUD' | 'USD' | 'EUR';
};

type SelectListProps = {
  value: string | number;
  label: string | number;
  abbr?: string;
};

export interface CreditCardInfo {
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
}

export interface CreditCardAddress {
  postalCode?: string;
}

export interface CreditCardAuthorization {
  billing: CreditCardAddress;
  card: CreditCardInfo;
  status: string;
  token: string;
}

const Payment = ({
  onSuccess,
  type,
  setSubmitting,
}: {
  onSuccess: () => void;
  type: 'sponsorship' | 'ticket';
  setSubmitting: (value: boolean) => void;
}) => {
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();

  const checkoutStore = useCheckoutStore();

  const {
    authState: { user },
  } = useAuthState();

  const [yearList] = useState<SelectListProps[]>(years());
  const minYear = new Date().getFullYear().toString().slice(2);
  const minMonth = new Date().getMonth() + 1;
  const [isSubmittingFree, setIsSubmittingFree] = useState(false);

  const clearCart = () => {
    checkoutStore.clearCart();
  };

  const onSubmit = async (values: CreditCardFormProps) => {
    setSubmitting(true);

    const _tickets = checkoutStore.state.items.filter((item) => {
      if (item.quantity > 0) {
        return item;
      }
    });

    const creditCard: CreditCardFormProps = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: user.email,
      number: values.number,
      cvv: values.cvv,
      month: values.month,
      year: '20' + values.year.toString(),
      address1: values.address1,
      city: values.city,
      state: values.state,
      zip: values.zip,
      country: 'United States',
    };

    const ccPurchaseRequest: CCPurchaseRequest = {
      creditCard,
      amount: checkoutStore.state.total,
      currencyCode: 'USD',
    };

    const requestBody = {
      ccPurchaseRequest,
      eventId: checkoutStore.state.eventId,
      tickets: _tickets,
      subtotal: checkoutStore.state.subtotal,
      discount: checkoutStore.state.discount,
      itemFee: Number(checkoutStore.state.itemFee.toFixed(2)),
      serviceFee: checkoutStore.state.serviceFee,
      processingFee: checkoutStore.state.processingFee,
      total: checkoutStore.state.total,
    };

    if (type === 'ticket') {
      const { response, error } = await callService({
        resource: httpRoutes.events.purchaseTickets(requestBody),
        successMessage:
          'Tickets purchased! An order confirmation has been sent to your email.',
      });

      if (response) {
        setSubmitting(false);

        showDialog({
          size: 'sm',
          content: <PurchaseConfirmation purchaseConfirmation={response} />,
          onClose: clearCart(),
        });

        onSuccess();
      }

      if (error) {
        setSubmitting(false);
      }
    }

    if (type === 'sponsorship') {
      const { response, error } = await callService({
        resource: httpRoutes.events.purchaseSponsorships(requestBody),
        successMessage:
          'Sponsorhips purchased! An order confirmation has been sent to your email.',
      });

      if (response) {
        setSubmitting(false);

        showDialog({
          size: 'sm',
          content: <PurchaseConfirmation purchaseConfirmation={response} />,
        });

        onSuccess();
      }

      if (error) {
        setSubmitting(false);
      }
    }
  };

  const checkoutFreeItems = async () => {
    setIsSubmittingFree(true);

    const _tickets = checkoutStore.state.items.filter((item) => {
      if (item.quantity > 0) {
        return item;
      }
    });

    const requestBody = {
      eventId: checkoutStore.state.eventId,
      tickets: _tickets,
      subtotal: checkoutStore.state.subtotal,
      discount: checkoutStore.state.discount,
      itemFee: Number(checkoutStore.state.itemFee.toFixed(2)),
      serviceFee: checkoutStore.state.serviceFee,
      processingFee: checkoutStore.state.processingFee,
      total: checkoutStore.state.total,
    };

    if (type === 'ticket') {
      const { response, error } = await callService({
        resource: httpRoutes.events.purchaseTickets(requestBody),
        successMessage:
          'Tickets purchased! An order confirmation has been sent to your email.',
      });

      if (response) {
        setIsSubmittingFree(false);

        showDialog({
          size: 'sm',
          content: <PurchaseConfirmation purchaseConfirmation={response} />,
          onClose: clearCart(),
        });

        onSuccess();
      }

      if (error) {
        setIsSubmittingFree(false);
      }
    }

    if (type === 'sponsorship') {
      const { response, error } = await callService({
        resource: httpRoutes.events.purchaseSponsorships(requestBody),
        successMessage:
          'Sponsorhips purchased! An order confirmation has been sent to your email.',
      });

      if (response) {
        setIsSubmittingFree(false);

        showDialog({
          size: 'sm',
          content: <PurchaseConfirmation purchaseConfirmation={response} />,
        });

        onSuccess();
      }

      if (error) {
        setIsSubmittingFree(false);
      }
    }
  };

  // Form Setup
  const validationSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    number: yup.string().required('Card number is required'),
    month: yup
      .number()
      .required('')
      .min(1)
      .max(12)
      .typeError('Please select a month'),
    year: yup
      .number()
      .required('')
      .min(Number(minYear), '')
      .max(99, '')
      .typeError('Please select a year'),
    cvv: yup.string().required('CVV is required'),
    address1: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zip: yup.string().required('Zip code is required'),
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting },
  } = useForm<CreditCardFormProps>({
    mode: 'onBlur',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      number: '',
      month: '',
      year: '',
      cvv: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const watchMonth = watch('month');
  const watchYear = watch('year');

  useEffect(() => {
    const validateExpiration = () => {
      if (
        Number(watchYear) === Number(minYear) &&
        Number(watchMonth) < Number(minMonth)
      ) {
        setError('month', { type: 'validate', message: '' });
      }
    };

    validateExpiration();
  }, [minMonth, minYear, setError, watchMonth, watchYear]);

  return (
    <Grid container justifyContent="center" pt={2}>
      {isSubmitting && (
        <Grid container justifyContent="center" sx={{ height: '25vh' }}>
          <Grid item xs={12} p={2}>
            <Lottie
              style={{ height: '25vh' }}
              animationData={bookingTickets}
              loop={true}
            />
          </Grid>
          <Typography variant="body1" align="center">
            Completing your order...
          </Typography>
        </Grid>
      )}
      {!isSubmitting && checkoutStore.state.total > 0 && (
        <Grid container pb={2} justifyContent="center">
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="h3" align="center">
              Payment Info
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="body1" align="center">
              Please enter your credit card information below
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} id="payment-form">
              <Grid container pt={2} spacing={1}>
                <Typography>Payment Info</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextFormField
                      id="first_name"
                      name="firstName"
                      control={control}
                      label="First Name"
                      required
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFormField
                      id="last_name"
                      name="lastName"
                      control={control}
                      label="Last Name"
                      required
                      margin="none"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextFormField
                    id="number"
                    name="number"
                    control={control}
                    label="Card Number"
                    icon={<CreditCardOutlinedIcon />}
                    position="end"
                    required
                    margin="none"
                  />
                </Grid>
                <Grid item xs={4} md={4} pr={1}>
                  <SelectFormField
                    id="month"
                    name="month"
                    label="Month"
                    control={control}
                    options={months}
                    margin="none"
                  />
                </Grid>
                <Grid item xs={4} md={4} pr={1}>
                  <SelectFormField
                    id="year"
                    name="year"
                    label="Year"
                    control={control}
                    options={yearList}
                    margin="none"
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <TextFormField
                    name="cvv"
                    label="CVV"
                    control={control}
                    required
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFormField
                    name="address1"
                    control={control}
                    label="Address"
                    required
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFormField
                    name="city"
                    control={control}
                    label="City"
                    required
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <SelectFormField
                    name="state"
                    control={control}
                    label="State"
                    options={statelist}
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFormField
                    name="zip"
                    control={control}
                    label="Postal Code"
                    required
                    margin="none"
                  />
                </Grid>
              </Grid>
              <Grid container m={1}>
                <Grid item xs={12}>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isSubmitting}
                  >
                    Purchase Tickets
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <img
                  src={CreditCardsAccepted}
                  style={{
                    height: '30px',
                    margin: 'auto',
                    alignSelf: 'center',
                  }}
                />
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
      {!isSubmitting && checkoutStore.state.total === 0 && (
        <Grid container justifyContent="center" sx={{ height: '25vh' }} p={2}>
          <Typography variant="h4">
            Please confirm your items before continuing
          </Typography>
          <Typography variant="body1" align="center">
            Since your order only consists of free items, please confirm your
            items before checking out.
          </Typography>
          <Grid container m={1}>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                fullWidth
                onClick={checkoutFreeItems}
                loading={isSubmittingFree}
              >
                Purchase Tickets
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Payment;
