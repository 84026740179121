import { ReactNode, useEffect, useState } from 'react';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Event, ExpandLess } from '@mui/icons-material';
import {
  Collapse,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  AttendeeIcon,
  DashboardIcon,
  EventDetailIcon,
  EventGeneralIcon,
  EventTicketIcon,
  ExpandMore,
  MenuIcon,
  SponsorIcon,
  SponsorshipIcon,
  TicketStubIcon,
  TicketTypeIcon,
  TransactionIcon,
  UserIcon,
} from 'material-icons';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ml: 1,
  height: '60px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    marginRight: 1,
  }),
}));

interface LinkProps extends ListItemProps {
  label: string;
  link: string;
  icon: ReactNode;
}

const AdminLinks = () => [
  {
    label: 'Events',
    link: '/admin/events',
    icon: <EventGeneralIcon />,
  },
  {
    label: 'Users',
    link: '/admin/users',
    icon: <AttendeeIcon />,
  },
  {
    label: 'Transactions',
    link: '/admin/transactions',
    icon: <TransactionIcon />,
  },
  {
    label: 'Ticket Types',
    link: '/admin/ticket-types',
    icon: <TicketStubIcon />,
  },
];

export default function MainDrawer() {
  const theme = useTheme();
  const router = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [open, setOpen] = useState(true);

  const [eventManagementOpen, setEventManagementOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
  }, [matchDownLG]);

  const paths = location.pathname.split('/');

  const selectedPath = paths[paths.length - 1];

  const selectedLink = selectedPath[1];

  const selectedSection = selectedPath[1];

  const LinkItem = ({
    item,
    child = false,
  }: {
    item: LinkProps;
    child?: boolean;
  }) => {
    const _isSelected = false;

    return (
      <Tooltip title={item.label} placement="right" arrow>
        <ListItem
          disablePadding
          sx={{
            ml: child && open ? 3 : 'auto',
            display: 'block',
            color: _isSelected ? theme.palette.primary.main : 'default',
            fontWeight: _isSelected ? 'bold' : 'default',
          }}
          onClick={() => router(item.link)}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: _isSelected ? theme.palette.primary.main : 'default',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Tooltip>
    );
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        pt: 8,
        pr: 0.5,
      }}
    >
      <Toolbar />
      <DrawerHeader sx={{ mt: 4 }}>
        <IconButton
          onClick={handleDrawerClose}
          sx={{ display: open ? 'block' : 'none' }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={handleDrawerOpen}
          sx={{ display: open ? 'none' : 'block' }}
        >
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List disablePadding>
        {AdminLinks().map((item) => (
          <LinkItem key={item.label} item={item} />
        ))}
      </List>
      <Divider />
    </Drawer>
  );
}
