import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-license-pro';
import { HelmetProvider } from 'react-helmet-async';

import themes from 'themes';

import createEmotionCache from 'utils/createEmotionCache';

import AppProvider from 'providers/AppProvider';
import AuthProvider from 'providers/AuthProvider/AuthProvider';
import DialogProvider from 'providers/DialogProvider/DialogProvider';
import RightDrawerProvider from 'providers/RightDrawerProvider/RightDrawerProvider';

import Routes from 'routes';

// SET MUI Pro license key
LicenseInfo.setLicenseKey(
  '13f3d1201e1d16fcd45634d13ee2c35bTz00NTUwNyxFPTE2ODY1MjI0NDEzODIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function App() {
  return (
    <HelmetProvider>
      <AppProvider>
        <CacheProvider value={clientSideEmotionCache}>
          <ThemeProvider theme={themes()}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <AuthProvider>
                <DialogProvider>
                  <RightDrawerProvider>
                    <Routes />
                  </RightDrawerProvider>
                </DialogProvider>
              </AuthProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </CacheProvider>
      </AppProvider>
    </HelmetProvider>
  );
}

export default App;
