/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDropzone, DropzoneOptions, Accept } from 'react-dropzone';
import { Grid, Typography } from '@mui/material';

import { ImagePlaceholderIcon } from 'material-icons';

interface DropzoneProps {
  handleAcceptedFiles: (values: File[]) => void;
  defaultValue?: string;
  helperText?: string;
  maxFiles?: number;
  maxSize?: number;
}

interface ImageWithPreview extends File {
  preview: string;
}

const INITIAL_STATE: ImageWithPreview[] = [];

const ImagePreview = ({ source, alt }: { source: string; alt: string }) => {
  return (
    <Grid container justifyContent="center">
      <img
        src={source}
        alt={alt}
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          height: '400px',
          aspectRatio: '2/1',
          objectFit: 'contain',
        }}
      />
    </Grid>
  );
};

const Dropzone = ({
  handleAcceptedFiles,
  defaultValue,
  helperText,
  maxFiles = 1,
  maxSize = 2000000,
}: DropzoneProps) => {
  const [files, setFiles] = useState(INITIAL_STATE);
  const [errorMessage, setErrorMessage] = useState<string>();

  const accept: Accept = {
    'image/*': ['.png', '.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
  };

  const options: DropzoneOptions = {
    accept: accept,
    maxSize: maxSize,
    maxFiles: maxFiles,
    onDrop: (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      setErrorMessage(undefined);
      handleAcceptedFiles(acceptedFiles);
    },
    onDropRejected: (rejectedFiles: any[]) => {
      const _fileErrors = rejectedFiles.map((errors) => {
        return errors;
      });

      if (_fileErrors[0].errors[0].code === 'file-too-large') {
        setErrorMessage('The image is too large. Max size is 2MB.');
      }
    },
    noClick: true,
  };

  const { getRootProps, getInputProps, open } = useDropzone(options);

  const previews = files.map((file, index) => {
    return <ImagePreview source={file.preview} alt={file.name} key={index} />;
  });

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const _helperText = helperText
    ? helperText
    : 'Click here to select your main image';

  return (
    <>
      <Grid
        sx={{
          mt: 1,
          mb: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 2,
          borderRadius: 2,
          borderColor: errorMessage ? '#EE0000' : '#eeeeee',
          borderStyle: errorMessage ? 'solid' : 'dashed',
          backgroundColor: '#EFEFEF',
          height: '400px',
          aspectRatio: '2/1',
          outline: 'none',
          transition: 'border .24s ease-in-out',
        }}
        {...getRootProps({ className: 'dropzone' })}
        onClick={open}
      >
        <input type="file" accept="image/*" {...getInputProps()} />
        {errorMessage && (
          <Typography variant="h3" textAlign="center">
            {errorMessage}
          </Typography>
        )}
        {files.length < 1 && !defaultValue && (
          <Grid container flexDirection="row" justifyContent="center">
            <ImagePlaceholderIcon sx={{ fontSize: '64px' }} />
            <Grid container justifyContent="center">
              <Typography variant="caption" textAlign="center">
                {_helperText}
              </Typography>
            </Grid>
          </Grid>
        )}
        {files.length > 0 && <>{previews}</>}
        {files.length < 1 && defaultValue && (
          <ImagePreview
            source={`${defaultValue}`}
            alt="Current Image"
            key="Current Image"
          />
        )}
      </Grid>
    </>
  );
};

export default Dropzone;
