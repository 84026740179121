export const formatCurrency = (value: string | number) => {
  const _value = Number(value);

  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    signDisplay: 'auto',
  }).format(_value);

  return currency;
};

export const months = [
  {
    value: 1,
    label: 'January',
    abbr: 'Jan',
  },
  {
    value: 2,
    label: 'February',
    abbr: 'Feb',
  },
  {
    value: 3,
    label: 'March',
    abbr: 'Mar',
  },
  {
    value: 4,
    label: 'April',
    abbr: 'Apr',
  },
  {
    value: 5,
    label: 'May',
    abbr: 'May',
  },
  {
    value: 6,
    label: 'June',
    abbr: 'Jun',
  },
  {
    value: 7,
    label: 'July',
    abbr: 'Jul',
  },
  {
    value: 8,
    label: 'August',
    abbr: 'Aug',
  },
  {
    value: 9,
    label: 'September',
    abbr: 'Sep',
  },
  {
    value: 10,
    label: 'October',
    abbr: 'Oct',
  },
  {
    value: 11,
    label: 'November',
    abbr: 'Nov',
  },
  {
    value: 12,
    label: 'December',
    abbr: 'Dec',
  },
];

export const years = () => {
  const minYear = new Date().getFullYear().toString().slice(2);

  const list = [];

  for (let i = 0; i < 10; i += 1) {
    list.push({
      value: Number(minYear) + i,
      label: Number(minYear) + i,
    });
  }

  return list;
};
