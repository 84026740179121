import { useState, useEffect } from 'react';

// material-ui
import LoadingButton from '@mui/lab/LoadingButton';

import {
  Button,
  Divider,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CastIcon from '@mui/icons-material/Cast';
import FestivalIcon from '@mui/icons-material/Festival';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuthState } from 'providers/AuthProvider/hooks';

import { useCallService } from 'hooks';

import stateList from 'data/states';

import Loading from 'components/Loading';
import FormSection from 'components/form/FormSection';
import GoogleMapsAutoComplete from 'components/autocomplete/AutoComplete';

import {
  DateFormField,
  TimeFormField,
  EmailFormField,
  SelectFormField,
  SwitchFormField,
  TextFormField,
} from 'components/formFields';
import { SelectOptionProps } from 'components/formFields/SelectFormField';
import { InActivePlantIcon, ReturnIcon } from 'material-icons';
import DisplayText from 'components/DisplayText';

import timezones from 'utils/timezone';
import regex from 'utils/regex';

import httpRoutes from 'utils/httpRoutes';

// type EventCategory {
//   id: number,
//   name: string
// };

interface EventLocation {
  name?: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  latitude?: number;
  longitude?: number;
  placeId?: string;
}

export interface IEventFormInputs {
  status: string;
  id?: number | undefined;
  name: string;
  hostname: string;
  contactEmail: string;
  cannabisConsumption: boolean;
  eventCategories: string[];
  private: boolean;
  isVirtual: boolean;
  hideLocation: boolean;
  virtualUrl?: string;
  eventLocation?: EventLocation;
  startsAt: Date;
  startTime: Date;
  endsAt: Date;
  endTime: Date;
  timezone: string;
  allowShare?: boolean;
  eventType?: number;
  nameSlug?: string;
  refundPolicy: number;
}

const validationSchema = yup.object({
  id: yup.string().nullable(),
  name: yup
    .string()
    .min(5, 'Event names must be at least 5 characters')
    .required('An event name is required'),
  hostname: yup.string().required('A host name is required'),
  contactEmail: yup
    .string()
    .email('Must be a valid email')
    .default('')
    .required('An email is required'),
  cannabisConsumption: yup.boolean(),
  venueName: yup.string().nullable(),
  eventCategories: yup
    .array()
    .of(yup.number())
    .default([])
    .min(1, 'Please select at least one category')
    .max(3, 'Please select a max of 3 categories')
    .required(),
  private: yup.boolean(),
  isVirtual: yup.boolean(),
  virtualUrl: yup
    .string()
    .when('isVirtual', {
      is: true,
      then: yup
        .string()
        .matches(
          regex.urlValidatorRegex,
          'Url must be valid (e.g. website.com/eventhi_io)'
        ),
    })
    .nullable(),
  eventLocation: yup.object().shape({
    name: yup.string().nullable(),
    streetAddress: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    postalCode: yup.string().nullable(),
    latitude: yup.number().nullable(),
    longitude: yup.number().nullable(),
    placeId: yup.string().nullable(),
  }),
  hideLocation: yup.boolean().default(false),
  startsAt: yup.date().default(new Date()),
  startTime: yup.date().default(new Date()),
  endsAt: yup.date().default(new Date()),
  endTime: yup.date().default(new Date()),
  timezone: yup.string().nullable(),
  refundPolicy: yup.string().required(),
});

interface EventGeneralInfoProps {
  onSubmit: (values: IEventFormInputs, details?: boolean) => void;
  onBack: VoidFunction;
  defaultValues?: IEventFormInputs;
}

export const INITIAL_EVENT_FORM_STATE: IEventFormInputs = {
  id: undefined,
  isVirtual: false,
  cannabisConsumption: false,
  private: false,
  name: '',
  hideLocation: false,
  hostname: '',
  contactEmail: '',
  status: 'Draft',
  eventCategories: [],
  virtualUrl: '',
  startsAt: new Date(),
  startTime: new Date(),
  endsAt: new Date(),
  endTime: new Date(),
  timezone: '',
  allowShare: false,
  eventType: 1,
  refundPolicy: 0,
};

const EventGeneralInfoForm = ({
  onSubmit,
  onBack,
  defaultValues,
}: EventGeneralInfoProps) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<SelectOptionProps[]>([]);

  const { callService } = useCallService();
  const { authState } = useAuthState();

  const preSubmit = (values: IEventFormInputs, details?: boolean) => {
    setLoading(true);
    values.allowShare = false;
    values.eventType = 1;

    values.startsAt.setHours(
      values.startTime.getHours(),
      values.startTime.getMinutes()
    );

    values.endsAt.setHours(
      values.endTime.getHours(),
      values.endTime.getMinutes()
    );

    onSubmit(values, details);
    setLoading(false);
  };

  const [eventType, setEventType] = useState(
    defaultValues && defaultValues.isVirtual ? 'virtual' : 'in-person'
  );

  const { control, handleSubmit, setValue, watch, getValues } =
    useForm<IEventFormInputs>({
      mode: 'onBlur',
      defaultValues: {
        id: defaultValues?.id || undefined,
        name: defaultValues?.name || '',
        timezone:
          defaultValues?.timezone ||
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        private: defaultValues?.private || false,
        hideLocation: defaultValues?.hideLocation || false,
        eventType: defaultValues?.eventType || 0,
        cannabisConsumption: defaultValues?.cannabisConsumption || false,
        hostname: defaultValues?.hostname || '',
        contactEmail: defaultValues?.contactEmail || '',
        endsAt: defaultValues?.endsAt || new Date(),
        endTime: defaultValues?.endTime || new Date(),
        startsAt: defaultValues?.startsAt || new Date(),
        startTime: defaultValues?.startTime || new Date(),
        allowShare: defaultValues?.allowShare || false,
        isVirtual: defaultValues?.isVirtual || false,
        virtualUrl: defaultValues?.virtualUrl || '',
        eventCategories: defaultValues?.eventCategories || [],
        refundPolicy: defaultValues?.refundPolicy || 0,
        eventLocation: defaultValues?.eventLocation || {
          name: '',
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          latitude: undefined,
          longitude: undefined,
          placeId: undefined,
        },
      },
      shouldUnregister: false,
      resolver: yupResolver(validationSchema),
    });

  const watchStartDate = watch('startsAt');

  useEffect(() => {
    if (getValues('endsAt') < getValues('startsAt')) {
      setValue('endsAt', getValues('startsAt'));
    }
  }, [watchStartDate]);

  // get category types
  useEffect(() => {
    const getCategories = async () => {
      const { response } = await callService({
        resource: httpRoutes.organizer.getEventCategoryTypes(),
      });

      if (response) {
        setCategories(
          response.map((item: any) => ({ label: item.name, value: item.id }))
        );
      }
    };

    try {
      setLoading(true);
      getCategories();
    } finally {
      setLoading(false);
    }
  }, [authState.authenticated]);

  const formatAddress = (data: any) => {
    let streetAddress = '';
    let cityNeighborhood = '';

    if (!data.addressComponents) {
      setValue('eventLocation.latitude', 0);
      setValue('eventLocation.longitude', 0);
      setValue('eventLocation.placeId', '');
      setValue('eventLocation.streetAddress', '');
      setValue('eventLocation.city', '');
      setValue('eventLocation.state', '');
      setValue('eventLocation.postalCode', '');
      return;
    }

    setValue('eventLocation.latitude', data.latitude);
    setValue('eventLocation.longitude', data.longitude);
    setValue('eventLocation.placeId', data.placeId);
    setValue('eventLocation.name', '');

    for (let index = 0; index < data.addressComponents.length; index++) {
      const element = data.addressComponents[index];

      const types = element.types;

      for (let j = 0; j < types.length; j++) {
        const addressDataType = types[j];

        switch (addressDataType) {
          case 'establishment':
          case 'park':
          case 'point_of_interest':
          case 'tourist_attraction':
            setValue('eventLocation.name', element.long_name);
            break;
          case 'street_number':
            streetAddress = element.long_name;
            setValue('eventLocation.streetAddress', streetAddress);
            break;
          case 'route':
            streetAddress = `${streetAddress} ${element.long_name}`;
            setValue('eventLocation.streetAddress', streetAddress);
            break;
          case 'sublocality':
            cityNeighborhood = element.short_name;
            setValue('eventLocation.city', cityNeighborhood);
            break;
          case 'locality':
          case 'neighborhood':
            cityNeighborhood = `${cityNeighborhood} ${element.long_name}`;
            setValue('eventLocation.city', cityNeighborhood);
            break;
          case 'administrative_area_level_1':
            setValue('eventLocation.state', element.short_name);
            break;
          case 'postal_code':
            setValue('eventLocation.postalCode', element.long_name);
            break;
          default:
            break;
        }
      }
    }
  };

  if (loading) return <Loading />;

  return (
    <Grid p={1} pb={4} mb={1} maxWidth="md">
      <form onSubmit={handleSubmit((values) => preSubmit(values))}>
        {/* General Info */}
        <FormSection
          title="Event Info"
          subtitle="Input the basic event information"
          icon={
            <FeedOutlinedIcon
              sx={{
                fontSize: '4rem',
                color: 'gray',
              }}
            />
          }
        >
          <TextFormField
            name="name"
            control={control}
            label="Event Name"
            required
          />

          <TextFormField
            name="hostname"
            control={control}
            label="Host/Organizer Name"
            required
          />

          <EmailFormField
            name="contactEmail"
            control={control}
            label="Host Email"
            required
          />

          <Typography variant="h6">Event Categories</Typography>
          <Typography variant="caption">
            Select up to three categories that best describe your event.
          </Typography>
          <SelectFormField
            name="eventCategories"
            control={control}
            label="Categories"
            options={categories}
            multiple
            required
          />
          {/* Options */}
          <Grid container>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={4} mt={3} ml={1}>
                <Typography variant="body1">Is cannabis allowed?</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="cannabisConsumption"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      sx={{ mt: 1, marginLeft: 3.5 }}
                      {...field}
                      onChange={(_, value) => {
                        if (value === null) return;
                        field.onChange(value);
                      }}
                      exclusive
                      size="small"
                      color="primary"
                    >
                      <ToggleButton value={true}>
                        <InActivePlantIcon />{' '}
                        <Typography ml={2}>Yes</Typography>
                      </ToggleButton>
                      <ToggleButton value={false}>
                        <DoNotDisturbAltIcon />{' '}
                        <Typography ml={2}>No</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={4} mt={3} ml={1}>
                <Typography variant="body1">
                  Is this a public or private event?
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="private"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      sx={{ ml: 1, mt: 1 }}
                      {...field}
                      onChange={(_, value) => {
                        if (value === null) return;
                        field.onChange(value);
                      }}
                      exclusive
                      size="small"
                      color="primary"
                    >
                      <ToggleButton value={true}>
                        <LockIcon /> <Typography ml={2}>Private</Typography>
                      </ToggleButton>
                      <ToggleButton value={false}>
                        <LockOpenIcon /> <Typography ml={2}>Public</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormSection>
        {/* Location Info */}
        <FormSection
          title="Location"
          subtitle="Where will the event be?"
          icon={
            <AddLocationAltOutlinedIcon
              sx={{
                fontSize: '4rem',
                color: 'gray',
              }}
            />
          }
        >
          <Controller
            name="isVirtual"
            control={control}
            render={({ field }) => (
              <ToggleButtonGroup
                {...field}
                onChange={(_, value) => {
                  if (value === null) return;
                  field.onChange(value);

                  if (eventType === 'virtual') {
                    setEventType('in-person');
                  } else {
                    setEventType('virtual');
                  }
                }}
                fullWidth
                exclusive
                size="large"
                color="primary"
              >
                <ToggleButton value={true}>
                  <CastIcon /> <Typography ml={2}>Virtual Event</Typography>
                </ToggleButton>
                <ToggleButton value={false}>
                  <FestivalIcon />{' '}
                  <Typography ml={2}>In-Person Event</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
          {eventType === 'virtual' && (
            <TextFormField
              name="virtualUrl"
              control={control}
              label="Virtual URL"
              placeholder="Paste a link to your virtual event here."
            />
          )}
          {eventType === 'in-person' && (
            <Grid spacing={1} mt={4}>
              <Grid item mb={2}>
                <Typography variant="body1">
                  Search for your event location here!
                </Typography>
              </Grid>
              <Grid item mb={2}>
                <GoogleMapsAutoComplete
                  onChange={(value) => {
                    /**
                     * streetAddress: "1250 Waters Pl, The Bronx, NY 10461, USA"
                        addressComponents: Array(8)
                        0: {long_name: '1250', short_name: '1250', types: Array(1)}
                        1: {long_name: 'Waters Place', short_name: 'Waters Pl', types: Array(1)}
                        2: {long_name: 'Pelham Bay', short_name: 'Pelham Bay', types: Array(2)}
                        3: {long_name: 'The Bronx', short_name: 'The Bronx', types: Array(3)}
                        4: {long_name: 'Bronx County', short_name: 'Bronx County', types: Array(2)}
                        5: {long_name: 'New York', short_name: 'NY', types: Array(2)}
                        6: {long_name: 'United States', short_name: 'US', types: Array(2)}
                        7: {long_name: '10461', short_name: '10461', types: Array(1)}
                        latitude: 40.8526781
                        longitude: -73.839137
                        placeId: "ChIJS4ZOfFWLwokR-ScG94bx5UQ"
                     */
                    formatAddress(value);
                  }}
                  label="Search Addresses"
                  placeholder="123 Main Street Los Angeles, CA 90210"
                  standout
                />
              </Grid>
              <Grid item mt={4}>
                <Divider />
                <Grid container p={2}>
                  <TextFormField
                    name="eventLocation.name"
                    control={control}
                    label="Name of Venue"
                  />
                  <TextFormField
                    name="eventLocation.streetAddress"
                    control={control}
                    label="Street Address"
                    required={eventType === 'in-person'}
                  />

                  <TextFormField
                    name="eventLocation.city"
                    control={control}
                    label="City"
                    required={eventType === 'in-person'}
                  />

                  <Grid container justifyContent="space-between">
                    <Grid item xs={6} md={8}>
                      <SelectFormField
                        name="eventLocation.state"
                        control={control}
                        label="State"
                        options={stateList}
                        required={eventType === 'in-person'}
                      />
                    </Grid>
                    <Grid item xs={5} md={3}>
                      <TextFormField
                        name="eventLocation.postalCode"
                        control={control}
                        label="Zip Code"
                        required={eventType === 'in-person'}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <SwitchFormField
                      name="hideLocation"
                      label="Hide location on event page. Location only shown on ticket after purchase"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </FormSection>
        {/* Date/Time Info */}
        <FormSection
          title="When"
          subtitle="What dates will your event be held?"
          icon={
            <AccessTimeOutlinedIcon
              sx={{
                fontSize: '4rem',
                color: 'gray',
              }}
            />
          }
        >
          <Grid
            container
            justifyContent="space-between"
            mt={1}
            spacing={1.5}
            ml={2}
          >
            <DisplayText text="From" type="bodyMedium" />
            <Grid item xs={12} flexDirection="row">
              <DateFormField
                label="Start Date"
                name="startsAt"
                control={control}
                sx={{ padding: 1 }}
              />

              <TimeFormField
                label="Start Time"
                name="startTime"
                control={control}
                sx={{ padding: 1 }}
              />
            </Grid>
            <DisplayText text="To" type="bodyMedium" />

            <Grid item xs={12} flexDirection="row">
              <DateFormField
                label="End Date"
                name="endsAt"
                control={control}
                sx={{ padding: 1 }}
              />

              <TimeFormField
                label="End Time"
                name="endTime"
                control={control}
                sx={{ padding: 1 }}
              />
            </Grid>
            <Grid item xs={6} md={3} alignItems="bottom">
              <SelectFormField
                label="Time Zone"
                variant="standard"
                name="timezone"
                control={control}
                options={timezones}
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSection
          icon={
            <ReturnIcon
              sx={{
                fontSize: '3rem',
                color: 'gray',
              }}
            />
          }
          title="Refund Policy"
          subtitle="Select a return policy for tickets"
        >
          <SelectFormField
            label="Refund Policy"
            name="refundPolicy"
            control={control}
            options={[
              { label: 'No Refunds', value: 0 },
              { label: '1 day before event', value: 1 },
              { label: '3 days before event', value: 3 },
              { label: '7 days before event', value: 7 },
            ]}
          />
        </FormSection>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              color="primary"
              variant="text"
              sx={{ marginLeft: 2 }}
              onClick={onBack}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              type="submit"
              sx={{ mr: 2 }}
            >
              Save
            </LoadingButton>
            <LoadingButton
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              onClick={handleSubmit((data) => preSubmit(data, true))}
            >
              Save & Go To Details
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EventGeneralInfoForm;
