import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface FilterState {
  page: number;
  limit: number;
  order: 'ASC' | 'DESC';
  orderBy: string;
  name: string;
  latitude?: number;
  longitude?: number;
  radius?: number | string;
  city: string;
  state: string;
  cannabisConsumption: string;
  virtual: string;
  categoryId?: number | string;
  startDate: Date | null;
  endDate: Date | null;
  cleared?: boolean;
}

const FILTER_INITIAL_STATE: FilterState = {
  page: 0,
  limit: 16,
  order: 'ASC',
  orderBy: '',
  name: '',
  latitude: undefined,
  longitude: undefined,
  radius: 0,
  city: '',
  state: '',
  cannabisConsumption: '',
  virtual: '',
  categoryId: '',
  startDate: null,
  endDate: null,
  cleared: true,
};

interface FilterStore {
  state: FilterState;
  update: (filterState: FilterState) => void;
  clear: () => void;
}

const useFilterStore = create<FilterStore>()(
  devtools(
    persist(
      (set) => ({
        state: FILTER_INITIAL_STATE,
        update: (newState) => {
          set(() => ({ state: { cleared: false, ...newState } }));
        },
        clear: () => {
          set(() => ({ state: FILTER_INITIAL_STATE }));
        },
      }),
      {
        name: 'event-filter-storage',
      }
    )
  )
);

export default useFilterStore;
