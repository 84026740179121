import { useCallService } from 'hooks';

import SponsorshipTypeForm, {
  ISponsorshipTypeFormInputs,
} from 'features/sponsorships/forms/SponsorshipTypeForm';

import httpRoutes from 'utils/httpRoutes';
import { IEvent } from 'layout/OrganizerEventLayout';

type CreateSponsorshipTypeProps = {
  eventId: number;
  eventDetails: IEvent;
  sponsorshipId: string;
  onSuccess: () => void;
  hideDialog: () => void;
};

const CreateSponsorshipType = ({
  eventId,
  eventDetails,
  sponsorshipId,
  onSuccess,
  hideDialog,
}: CreateSponsorshipTypeProps) => {
  const { callService } = useCallService();

  const createSponsorshipType = async (values: ISponsorshipTypeFormInputs) => {
    const { response } = await callService({
      resource: httpRoutes.sponsorshipTypes.createSponsorshipType(values),
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  return (
    <SponsorshipTypeForm
      eventId={eventId}
      eventDetails={eventDetails}
      sponsorshipId={sponsorshipId}
      onSubmit={createSponsorshipType}
      hideDialog={hideDialog}
    />
  );
};

export default CreateSponsorshipType;
