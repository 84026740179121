import axios from 'axios';
import { instance as apiClient, createAxiosInstance } from './axios';

interface IPutItem {
  key: string;
  body: File;
  operation?: string;
  contentType?: string;
}

interface IFileManagement {
  putItemWithPresignedUrl(params: IPutItem): Promise<any>;
}

export class FileManagement implements IFileManagement {
  private readonly _aws: any;

  constructor() {
    const apiBase = '/api/v1';

    this._aws = {
      generatePresignedUrl(
        fileName: string,
        fileType?: string,
        operation?: string
      ) {
        return apiClient.get(
          `${apiBase}/aws/generate-presigned-url?fileName=${fileName}&fileType=${fileType}&operation=${operation}`
        );
      },
      S3Put(url: string, file: any) {
        const awsInstance = createAxiosInstance(
          // eslint-disable-next-line no-undef
          url,
          'application/json'
        );
        return awsInstance.put('', {
          file,
        });
      },
    };
  }

  public async putItemWithPresignedUrl(params: IPutItem) {
    try {
      const contentType = params.contentType || 'application/octet-stream';
      const operation = params.operation || 'putObject';

      const { data: url } = await this._aws.generatePresignedUrl(
        params.key,
        contentType,
        operation
      );

      if (!url) {
        console.log('Could not retrieve presigned url.');
        return null;
      }

      const headers = {
        'Content-Type': contentType,
      };

      // Perform the PUT request
      return await axios.put(url, params.body, { headers });
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}

export const fileManagement = new FileManagement();

export default fileManagement;
