import { useState, useEffect } from 'react';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import { useRightDrawerDispatcher } from 'providers/RightDrawerProvider/hooks/useRightDrawerDispatcher';

import OrderSummary from '../components/rightDrawer/OrderSummary';

import { REACT_APP_CLOUDFRONT_URL } from 'config/config';

import resources from '../resources';
import { formatCurrency } from 'utils/helpers';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

const OrderHistoryGrid = () => {
  const { state } = useDataGridState();

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const { callService } = useCallService();
  const { showRightDrawer } = useRightDrawerDispatcher();

  const getOrderDetails = (orderId: number) => {
    showRightDrawer({
      content: <OrderSummary orderId={orderId} />,
    });
  };

  const getOrders = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));
    try {
      const { response } = await callService({
        resource: resources.getOrderHistory({
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getOrders();
  }, [filters, state]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: '#',
      field: 'eventImage',
      headerAlign: 'center',
      sortable: true,
      filterable: false,
      flex: 0.1,
      ColumnComponent: (params: any) => {
        return (
          <img
            src={REACT_APP_CLOUDFRONT_URL + '/' + params.row.eventImage}
            style={{
              margin: '0 auto',
              height: '51px',
              maxWidth: '75px',
              objectFit: 'contain',
            }}
          />
        );
      },
    },
    {
      headerName: 'Event Name',
      field: 'eventName',
      sortable: true,
      filterable: false,
      flex: 0.4,
    },
    {
      headerName: 'Order #',
      field: 'id',
      sortable: true,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Date Purchased',
      field: 'datePurchased',
      sortable: true,
      filterable: false,
      hide: true,
      type: 'dateTime',
      flex: 0.2,
    },
    {
      headerName: 'Order Status',
      field: 'status',
      sortable: false,
      filterable: false,
      flex: 0.2,
    },
    {
      headerName: 'Subtotal',
      field: 'subtotal',
      sortable: false,
      filterable: false,
      type: 'usdCurrency',
      flex: 0.1,
      headerAlign: 'right',
      align: 'right',
    },
    {
      headerName: 'Fees',
      field: 'serviceFee',
      filterable: false,
      sortable: false,
      type: 'usdCurrency',
      flex: 0.15,
      headerAlign: 'right',
      ColumnComponent: (params: any) => {
        const _value =
          Number(params.row.serviceFee) +
          Number(params.row.itemFee) +
          Number(params.row.processingFee);
        return formatCurrency(_value);
      },
      align: 'right',
    },
    {
      headerName: 'Total',
      field: 'total',
      filterable: false,
      sortable: false,
      type: 'usdCurrency',
      flex: 0.15,
      headerAlign: 'right',
      align: 'right',
    },
    {
      headerName: 'Transactions',
      field: 'mobile',
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <Card sx={{ m: 1 }}>
            <CardMedia
              component="img"
              image={`${REACT_APP_CLOUDFRONT_URL}/${params.row.eventImage}`}
              sx={{
                top: '0',
                left: '0',
                position: 'relative',
                aspectRatio: '2/1',
                width: '100%',
              }}
            />
            <CardContent>
              <Grid item xs={12} sm={3} p={1}>
                <Typography fontWeight="bold">
                  {params.row.eventName}
                </Typography>
                <Typography variant="caption">Order #</Typography>
                <Typography>{params.row.id}</Typography>
              </Grid>
            </CardContent>
          </Card>
        );
      },
    },
  ];

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'eventName',
      type: 'text',
      label: 'Event Name',
    },
  ];

  return (
    <PageContainer maxWidth="xl" title="Order History">
      <DataGrid
        tableTitle="Order History"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
        onRowClick={(params) => getOrderDetails(Number(params.id))}
      />
    </PageContainer>
  );
};

const OrderHistory = () => (
  <ControlledDataGrid>
    <OrderHistoryGrid />
  </ControlledDataGrid>
);

export default OrderHistory;
