import { PaginationInterface } from 'components/dataGrid/components/Pagination';
import { FilterState } from 'components/filters/Filters';
import { instance as apiClient } from 'utils/axios';
import { ProfileFormInputs } from './components/Profile';
import { ISecurityFormInputs } from './components/Security';

const apiBase = '/api';

const resources = {
  getUserProfile() {
    return apiClient.get(`${apiBase}/users/profile`);
  },
  updateUserProfile(payload: ProfileFormInputs) {
    return apiClient.put(`${apiBase}/users/profile`, payload);
  },
  changeUserPassword(payload: ISecurityFormInputs) {
    return apiClient.put(`${apiBase}/users/change-password`, payload);
  },
  getOrderHistory({
    params,
    filters,
  }: {
    params: PaginationInterface;
    filters: FilterState;
  }) {
    const formattedParams = {
      ...params,
      eventName: filters.get('eventName'),
    };

    return apiClient.get(`${apiBase}/users/order-history`, {
      params: formattedParams,
    });
  },
  getOrderDetails(orderId: number) {
    return apiClient.get(`${apiBase}/users/order-details/${orderId}`);
  },
  downloadTickets(orderId: number) {
    return apiClient.get(`${apiBase}/users/tickets/download/${orderId}`);
  },
};

export default resources;
