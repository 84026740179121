import { useContext } from 'react';
import AuthContext from '../contexts/AuthContext';

const useAuthDispatcher = () => {
  const { login, logout } = useContext(AuthContext);

  return {
    login,
    logout,
  };
};

export default useAuthDispatcher;
