import { useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

// project imports
import useCallService from 'hooks/useCallService';

import httpRoutes from 'utils/httpRoutes';

import {
  CheckboxFormField,
  EmailFormField,
  PasswordFormField,
  TextFormField,
} from 'components/formFields';

export interface IRegisterFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  termsAndConditions: boolean;
}

const AuthRegister = ({ onSuccess }: { onSuccess: () => void }) => {
  const theme = useTheme();

  const { callService } = useCallService();

  const location: any = useLocation();
  const navigate = useNavigate();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const from: string = location?.pathname || '/';

  const onSubmit = async (values: IRegisterFormInputs) => {
    const response = await callService({
      resource: httpRoutes.authentication.createUserAccount(values),
      successMessage: 'Account created successfully! Please sign in.',
    });

    if (response) {
      onSuccess();
      navigate(from, { replace: true });
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().max(255).required('Password is required'),
    termsAndConditions: Yup.boolean()
      .oneOf([true], 'The terms and conditions must be accepted.')
      .required('The terms and conditions must be accepted.'),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<IRegisterFormInputs>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      termsAndConditions: false,
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12} sm={6}>
            <TextFormField
              name="firstName"
              control={control}
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFormField
              name="lastName"
              control={control}
              label="Last Name"
            />
          </Grid>
        </Grid>
        <EmailFormField name="email" control={control} label="Email" />

        <PasswordFormField name="password" control={control} label="Password" />

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <CheckboxFormField
              name="termsAndConditions"
              control={control}
              label={
                <Typography variant="subtitle1">
                  Agree to{' '}
                  <Link href="/terms-and-conditions">Terms & Conditions.</Link>
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Box>
          <Button
            disableElevation
            disabled={isSubmitting || !isValid}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="secondary"
          >
            Sign up
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AuthRegister;
