import * as React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

import { FilterProps } from '../filterTypes';

export default function TextFilter({
  id,
  label,
  value = '',
  onChange,
}: FilterProps) {
  return (
    <TextField
      fullWidth
      id={id}
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
}
