import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import AuthLogin from 'features/auth/components/AuthLogin';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useAuthState } from 'providers/AuthProvider/hooks';

import Logo from 'components/Logo';

import SignUp from '../dialogs/SignUp';
import AuthWrapper from '../components/AuthWrapper';

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const { showDialog } = useDialogDispatcher();

  const from = '/';

  const {
    authState: { authenticated },
  } = useAuthState();

  // redirect if logged in
  useEffect(() => {
    if (authenticated) {
      navigate(from, { replace: true });
    }
  }, [authenticated]);

  const showSignUp = () => {
    showDialog({
      size: 'sm',
      content: <SignUp />,
    });
  };

  const onSuccess = () => {
    // redirect to previous page
  };

  return (
    <AuthWrapper>
      <Grid
        container
        maxWidth="sm"
        justifyContent="center"
        sx={{ margin: 'auto', pt: 3 }}
      >
        <Paper elevation={1} sx={{ p: 2 }}>
          <Grid container justifyContent="center">
            <Grid item sx={{ mb: 3 }} justifyContent="center">
              <Logo />
            </Grid>
            <Grid item xs={12}>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <>
                  <Typography
                    color={theme.palette.secondary.main}
                    gutterBottom
                    fontWeight="bold"
                    fontFamily="Montserrat"
                    fontSize={matchDownSM ? '24px' : '36px'}
                    sx={{
                      textShadow: '1px 1px 1px rgb(122, 122, 122, 0.45)',
                    }}
                  >
                    Hi, Welcome Back!
                  </Typography>
                  <Typography
                    variant="caption"
                    fontSize="16px"
                    pt={3}
                    textAlign={matchDownSM ? 'center' : 'inherit'}
                  >
                    Enter your credentials to continue
                  </Typography>
                </>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <AuthLogin onSuccess={onSuccess} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                xs={12}
              >
                <Button variant="text" onClick={showSignUp}>
                  Don&apos;t have an account?
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
