import React from 'react';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';

import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface IconButtonProps extends MuiIconButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const IconButton = ({ sx, ...props }: IconButtonProps) => (
  <MuiIconButton {...props} sx={sx}>
    {props.icon}
  </MuiIconButton>
);

export default IconButton;
