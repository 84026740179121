import { useEffect, useState } from 'react';

import Loading from 'components/Loading';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import OrderSummary, {
  INITIAL_STATE,
  OrderDetailsProps,
} from 'shared/OrderSummary';

type LoadingType = {
  data: boolean;
};
const loadingState: LoadingType = { data: true };

const OrganizerOrderSummary = ({ orderId }: { orderId: number }) => {
  const [loading, setLoading] = useState<LoadingType>(loadingState);
  const [details, setDetails] = useState<OrderDetailsProps>(INITIAL_STATE);

  const { callService } = useCallService();

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    const getOrderDetails = async (orderId: number) => {
      const { response } = await callService({
        resource: httpRoutes.organizer.getTransactionDetails(orderId),
      });

      if (response) {
        setDetails(response);
        setLoading((prevState) => ({ ...prevState, data: false }));
      }
    };

    getOrderDetails(orderId);
  }, [orderId]);

  if (loading) <Loading />;

  return <OrderSummary details={details} />;
};

export default OrganizerOrderSummary;
