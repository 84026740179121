import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import AuthLogin from 'features/auth/components/AuthLogin';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import Logo from 'components/Logo';

import SignUp from './SignUp';

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const { hideDialog, showDialog } = useDialogDispatcher();

  const showSignUp = () => {
    showDialog({
      size: 'sm',
      content: <SignUp />,
    });
  };

  const onSuccess = () => {
    hideDialog();
  };

  return (
    <Grid
      container
      maxWidth="sm"
      justifyContent="center"
      sx={{ margin: 'auto', pt: 3 }}
    >
      <Paper elevation={1} sx={{ p: 2 }}>
        <Grid container justifyContent="center">
          <Grid item sx={{ mb: 3 }} justifyContent="center">
            <Logo />
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <>
                <Typography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  fontWeight="bold"
                  fontFamily="Montserrat"
                  fontSize={matchDownSM ? '24px' : '36px'}
                  sx={{
                    textShadow: '1px 1px 1px rgb(122, 122, 122, 0.45)',
                  }}
                >
                  Hi, Welcome Back!
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="16px"
                  pt={3}
                  textAlign={matchDownSM ? 'center' : 'inherit'}
                >
                  Enter your credentials to continue
                </Typography>
              </>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <AuthLogin onSuccess={onSuccess} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid item container direction="column" alignItems="center" xs={12}>
              <Button variant="text" onClick={showSignUp}>
                Don&apos;t have an account?
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Login;
