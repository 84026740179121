import { createContext } from 'react';

export type User = {
  id: number | undefined;
  firstName: string;
  lastName: string;
  email: string;
};

export type AuthStateType = {
  authenticated: boolean;
  user: User;
  token: string;
  tokenExpiration: number;
  validating: boolean;
  isAdmin: boolean;
};

export const AUTH_INITIAL_STATE: AuthStateType = {
  authenticated: false,
  user: {
    id: undefined,
    firstName: '',
    lastName: '',
    email: '',
  },
  token: '',
  tokenExpiration: 0,
  validating: false,
  isAdmin: false,
};

const AuthContext = createContext<{
  authState: AuthStateType;
  login: ({ user, token }: { user: User; token: string }) => void;
  logout: () => void;
}>({
  authState: AUTH_INITIAL_STATE,
  login: () => null,
  logout: () => null,
});

export default AuthContext;
