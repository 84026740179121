import { ReactNode } from 'react';

import { Grid, Paper } from '@mui/material';

const AuthWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      container
      maxWidth="sm"
      justifyContent="center"
      sx={{ margin: 'auto', pt: 3 }}
    >
      <Paper elevation={1} sx={{ p: 2 }}>
        <Grid container justifyContent="center">
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AuthWrapper;
