import { Fragment, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
// import TextField from 'components/atomic/'
import Stack from '@mui/material/Stack';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

import {
  DateRangePicker as MuiDateRangePicker,
  DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useFilterStore from 'states/filterState';

export default function DateRangePicker({
  onChange,
}: {
  onChange: (value: DateRange<Date | null>) => void;
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const filterStore = useFilterStore();

  const [value, setValue] = useState<DateRange<Date>>([
    filterStore.state.startDate,
    filterStore.state.endDate,
  ]);

  useEffect(() => {
    const {
      state: { cleared },
    } = filterStore;

    if (cleared) {
      setValue([filterStore.state.startDate, filterStore.state.endDate]);
    }
  }, [filterStore.state.cleared]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {!matches && (
        <MuiDateRangePicker
          startText="Start"
          endText="End"
          value={value}
          onChange={(newValue) => {
            console.log('New Value: ', newValue);
            setValue(newValue);
            onChange(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <Fragment>
              <TextField
                sx={{ mr: 0.25 }}
                fullWidth
                variant="filled"
                InputProps={{
                  endAdornment: <EventOutlinedIcon color="inherit" />,
                }}
                {...startProps}
              />
              <TextField
                fullWidth
                variant="filled"
                InputProps={{
                  endAdornment: <EventOutlinedIcon color="inherit" />,
                }}
                {...endProps}
              />
            </Fragment>
          )}
        />
      )}
      {matches && (
        <MobileDateRangePicker
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <Stack>
              <TextField
                variant="filled"
                fullWidth
                InputProps={{
                  endAdornment: <EventOutlinedIcon color="inherit" />,
                }}
                {...startProps}
              />
              <TextField
                variant="filled"
                fullWidth
                InputProps={{
                  endAdornment: <EventOutlinedIcon color="inherit" />,
                }}
                {...endProps}
              />
            </Stack>
          )}
        />
      )}
    </LocalizationProvider>
  );
}
