import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import { REACT_APP_CLOUDFRONT_URL } from 'config/config';
import { PublishEventIcon } from 'material-icons';

const PublishPrompt = ({
  eventDetails,
  onSuccess,
  hideDialog,
}: {
  eventDetails: any;
  onSuccess: VoidFunction;
  hideDialog: VoidFunction;
}) => {
  const { callService } = useCallService();

  const updateStatus = async () => {
    const { response } = await callService({
      resource: httpRoutes.organizer.updateEventStatus(
        eventDetails.id,
        'Published'
      ),
      successMessage: 'Your event was published successfully!',
    });

    if (response) {
      hideDialog();
      onSuccess();
    }
  };

  return (
    <Grid
      container
      maxWidth="sm"
      justifyContent="center"
      flexDirection="row"
      sx={{ p: 2 }}
    >
      <Grid item xs={12}>
        <Typography variant="h1" align="center">
          Great job!
        </Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Typography variant="h2" align="center">
          {eventDetails.name}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Card sx={{ m: '0 auto', border: 'none', boxShadow: 'none' }}>
          <CardMedia
            component="img"
            image={`${REACT_APP_CLOUDFRONT_URL}/${eventDetails.imageUrl}`}
            sx={{
              objectFit: 'contain',
              position: 'relative',
              // top: 0,
              // left: 0,
              height: '250px',
              aspectRatio: '2/1',
              boxShadow: 'none',
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant="h3" align="center">
          is ready to be published.
        </Typography>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Grid container justifyContent="space-between" sx={{ p: 3 }}>
          <Grid item>
            <Button variant="text" onClick={hideDialog}>
              I&apos;m not quite ready
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={updateStatus}
              sx={{ margin: 'auto' }}
              startIcon={<PublishEventIcon color="inherit" />}
            >
              Publish Event
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PublishPrompt;
