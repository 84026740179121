import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export type Order = 'asc' | 'desc';

interface ITableHeader {
  columns: any[];
  orderBy?: string;
  order?: Order;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
}

const TableHeader = ({
  columns,
  orderBy,
  order,
  onRequestSort,
}: ITableHeader) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          const columnIsSorted = orderBy === column.field;
          if (column.field === 'mobile' && matchDownSM) {
            return (
              <TableCell
                key={column.field}
                align={column.headerAlign || column.numeric}
                sortDirection={columnIsSorted ? order : false}
              >
                <TableSortLabel
                  active={columnIsSorted}
                  direction={columnIsSorted ? order : 'asc'}
                  onClick={createSortHandler(column.field)}
                >
                  {column.headerName}
                  {columnIsSorted ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }

          if (column.field !== 'mobile' && !matchDownSM) {
            return (
              <TableCell
                key={column.field}
                align={column.headerAlign || column.numeric}
                sortDirection={columnIsSorted ? order : false}
              >
                <TableSortLabel
                  active={columnIsSorted}
                  direction={columnIsSorted ? order : 'asc'}
                  onClick={createSortHandler(column.field)}
                >
                  {column.headerName}
                  {columnIsSorted ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
