import { ReactNode } from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

const PageContainer = ({
  title,
  icon,
  pageAction,
  elevation = 0,
  isSecondary,
  maxWidth = 'xl',
  children,
}: {
  title?: string;
  icon?: ReactNode;
  pageAction?: ReactNode;
  elevation?: number;
  isSecondary?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children: ReactNode;
}) => {
  return (
    <Container maxWidth={maxWidth} sx={{ pl: 0, pr: 0 }}>
      <Paper
        elevation={elevation}
        sx={{
          p: { xs: 1, sm: 1, md: 2 },
          borderRadius: 2,
          backgroundColor: '#FEFEFE',
        }}
      >
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            pb: 2,
            ml: isSecondary ? 2 : 0,
          }}
        >
          {icon && title && (
            <Grid item>
              <Grid container flexDirection="row">
                {icon}
                <Typography
                  variant={isSecondary ? 'h4' : 'h2'}
                  sx={{ mb: 2, mt: 0.75, ml: 1 }}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
          )}
          {!icon && title && (
            <Grid item>
              <Typography
                variant={isSecondary ? 'h4' : 'h3'}
                sx={{ mb: 2, mt: 0.75, ml: 1, fontWeight: 400 }}
              >
                {title}
              </Typography>
            </Grid>
          )}
          {pageAction && (
            <Grid
              item
              sx={{
                mr: {
                  xs: 'none',
                  sm: 3,
                },
              }}
            >
              {pageAction}
            </Grid>
          )}
        </Grid>

        <Box sx={{ width: '100%', ml: isSecondary ? 2 : 0 }}>{children}</Box>
      </Paper>
    </Container>
  );
};

export default PageContainer;
