import { useState } from 'react';

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  AttendeeIcon,
  CheckOrderIcon,
  EventIcon,
  TicketStubIcon,
  TimeIcon,
} from 'material-icons';

import Alert from 'components/Alert';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';
import { formatEventStartDate } from 'utils/formatDateTime';
import { formatCurrency } from 'utils/helpers';

const TicketDetails = ({
  ticketDetails,
  onSuccess,
}: {
  ticketDetails: any;
  onSuccess: VoidFunction;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();

  const [success, showSuccess] = useState<boolean>(false);
  const [undoSuccess, showUndoSuccess] = useState<boolean>(false);

  const checkInAttendee = async (id: number) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.checkInAttendee(id),
      });

      if (response) {
        showSuccess(true);
        onSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const undoCheckInAttendee = async (id: number) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.undoCheckInAttendee(id),
      });

      if (response) {
        showUndoSuccess(true);
        onSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Grid
      container
      maxWidth="sm"
      justifyContent="center"
      flexDirection="column"
      sx={{ p: 2, margin: 'auto' }}
    >
      <Typography variant="h2" align="center" pb={2}>
        Ticket Details
      </Typography>
      {success && (
        <Alert severity="success" text="Attendee successfully checked in!" />
      )}
      {undoSuccess && (
        <Alert severity="success" text="Attendee check in removed!" />
      )}
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: 'white',
                  }}
                >
                  <EventIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={ticketDetails.eventName} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: 'white',
                  }}
                >
                  <TimeIcon color="primary" />
                </Avatar>{' '}
              </ListItemAvatar>
              <ListItemText
                primary={formatEventStartDate(ticketDetails.eventDate)}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: 'white',
                  }}
                >
                  <TicketStubIcon color="primary" />
                </Avatar>{' '}
              </ListItemAvatar>
              <ListItemText
                primary={ticketDetails.ticketName}
                secondary={formatCurrency(ticketDetails.purchasePrice)}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: 'white',
                  }}
                >
                  <AttendeeIcon color="primary" />
                </Avatar>{' '}
              </ListItemAvatar>
              <ListItemText
                primary={`${ticketDetails.userName} (${ticketDetails.userEmail})`}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: 'white',
                  }}
                >
                  <CheckOrderIcon color="primary" />
                </Avatar>{' '}
              </ListItemAvatar>
              <ListItemText
                primary="Attendee Checked In"
                secondary={
                  (ticketDetails.claimed || success) && !undoSuccess
                    ? 'Yes'
                    : 'No'
                }
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      {!ticketDetails.claimed && !success && (
        <Button
          sx={{ mt: 1 }}
          variant="contained"
          onClick={() => checkInAttendee(ticketDetails.id)}
        >
          Check-In Attendee
        </Button>
      )}
      {(success || undoSuccess) && (
        <Button sx={{ mt: 1 }} variant="contained" onClick={hideDialog}>
          Close
        </Button>
      )}
      {ticketDetails.claimed && !undoSuccess && (
        <Button
          sx={{ mt: 1 }}
          variant="contained"
          onClick={() => undoCheckInAttendee(ticketDetails.id)}
        >
          Undo Check-In Attendee
        </Button>
      )}
    </Grid>
  );
};

export default TicketDetails;
