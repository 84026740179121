import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography } from '@mui/material';

import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { LoadingButton } from '@mui/lab';

import { TextFormField, SelectFormField } from 'components/formFields';

import stateList from 'data/states';

import { useCallService } from 'hooks';

import { SaveIcon } from 'material-icons';

import resources from '../resources';

export type ProfileFormInputs = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

export const PROFILE_FORM_INITIAL_STATE: ProfileFormInputs = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  streetAddress: '',
  city: '',
  state: '',
  postalCode: '',
};

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(2, 'First name must be at least two letters')
    .required('A first name is required'),
  lastName: yup
    .string()
    .min(2, 'Last name must be at least two letters')
    .required('A last name is required'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('An email is required'),
  streetAddress: yup.string(),
  city: yup.string(),
  state: yup.string(),
  postalCode: yup.string(),
});

const Profile = ({ defaultValues }: { defaultValues: ProfileFormInputs }) => {
  const theme = useTheme();

  const { callService } = useCallService();

  const onSubmit = async (values: ProfileFormInputs) => {
    const { response } = await callService({
      resource: resources.updateUserProfile(values),
      successMessage: 'Profile updated successfully!',
    });

    if (response) {
      console.log('Good job!');
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ProfileFormInputs>({
    defaultValues: {
      id: defaultValues.id,
      firstName: defaultValues.firstName,
      lastName: defaultValues.lastName,
      email: defaultValues.email,
      streetAddress: defaultValues.streetAddress,
      city: defaultValues.city,
      state: defaultValues.state,
      postalCode: defaultValues.postalCode,
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item sm={6} md={8}>
        <Typography variant="h4">Edit Account Details</Typography>
        <Divider
          sx={{
            opacity: 0.5,
            m: 1,
            borderColor: theme.palette.primary.light,
          }}
        />
        <Grid container sx={{ p: 2.5 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextFormField
                  margin="dense"
                  name="firstName"
                  control={control}
                  label="First Name"
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFormField
                  margin="dense"
                  name="lastName"
                  control={control}
                  label="Last Name"
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFormField
                  margin="dense"
                  name="email"
                  control={control}
                  label="Email"
                  icon={<MailOutlinedIcon />}
                  position="start"
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFormField
                  margin="dense"
                  name="streetAddress"
                  control={control}
                  label="Address"
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFormField
                  margin="dense"
                  name="city"
                  control={control}
                  label="City"
                />
              </Grid>
              <Grid item xs={7}>
                <SelectFormField
                  margin="dense"
                  name="state"
                  control={control}
                  label="State"
                  options={stateList}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={5}>
                <TextFormField
                  margin="dense"
                  name="postalCode"
                  control={control}
                  label="Postal/Zip Code"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Save
              </LoadingButton>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
