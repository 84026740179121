import { CheckoutFees, CheckoutItemState } from 'states/checkoutState';

import { formatCurrency } from './helpers';
import { calculateItemFees } from './transactions';

export function isSoldOut(remaining: number): boolean {
  return Number(remaining) < 1;
}

export function salesEnded(salesEnd: Date): boolean {
  const now = new Date().getTime();
  const then = new Date(salesEnd).getTime();

  return now > then;
}

export function salesStarted(salesStart: Date): boolean {
  const now = new Date().getTime();
  const then = new Date(salesStart).getTime();

  const diff = now - then;

  return diff > 0;
}

export function isActive(ticket: CheckoutItemState): boolean {
  if (ticket.quantityRemaining) {
    return (
      salesStarted(ticket.saleStartDate) &&
      !isSoldOut(ticket.quantityRemaining) &&
      !salesEnded(ticket.saleEndDate)
    );
  }
  return salesStarted(ticket.saleStartDate) && !salesEnded(ticket.saleEndDate);
}

export function generateMinMaxLimits(ticket: CheckoutItemState) {
  let limit = Number(ticket.maxPerOrder) || 10;

  if (ticket.quantityRemaining && ticket.quantityRemaining > 0) {
    limit =
      ticket.quantityRemaining > ticket.maxPerOrder
        ? ticket.maxPerOrder
        : ticket.quantityRemaining;
  } else {
    limit = 0;
  }

  const quantity = [...Array(limit + 1).keys()];

  const list = quantity.map((item: number) => ({ value: item, key: item }));

  return list;
}

// generate price/fees
export function generatePriceText(ticket: CheckoutItemState) {
  const { price = '0' } = ticket;

  if (ticket.quantityRemaining === 0) {
    return 'SOLD OUT';
  }

  if (salesEnded(ticket.saleEndDate)) return 'SALES ENDED';

  if (Number(ticket.price) === 0) return 'FREE';

  return formatCurrency(price);
}

// generate price/fees
export function generateFeeText(ticket: CheckoutItemState, fees: CheckoutFees) {
  if (
    ticket.quantityRemaining === 0 ||
    salesEnded(ticket.saleEndDate) ||
    Number(ticket.price) === 0
  ) {
    return '';
  }

  if (ticket.feesType === 'pass') {
    const calculatedFees = calculateItemFees(ticket, fees);

    return calculatedFees > 0 ? formatCurrency(calculatedFees) : '';
  }

  return '';
}
