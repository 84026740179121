import { useContext } from 'react';
import AuthContext from '../contexts/AuthContext';

const useAuthState = () => {
  const { authState } = useContext(AuthContext);

  return {
    authState,
  };
};

export default useAuthState;
