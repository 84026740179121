import { useEffect, useState } from 'react';
import fileSaver from 'file-saver';

import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import LoadingButton from 'components/buttons/LoadingButton';

import DisplayText from 'components/DisplayText';
import Loading from 'components/Loading';

import { useCallService } from 'hooks';

import EventProps from 'types/Event';
import TransactionProps from 'types/Transactions';
import TransactionItemProps from 'types/TransactionItems';

import { DownloadIcon } from 'material-icons';
import { REACT_APP_CLOUDFRONT_URL } from 'config/config';

import httpRoutes from 'utils/httpRoutes';
import { formatCurrency } from 'utils/helpers';
import {
  formatDate,
  formatDateTime,
  formatEventStartDate,
} from 'utils/formatDateTime';

// export interface OrderDetailsProps extends Partial<TransactionProps> {
//   id: number;
//   subtotal: string;
//   itemFee: string;
//   serviceFee: string;
//   processingFee: string;
//   total: string;
//   date: Date;
//   status: string;
//   event: EventProps;
//   transactionItem: Partial<TransactionItemProps>[];
// }

// export const INITIAL_STATE: OrderDetailsProps = {
//   id: 0,
//   subtotal: '',
//   itemFee: '',
//   serviceFee: '',
//   processingFee: '',
//   total: '',
//   date: new Date(),
//   status: '',
//   transactionItem: [],
//   event: {
//     id: 0,
//     name: '',
//     imageUrl: '',
//   },
// };

type LoadingType = {
  data: boolean;
  downloading: boolean;
};
const loadingState: LoadingType = { data: true, downloading: false };

const EventPayout = ({ eventId }: { eventId: number }) => {
  const [loading, setLoading] = useState<LoadingType>(loadingState);
  const [details, setDetails] = useState<any>({});

  const { callService } = useCallService();

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    const getEventPayout = async (eventId: number) => {
      const { response } = await callService({
        resource: httpRoutes.admin.getEventPayout(eventId),
      });

      if (response) {
        setDetails(response);
        setLoading((prevState) => ({ ...prevState, data: false }));
      }
    };

    getEventPayout(eventId);
  }, [eventId]);

  if (loading.data) return <Loading />;

  return (
    <Container>
      <Card sx={{ m: 2, p: 2 }} elevation={1}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="subtitle2">Settlement Period:</Typography>
            <Typography variant="subtitle1">{details.dateRange}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <Typography variant="subtitle2">Settlement #:</Typography>
            <Typography variant="subtitle1">{details.eventId}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ m: 0, p: 0 }}>
          <Typography variant="h2" mb={3}>
            {details.eventName}
          </Typography>

          <Grid container pl={4}>
            <Grid item xs={12}>
              Attendees: {details.attendees}
            </Grid>
            <Grid item xs={12}>
              Sponsors: {details.sponsors}
            </Grid>
          </Grid>
          <Grid container>
            {/* Tickets */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                p: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                flex: '0.34 0 auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '20px',
                    lineHeight: '130%',
                    color: '#56C596',
                  }}
                >
                  TICKET SALES
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    fontFamily: 'RobotoSlab',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '130%',
                    color: '#445D74',
                  }}
                >
                  by ticket type
                </Box>
              </Box>

              <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                  Name
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'end' }}>
                  Price
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'end' }}>
                  Quantity
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'end' }}>
                  Gross Revenue
                </Grid>
              </Grid>
              <Divider />
              {details.ticketTypes.map((type: any) => {
                return (
                  <Grid
                    container
                    justifyContent="space-between"
                    key={type.name}
                  >
                    {type.name === 'TOTAL' && <Divider />}

                    {type.name === 'TOTAL' && (
                      <Grid item xs={7}>
                        {type.name}
                      </Grid>
                    )}
                    {type.name !== 'TOTAL' && (
                      <>
                        <Grid item xs={4}>
                          {type.name}
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'end' }}>
                          {formatCurrency(type.price)}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={2} sx={{ textAlign: 'end' }}>
                      {type.quantity}
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'end' }}>
                      {formatCurrency(type.grossTotal)}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            {/* Sponsorships */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                p: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                flex: '0.34 0 auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '20px',
                    lineHeight: '130%',
                    color: '#56C596',
                  }}
                >
                  SPONSORSHIP SALES
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    fontFamily: 'RobotoSlab',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '130%',
                    color: '#445D74',
                  }}
                >
                  by sponsorship type
                </Box>
              </Box>
              <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                  Name
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'end' }}>
                  Price
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'end' }}>
                  Quantity
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'end' }}>
                  Gross Revenue
                </Grid>
              </Grid>
              <Divider />
              {details.sponsorshipTypes.map((type: any) => {
                return (
                  <Grid
                    container
                    justifyContent="space-between"
                    key={type.name}
                  >
                    {type.name === 'TOTAL' && <Divider />}

                    {type.name === 'TOTAL' && (
                      <Grid item xs={7}>
                        {type.name}
                      </Grid>
                    )}
                    {type.name !== 'TOTAL' && (
                      <>
                        <Grid item xs={4}>
                          {type.name}
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'end' }}>
                          {formatCurrency(type.price)}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={2} sx={{ textAlign: 'end' }}>
                      {type.quantity}
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'end' }}>
                      {formatCurrency(type.grossTotal)}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {/* Fees/Refunds Overview */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                p: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                flex: '0.34 0 auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '20px',
                    lineHeight: '130%',
                    color: '#56C596',
                  }}
                >
                  DISCOUNT SUMMARY
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    fontFamily: 'RobotoSlab',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '130%',
                    color: '#445D74',
                  }}
                >
                  by discount code
                </Box>
              </Box>
              <Grid container justifyContent="space-between" mb={0.5}>
                <Grid item xs={4}>
                  Discount Code
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                  Times Used
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                  Total Discounted
                </Grid>
              </Grid>
              <Divider />
              {details.discountSummary.map((type: any) => {
                return (
                  <Grid
                    container
                    justifyContent="space-between"
                    key={type.name}
                  >
                    {type.name === 'TOTAL' && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}

                    {type.name === 'TOTAL' && (
                      <Grid item xs={7}>
                        {type.name}
                      </Grid>
                    )}
                    {type.name !== 'TOTAL' && (
                      <>
                        <Grid item xs={4}>
                          {type.name}
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'end' }}>
                          {type.timesUsed}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4} sx={{ textAlign: 'end' }}>
                      {formatCurrency(type.totalDiscounted)}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {/* Fees/Refunds Overview */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                p: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                flex: '0.34 0 auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box>
                <Box
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '700',
                    fontSize: '20px',
                    lineHeight: '130%',
                    color: '#56C596',
                  }}
                >
                  FEE SUMMARY
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    fontFamily: 'RobotoSlab',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '130%',
                    color: '#445D74',
                  }}
                >
                  by fee type
                </Box>
              </Box>
              <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                  Type
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'end' }}>
                  Amount
                </Grid>
              </Grid>
              <Divider />
              {details.feeSummary.map((type: any) => {
                if (type.total > 0) {
                  return (
                    <Grid
                      container
                      justifyContent="space-between"
                      key={type.name}
                    >
                      <Grid item xs={6}>
                        {type.name}
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: 'end' }}>
                        {formatCurrency(type.total)}
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </Grid>
            {/* Sales Overview */}
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  p: 4,
                  borderRadius: '15px',
                  backgroundColor: 'white',
                  flex: '0.34 0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      fontFamily: 'Raleway',
                      fontWeight: '700',
                      fontSize: '20px',
                      lineHeight: '130%',
                      color: '#56C596',
                    }}
                  >
                    SALES SUMMARY
                  </Box>
                </Box>
                <Divider />
                {details.formattedSalesReport.map((type: any) => (
                  <Grid
                    container
                    justifyContent="space-between"
                    key={type.name}
                  >
                    <Grid item xs={6}>
                      {type.name}
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'end' }}>
                      {formatCurrency(type.total)}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default EventPayout;
