import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

const EventSponsorsGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [sponsorshipTypes, setSponsorshipTypes] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const params = useParams();
  const { id = 0 } = params;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getEventSponsors();
  }, [filters, state]);

  useEffect(() => {
    const getTicketTypes = async () => {
      if (id !== 0) {
        const { response } = await callService({
          resource: httpRoutes.organizer.getAllSponsorshipTypes(Number(id)),
        });

        if (response) {
          const defaultOption = {
            label: 'Any Type',
            value: '',
          };

          const _sponsorshipTypes = response.map(
            (item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            }
          );

          _sponsorshipTypes.unshift(defaultOption);

          setSponsorshipTypes(_sponsorshipTypes);
        }
      }
    };

    getTicketTypes();
  }, []);

  const getEventSponsors = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.getEventSponsors({
          eventId: Number(id),
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  const exportEventSponsors = async () => {
    setLoading((prevState) => ({ ...prevState, exporting: true }));
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.exportEventSponsors({
          eventId: Number(id),
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Event Sponsors.csv');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name',
    },
    {
      id: 'email',
      type: 'text',
      label: 'Email',
    },
    {
      id: 'sponsorshipTypeId',
      type: 'select',
      label: 'Sponsorship Type',
      options: sponsorshipTypes,
    },
  ];

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Ticket Number',
      field: 'id',
      sortable: false,
      filterable: false,
      flex: 0.1,
    },
    {
      headerName: 'Name',
      field: 'userName',
      sortable: true,
      filterable: true,
      flex: 0.15,
    },
    {
      headerName: 'Email',
      field: 'userEmail',
      sortable: true,
      filterable: true,
      flex: 0.15,
    },
    {
      headerName: 'Ticket Type',
      field: 'ticketName',
      sortable: true,
      filterable: true,
      type: 'string',
      flex: 0.2,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      filterable: true,
      type: 'string',
    },
    {
      headerName: 'Claimed',
      field: 'claimed',
      sortable: true,
      filterable: true,
      type: 'boolean',
    },
    {
      headerName: 'Sponsor',
      field: 'mobile',
      hide: !matchDownSM,
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <Grid
            container
            height="100%"
            m={0.5}
            p={1}
            justifyContent="space-between"
            flexDirection="row"
            spacing={1}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Name</Typography>
              <Typography fontWeight="bold">{params.row.userName}</Typography>
              <Typography>{params.row.userEmail}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Ticket Type</Typography>
              <Typography>{params.row.ticketName}</Typography>
              <Typography>Ticket # {params.row.id}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <PageContainer maxWidth="xl">
      <DataGrid
        tableTitle="Event Sponsors"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
        handleExport={exportEventSponsors}
      />
    </PageContainer>
  );
};

const EventSponsors = () => (
  <ControlledDataGrid>
    <EventSponsorsGrid />
  </ControlledDataGrid>
);

export default EventSponsors;
