import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InActivePlantIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} color="inherit">
      <path d="M22 15.7549L20.9014 16.2064C20.8155 16.2417 18.9041 17.0132 16.4203 16.9193C16.9989 17.5794 17.3914 18.1806 17.5924 18.7164L17.975 19.7361L16.8637 19.5754C16.7415 19.5576 14.3217 19.1941 12.3346 17.944V23H10.9957V17.9572C8.95258 19.2721 6.56688 19.564 6.44723 19.5779L5.36759 19.7032L5.73785 18.7164C5.93889 18.1806 6.33123 17.5794 6.90983 16.9194C4.44744 17.0127 2.88903 16.2166 2.81929 16.1804L2 15.7529L2.68086 15.1396C2.7466 15.0803 4.3257 13.6842 7.134 13.4319C7.21069 13.425 7.28686 13.4185 7.36269 13.4124C6.86479 13.0182 6.35419 12.5695 5.84289 12.076C3.33041 9.65115 2.65321 6.70192 2.62555 6.57755L2.40886 5.60118L3.4205 5.81032C3.54936 5.83684 6.60514 6.4906 9.11762 8.91548C9.14596 8.94284 9.17414 8.97003 9.20214 8.99739C9.19709 8.79463 9.19448 8.58902 9.19448 8.38123C9.19448 4.54996 11.0781 1.87935 11.1581 1.76756L11.7087 1L12.2591 1.76756C12.3393 1.87935 14.2229 4.54996 14.2229 8.38123C14.2229 8.58902 14.2203 8.79446 14.2151 8.99739L14.2998 8.91548C16.8123 6.4906 19.868 5.83684 19.9969 5.81032L21.0085 5.60118L20.7918 6.57738C20.7642 6.70175 20.0868 9.65115 17.5743 12.076C17.0604 12.5722 16.5471 13.0229 16.0465 13.4188L16.1964 13.4317C18.9574 13.6798 20.9445 15.0285 21.0278 15.0859L22 15.7549Z" />
    </SvgIcon>
  );
};

export default InActivePlantIcon;
