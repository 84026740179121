import { Grid, Typography, useTheme } from '@mui/material';

import FeaturesHeaderImage from 'assets/images/headers/header-features-pricing.png';
import ClientTypesImage from 'assets/images/pages/features/EventHi-Client-Types.png';
import CreatorFeaturesImage from 'assets/images/pages/features/icon-creator-features.png';

const Features = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          display: 'flex',
          [theme.breakpoints.only('xs')]: {
            height: '22vh',
            width: '100%',
          },
          [theme.breakpoints.only('sm')]: {
            height: '30vh',
            width: '100%',
          },
          [theme.breakpoints.only('md')]: {
            height: '50vh',
            width: '100%',
          },
          [theme.breakpoints.only('lg')]: {
            height: '70vh',
            width: '100%',
          },
          [theme.breakpoints.up('lg')]: {
            height: '70vh',
            width: '100%',
          },
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          zIndex: 0,
          backgroundImage: `url(${FeaturesHeaderImage})`,
        }}
      ></Grid>
      <Grid item sx={{ height: 'inherit' }}>
        <Typography
          sx={{
            margin: 0,
            color: 'white',
            lineHeight: '5rem',
            pt: '28vh',
            pb: '1vh',
            fontFamily: 'Myriad Pro',
            fontWeight: 'bold',
            fontSize: '80px',
            textShadow: '3px 3px 3px  rgba(0,0,0,.5)',
            textAlign: 'center',
          }}
        >
          Features & Pricing
        </Typography>
        <Typography
          sx={{
            margin: 0,
            color: 'white',
            lineHeight: '2rem',
            pb: '15vh',
            fontFamily: 'Myriad Pro',
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          Our marketplace allows everyone to experience or create
          <br />
          in a compliant, accessible, and authentic way
        </Typography>
      </Grid>
      {/* Client Types */}
      <Grid container flexDirection="column">
        <Grid item xs={4}>
          <img src={ClientTypesImage} alt="EventHi-Client-Types" />
        </Grid>
      </Grid>
      {/* Icon List */}
      <Grid
        container
        sx={{
          // position: 'absolute',
          left: 0,
          height: '475px',
          width: '100%',
          backgroundSize: 'cover',
        }}
      >
        <Grid
          container
          maxWidth="lg"
          alignContent="center"
          margin="auto"
        ></Grid>
      </Grid>
      <Grid
        container
        pt={4}
        sx={{ height: '500px', width: '75%', border: '1px solid blue' }}
        justifyContent="center"
        alignContent="center"
      >
        <Grid item xs={2}>
          <img src={CreatorFeaturesImage} alt="Event Creator Features" />
        </Grid>
        <Grid item xs={10}>
          <Typography
            sx={{
              color: 'purple',
              fontFamily: 'Myriad Pro',
              fontStyle: 'italic',
              fontWeight: 'bold',
              fontSize: '48px',
              textAlign: 'center',
            }}
          >
            Features For Event Creators
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Features;
