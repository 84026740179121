import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

interface DatePickerProps {
  label: string;
  onChange: any;
  value?: Date | null;
}

const DatePicker = ({ label, onChange, value, ...rest }: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        label={label}
        value={value}
        onChange={(newValue: React.SetStateAction<Date | null>) => {
          onChange(newValue);
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField fullWidth {...params} />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;

// import { DatePicker } from '@material-ui/pickers';
// import { useField, useFormikContext } from 'formik';

// export const DatePickerField = ({ label, ...props }) => {
//   const [field, meta] = useField(props);
//   const { setFieldValue } = useFormikContext();

//   return (
//     <DatePicker
//       fullWidth
//       {...props}
//       label={label}
//       name={field.name}
//       value={field.value}
//       helperText={meta.error}
//       error={meta.touched && Boolean(meta.error)}
//       onChange={(value) => setFieldValue(field.name, value)}
//     />
//   );
// };
