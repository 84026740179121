import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import PageContainer from 'components/PageContainer';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { EventIcon, TicketStubIcon, TicketTypeIcon } from 'material-icons';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

const UsersGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getUsers();
  }, [state, filters]);

  const getUsers = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    try {
      const { response } = await callService({
        resource: httpRoutes.admin.getUsers({
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Search',
    },
  ];

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'User ID',
      field: 'id',
      sortable: false,
      filterable: false,
      hide: matchDownSM,
    },
    {
      headerName: 'First name',
      field: 'firstName',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      flex: 0.1,
    },
    {
      headerName: 'Last name',
      field: 'lastName',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      flex: 0.1,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      flex: 0.1,
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      headerAlign: 'center',
      hide: matchDownSM,
      align: 'center',
      flex: 0.2,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton
              onClick={() =>
                navigate(`/admin/users/${params.row.id}/purchases`)
              }
            >
              <TicketStubIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate(`/admin/users/${params.row.id}/events`)}
            >
              <EventIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <PageContainer maxWidth="xl">
      <DataGrid
        tableTitle="Users"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
      />
    </PageContainer>
  );
};

const Users = () => {
  const { showDialog } = useDialogDispatcher();

  return (
    <ControlledDataGrid>
      <UsersGrid />
    </ControlledDataGrid>
  );
};

export default Users;
