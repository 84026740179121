import React from 'react';

import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { InputAdornment, TextField } from '@mui/material';

import Envelope from '@mui/icons-material/MailOutline';

const EndAdornment = () => (
  <InputAdornment position="end">
    <Envelope />
  </InputAdornment>
);

export type EmailFieldProps = {
  label: string;
  placeholder?: string;
  margin?: 'normal' | 'dense' | 'none';
  required?: boolean;
};

export type EmailFormFieldProps<T extends FieldValues> = EmailFieldProps &
  UseControllerProps<T>;

const EmailFormField = <T extends FieldValues>(
  props: EmailFormFieldProps<T>
) => {
  const {
    label,
    name,
    control,
    margin = 'normal',
    placeholder,
    required,
  } = props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          type="email"
          variant="outlined"
          label={label}
          fullWidth
          margin={margin}
          error={Boolean(errors[name])}
          placeholder={placeholder}
          required={required}
          helperText={
            errors[name] && errors[name]?.message ? (
              <span color="error">{errors[name]?.message?.toString()}</span>
            ) : (
              ' '
            )
          }
          InputProps={{
            endAdornment: <EndAdornment />,
          }}
        />
      )}
    />
  );
};

// const EmailFormField = ({
//   name,
//   label,
//   control,
//   errors,
//   margin = 'normal',
// }: EmailFormFieldProps) => (
//   <Controller
//     name={name}
//     control={control}
//     render={({ field }) => (
//       <TextField
//         {...field}
//         type="email"
//         variant="outlined"
//         label={label}
//         fullWidth
//         margin={margin}
//         error={Boolean(errors)}
//         helperText={errors ? <span color="error">{errors.message}</span> : ' '}
//         InputProps={{
//           endAdornment: <EndAdornment />,
//         }}
//       />
//     )}
//   />
// );

export default EmailFormField;
