import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import PageContainer from 'components/PageContainer';

import RefundPolicy from 'markdown/RefundPolicy.md';

const RefundPolicyPage = () => {
  const [text, setText] = useState<string>('');

  useEffect(() => {
    fetch(RefundPolicy)
      .then((response) => {
        return response.text();
      })
      .then((text) => setText(text));
  }, []);

  return (
    <PageContainer maxWidth="lg" title="Refund Policy">
      <ReactMarkdown>{text}</ReactMarkdown>
    </PageContainer>
  );
};

export default RefundPolicyPage;
