const timezones = [
  {
    label: '(GMT-11:00) Pacific/Midway',
    value: 'Pacific/Midway',
  },
  {
    label: '(GMT-10:00) America/Adak',
    value: 'America/Adak',
  },
  {
    label: '(GMT-09:00) America/Anchorage',
    value: 'America/Anchorage',
  },
  {
    label: '(GMT-09:00) Pacific/Gambier',
    value: 'Pacific/Gambier',
  },
  {
    label: '(GMT-08:00) America/Dawson_Creek',
    value: 'America/Dawson_Creek',
  },
  {
    label: '(GMT-08:00) America/Ensenada',
    value: 'America/Ensenada',
  },
  {
    label: '(GMT-08:00) America/Los_Angeles',
    value: 'America/Los_Angeles',
  },
  {
    label: '(GMT-07:00) America/Mexico_City',
    value: 'America/Mexico_City',
  },
  {
    label: '(GMT-07:00) America/Denver',
    value: 'America/Denver',
  },
  {
    label: '(GMT-06:00) America/Cancun',
    value: 'America/Cancun',
  },
  {
    label: '(GMT-06:00) America/Chicago',
    value: 'America/Chicago',
  },
  {
    label: '(GMT-05:00) America/New_York',
    value: 'America/New_York',
  },
  {
    label: '(GMT-04:00) America/Santiago',
    value: 'America/Santiago',
  },
  {
    label: '(GMT-03:00) America/Argentina/Buenos_Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    label: '(GMT-03:00) America/Montevideo',
    value: 'America/Montevideo',
  },
  {
    label: '(GMT-03:00) America/Araguaina',
    value: 'America/Araguaina',
  },
  {
    label: '(GMT-03:00) America/Godthab',
    value: 'America/Godthab',
  },
  {
    label: '(GMT-03:00) America/Miquelon',
    value: 'America/Miquelon',
  },
  {
    label: '(GMT-03:00) America/Sao_Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    label: '(GMT-03:30) America/St_Johns',
    value: 'America/St_Johns',
  },
  {
    label: '(GMT-02:00) America/Noronha',
    value: 'America/Noronha',
  },
  {
    label: '(GMT-01:00) Atlantic/Cape_Verde',
    value: 'Atlantic/Cape_Verde',
  },
  {
    label: '(GMT) Europe/Belfast',
    value: 'Europe/Belfast',
  },
  {
    label: '(GMT) Africa/Abidjan',
    value: 'Africa/Abidjan',
  },
  {
    label: '(GMT) Europe/Dublin',
    value: 'Europe/Dublin',
  },
  {
    label: '(GMT) Europe/Lisbon',
    value: 'Europe/Lisbon',
  },
  {
    label: '(GMT) Europe/London',
    value: 'Europe/London',
  },
  {
    label: '(GMT+01:00) Africa/Algiers',
    value: 'Africa/Algiers',
  },
  {
    label: '(GMT+01:00) Africa/Windhoek',
    value: 'Africa/Windhoek',
  },
  {
    label: '(GMT+01:00) Atlantic/Azores',
    value: 'Atlantic/Azores',
  },
  {
    label: '(GMT+01:00) Atlantic/Stanley',
    value: 'Atlantic/Stanley',
  },
  {
    label: '(GMT+01:00) Europe/Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    label: '(GMT+01:00) Europe/Belgrade',
    value: 'Europe/Belgrade',
  },
  {
    label: '(GMT+01:00) Europe/Brussels',
    value: 'Europe/Brussels',
  },
  {
    label: '(GMT+02:00) Africa/Cairo',
    value: 'Africa/Cairo',
  },
  {
    label: '(GMT+02:00) Africa/Blantyre',
    value: 'Africa/Blantyre',
  },
  {
    label: '(GMT+02:00) Asia/Beirut',
    value: 'Asia/Beirut',
  },
  {
    label: '(GMT+02:00) Asia/Damascus',
    value: 'Asia/Damascus',
  },
  {
    label: '(GMT+02:00) Asia/Gaza',
    value: 'Asia/Gaza',
  },
  {
    label: '(GMT+02:00) Asia/Jerusalem',
    value: 'Asia/Jerusalem',
  },
  {
    label: '(GMT+03:00) Africa/Addis_Ababa',
    value: 'Africa/Addis_Ababa',
  },
  {
    label: '(GMT+03:00) Asia/Riyadh89',
    value: 'Asia/Riyadh89',
  },
  {
    label: '(GMT+03:00) Europe/Minsk',
    value: 'Europe/Minsk',
  },
  {
    label: '(GMT+03:30) Asia/Tehran',
    value: 'Asia/Tehran',
  },
  {
    label: '(GMT+04:00) Asia/Dubai',
    value: 'Asia/Dubai',
  },
  {
    label: '(GMT+04:00) Asia/Yerevan',
    value: 'Asia/Yerevan',
  },
  {
    label: '(GMT+04:00) Europe/Moscow',
    value: 'Europe/Moscow',
  },
  {
    label: '(GMT+04:30) Asia/Kabul',
    value: 'Asia/Kabul',
  },
  {
    label: '(GMT+05:00) Asia/Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    label: '(GMT+05:30) Asia/Kolkata',
    value: 'Asia/Kolkata',
  },
  {
    label: '(GMT+05:45) Asia/Katmandu',
    value: 'Asia/Katmandu',
  },
  {
    label: '(GMT+06:00) Asia/Dhaka',
    value: 'Asia/Dhaka',
  },
  {
    label: '(GMT+06:00) Asia/Yekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  {
    label: '(GMT+06:30) Asia/Rangoon',
    value: 'Asia/Rangoon',
  },
  {
    label: '(GMT+07:00) Asia/Bangkok',
    value: 'Asia/Bangkok',
  },
  {
    label: '(GMT+07:00) Asia/Novosibirsk',
    value: 'Asia/Novosibirsk',
  },
  {
    label: '(GMT+08:00) Etc/GMT+8',
    value: 'Etc/GMT+8',
  },
  {
    label: '(GMT+08:00) Asia/Hong_Kong',
    value: 'Asia/Hong_Kong',
  },
  {
    label: '(GMT+08:00) Asia/Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
  },
  {
    label: '(GMT+08:00) Australia/Perth',
    value: 'Australia/Perth',
  },
  {
    label: '(GMT+08:45) Australia/Eucla',
    value: 'Australia/Eucla',
  },
  {
    label: '(GMT+09:00) Asia/Irkutsk',
    value: 'Asia/Irkutsk',
  },
  {
    label: '(GMT+09:00) Asia/Seoul',
    value: 'Asia/Seoul',
  },
  {
    label: '(GMT+09:00) Asia/Tokyo',
    value: 'Asia/Tokyo',
  },
  {
    label: '(GMT+09:30) Australia/Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: '(GMT+09:30) Australia/Darwin',
    value: 'Australia/Darwin',
  },
  {
    label: '(GMT+09:30) Pacific/Marquesas',
    value: 'Pacific/Marquesas',
  },
  {
    label: '(GMT+10:00) Etc/GMT+10',
    value: 'Etc/GMT+10',
  },
  {
    label: '(GMT+10:00) Australia/Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    label: '(GMT+10:00) Australia/Hobart',
    value: 'Australia/Hobart',
  },
  {
    label: '(GMT+10:00) Asia/Yakutsk',
    value: 'Asia/Yakutsk',
  },
  {
    label: '(GMT+10:30) Australia/Lord_Howe',
    value: 'Australia/Lord_Howe',
  },
  {
    label: '(GMT+11:00) Asia/Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    label: '(GMT+11:30) Pacific/Norfolk',
    value: 'Pacific/Norfolk',
  },
  {
    label: '(GMT+12:00) Etc/GMT+12',
    value: 'Etc/GMT+12',
  },
  {
    label: '(GMT+12:00) Asia/Anadyr',
    value: 'Asia/Anadyr',
  },
  {
    label: '(GMT+12:00) Asia/Magadan',
    value: 'Asia/Magadan',
  },
  {
    label: '(GMT+12:00) Pacific/Auckland',
    value: 'Pacific/Auckland',
  },
  {
    label: '(GMT+12:45) Pacific/Chatham',
    value: 'Pacific/Chatham',
  },
  {
    label: '(GMT+13:00) Pacific/Tongatapu',
    value: 'Pacific/Tongatapu',
  },
  {
    label: '(GMT+14:00) Pacific/Kiritimati',
    value: 'Pacific/Kiritimati',
  },
];

export default timezones;
