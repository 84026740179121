import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCallService } from 'hooks';

import { IEvent } from 'layout/OrganizerEventLayout';

import TicketTypeForm, {
  ITicketTypeFormInputs,
} from 'features/ticketTypes/forms/TicketTypeForm';

import httpRoutes from 'utils/httpRoutes';

type EditTicketTypeProps = {
  eventId: number;
  eventDetails: IEvent;
  ticketId: number;
  onSuccess: () => void;
  hideDialog: () => void;
};

const EditTicketType = ({
  eventId,
  eventDetails,
  ticketId,
  onSuccess,
  hideDialog,
}: EditTicketTypeProps) => {
  const [ticket, setTicket] = useState<ITicketTypeFormInputs>();
  const { callService } = useCallService();
  const navigate = useNavigate();

  const editTicketType = async (
    values: ITicketTypeFormInputs,
    dashboard?: boolean
  ) => {
    const { response } = await callService({
      resource: httpRoutes.ticketTypes.updateTicketType(values),
    });

    if (response) {
      hideDialog();
      if (!dashboard) {
        onSuccess();
        navigate(`/organizer/events/${eventId}/tickets`);
      } else {
        navigate(`/organizer/events/${eventId}/dashboard`);
      }
    }
  };

  useEffect(() => {
    const getTicketDetails = async () => {
      const { response } = await callService({
        resource: httpRoutes.ticketTypes.getTicketTypeDetails(ticketId),
      });

      if (response) {
        setTicket(response);
      }
    };

    getTicketDetails();
  }, [ticketId]);

  if (!ticket) {
    return <>Loading...</>;
  }

  return (
    <TicketTypeForm
      eventId={eventId}
      eventDetails={eventDetails}
      ticket={ticket}
      hideDialog={hideDialog}
      onSubmit={editTicketType}
    />
  );
};

export default EditTicketType;
