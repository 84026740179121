import * as React from 'react';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';

export default function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      mt={3}
      mb={3}
    >
      {'Copyright © '}
      {new Date().getFullYear()}.{' '}
      <MuiLink
        color="inherit"
        href="https://eventhi.io"
        sx={{ textDecoration: 'none' }}
      >
        EventHi Inc.
      </MuiLink>
      {'  All rights reserved.'}
    </Typography>
  );
}
