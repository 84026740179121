/* eslint-disable react/prop-types */
import { ReactNode, useEffect, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Loading from 'components/Loading';
import PageContainer from 'components/PageContainer';
import TotalSales from 'components/charts/TotalSales';

import { useCallService } from 'hooks';

import {
  DoneIcon,
  EventIcon,
  EventTicketIcon,
  PublishEventIcon,
  SponsorIcon,
} from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import TicketSales from '../components/TicketSales';
import SponsorshipSales from '../components/SponsorshipSales';

import httpRoutes from 'utils/httpRoutes';
import PublishPrompt from '../dialogs/PublishPrompt';
import Transactions from './Transactions';

interface IEventChecklist {
  ticketsCreated: boolean;
  sponsorshipsCreated: boolean;
  status: string;
}

const EventChecklist = ({
  props,
  checklist,
  updateStatus,
}: {
  props: any;
  checklist: IEventChecklist;
  updateStatus: () => void;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card
      sx={{
        p: { xs: 1, sm: 2, md: 3, lg: 4 },
        borderRadius: '15px',
        height: '100%',
      }}
      elevation={2}
    >
      <Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          EVENT CHECKLIST
        </Box>
        <Box
          sx={{
            fontFamily: 'RobotoSlab',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          your to-do list
        </Box>
      </Box>
      <Grid container flexDirection="row">
        <Grid item xs={12} sm={12}>
          {/* Next steps */}
          {/* Event Checklist */}
          <List>
            {/* Create Event */}
            <ListItem
              sx={{
                // backgroundColor: 'rgb(210, 246, 231)',
                borderRadius: '8px',
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: 'white',
                  }}
                >
                  <EventIcon sx={{ color: 'inherit' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography noWrap>Create event</Typography>}
                secondary={
                  <Typography variant="subtitle2">
                    Add details, images, and more!
                  </Typography>
                }
              />
              <Avatar
                sx={{
                  color: '#56C596',
                  backgroundColor: 'white',
                }}
              >
                <DoneIcon sx={{ color: 'inherit' }} />
              </Avatar>
              <Button
                variant="text"
                onClick={() =>
                  navigate(`/organizer/events/${props.id}/general-info`)
                }
              >
                View
              </Button>
            </ListItem>
            <Divider />
            {/* Create Tickets */}
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',

                    backgroundColor: 'white',
                  }}
                >
                  <EventTicketIcon sx={{ color: 'inherit' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Create event tickets"
                secondary={
                  <Typography variant="subtitle2">
                    Create custom ticket types
                  </Typography>
                }
              />
              {checklist.ticketsCreated && (
                <Avatar
                  sx={{
                    color: '#56C596',

                    backgroundColor: 'white',
                  }}
                >
                  <DoneIcon sx={{ color: 'inherit' }} />
                </Avatar>
              )}

              <Button
                variant="text"
                onClick={() =>
                  navigate(`/organizer/events/${props.id}/tickets`)
                }
              >
                {!checklist.ticketsCreated ? 'Create' : 'View'}
              </Button>
            </ListItem>
            <Divider />
          </List>
        </Grid>
        <Grid item xs={12} sm={12}>
          <List>
            {/* Create Sponsorships */}
            <ListItem
              sx={{
                borderRadius: '8px',
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: 'white',
                  }}
                >
                  <SponsorIcon sx={{ color: 'inherit' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Create event sponsorships"
                secondary={
                  <Typography variant="subtitle2">
                    Sell promotions and sponsorships to local brands
                  </Typography>
                }
              />
              {checklist.sponsorshipsCreated && (
                <Avatar
                  sx={{
                    color: '#56C596',

                    backgroundColor: 'white',
                  }}
                >
                  <DoneIcon sx={{ color: 'inherit' }} />
                </Avatar>
              )}
              <Button
                variant="text"
                onClick={() =>
                  navigate(`/organizer/events/${props.id}/sponsorships`)
                }
              >
                {checklist.sponsorshipsCreated ? 'View' : 'Create'}
              </Button>
            </ListItem>

            <Divider />
            {/* Publish Event */}
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border: '1px solid #00AEEF',
                    color: '#00AEEF',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <PublishEventIcon sx={{ color: 'inherit' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Publish your event"
                secondary={
                  <Typography variant="subtitle2">
                    {checklist.ticketsCreated
                      ? 'Once your event is published, it will be open to the world'
                      : 'NOTE: You need at least one ticket type to publish your event.'}
                  </Typography>
                }
              />
              {checklist.status === 'Published' && (
                <Avatar
                  sx={{
                    // border: '2px solid #56C596',
                    color: '#56C596',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <DoneIcon sx={{ color: 'inherit' }} />
                </Avatar>
              )}
              <Button
                variant="text"
                onClick={updateStatus}
                disabled={!checklist.ticketsCreated}
              >
                {props.status === 'Published' ? 'Undo' : 'Publish'}
              </Button>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Card>
  );
};

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [props, setData] = useState<any>({});

  const [checklist, setChecklist] = useState<IEventChecklist>({
    ticketsCreated: false,
    sponsorshipsCreated: false,
    status: '',
  });

  const { showDialog, hideDialog } = useDialogDispatcher();
  const [searchParams] = useSearchParams();
  const publishPrompt = searchParams.get('publishPrompt');

  const params = useParams();

  const { id } = params;

  const _eventId = Number(id);

  const { callService } = useCallService();

  const onPublishPrompt = () => {
    setData((prevState: any) => ({
      ...prevState,
      status: 'Published',
    }));

    setChecklist((prevState) => ({ ...prevState, status: 'Published' }));
  };

  useEffect(() => {
    if (publishPrompt && !loading && props.status !== 'Published') {
      showDialog({
        size: 'sm',
        content: (
          <PublishPrompt
            eventDetails={props}
            onSuccess={onPublishPrompt}
            hideDialog={hideDialog}
          />
        ),
      });
    }
  }, [publishPrompt, loading]);

  const updateStatus = async () => {
    if (props.status === 'Published') {
      const { response } = await callService({
        resource: httpRoutes.organizer.updateEventStatus(_eventId, 'Draft'),
        successMessage: 'Event status changed to Draft.',
      });

      if (response) {
        setData((prevState: any) => ({ ...prevState, status: 'Draft' }));
        setChecklist((prevState) => ({ ...prevState, status: 'Draft' }));
      }
    } else {
      const { response } = await callService({
        resource: httpRoutes.organizer.updateEventStatus(_eventId, 'Published'),
        successMessage: 'Event published successfully!',
      });

      if (response) {
        setData((prevState: any) => ({
          ...prevState,
          status: 'Published',
        }));

        setChecklist((prevState) => ({ ...prevState, status: 'Published' }));
      }
    }
  };

  const getData = async () => {
    setLoading(true);

    if (_eventId) {
      const { response } = await callService({
        resource: httpRoutes.organizer.getEvent(_eventId),
      });

      if (response) {
        setData(response.eventDetails);
        setChecklist({
          ticketsCreated: response.eventDetails.eventTicketTypes?.length > 0,
          sponsorshipsCreated:
            response.eventDetails.eventSponsorshipTypes?.length > 0,
          status: response.eventDetails.status,
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    getData();
  }, [_eventId]);

  if (loading) return <Loading />;

  /**
   * totalRevenue
   * totalAttendees
   * totalSponsors
   */
  return (
    <PageContainer>
      <Grid container flexDirection="row">
        {/* Status Headers */}
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} md={6}>
            <EventChecklist
              props={props}
              checklist={checklist}
              updateStatus={updateStatus}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TotalSales eventId={_eventId} />
          </Grid>
        </Grid>
        <Grid container alignItems="stretch" spacing={1} mt={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ height: { md: '100%', sm: '50%' } }}
          >
            <TicketSales eventId={_eventId} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ height: { md: '100%', sm: '50%' } }}
          >
            <SponsorshipSales eventId={_eventId} />
          </Grid>
        </Grid>
        <Grid container mb={2}>
          <Grid item xs={12} sx={{ height: '100%' }}>
            <Card
              elevation={2}
              sx={{
                mt: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                flex: '0.34 0 auto',
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Transactions />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Dashboard;
