import { RouteObject, useRoutes } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';

import { PublicRoutes } from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import AdminRoutes from './AdminRoutes';

const AppRoutes = () => {
  const MainLayoutRoutes: RouteObject = {
    element: <MainLayout />,
    children: PublicRoutes.concat(PrivateRoutes),
  };

  return useRoutes(AdminRoutes.concat([MainLayoutRoutes]));
};

export default AppRoutes;
