import React, { useState } from 'react';

import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { IconButton, InputAdornment, TextField } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface EndAdornmentProps {
  setShowPassword: (showPassword: boolean) => void;
  showPassword: boolean;
}

const EndAdornment = ({ setShowPassword, showPassword }: EndAdornmentProps) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => setShowPassword(!showPassword)}
      onMouseDown={() => setShowPassword(!showPassword)}
      edge="end"
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);

interface PasswordFieldProps {
  label: string;
  placeholder?: string;
  margin?: 'normal' | 'dense' | 'none';
  disabled?: boolean;
}

export type PasswordFormFieldProps<T extends FieldValues> = PasswordFieldProps &
  UseControllerProps<T>;

const PasswordFormField = <T extends FieldValues>(
  props: PasswordFormFieldProps<T>
) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    label,
    name,
    control,
    disabled,
    margin = 'normal',
    placeholder,
  } = props;

  const {
    formState: { errors },
  } = useController<T>({ name, control });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          label={label}
          fullWidth
          margin={margin}
          disabled={disabled}
          error={Boolean(errors[name])}
          placeholder={placeholder}
          helperText={
            errors[name] ? (
              <span color="error">{errors[name]?.message?.toString()}</span>
            ) : (
              ' '
            )
          }
          InputProps={{
            endAdornment: (
              <EndAdornment
                setShowPassword={setShowPassword}
                showPassword={showPassword}
              />
            ),
          }}
        />
      )}
    />
  );
};

export default PasswordFormField;
