import { useState, useEffect } from 'react';

import {
  Container,
  Grid,
  Typography,
  Divider,
  Select,
  MenuItem,
  Button,
  TextField,
} from '@mui/material';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import { formatCurrency } from 'utils/helpers';
import { calculateRefundedCCFees } from 'utils/calculateCCFees';
import { formatDateTime } from 'utils/formatDateTime';

export interface ReturnTransactionItemProps {
  id: number;
  name: string;
  price: number;
  feesType: 'pass' | 'absorb';
  quantity: number;
  returnQuantity: number;
  itemFee: number;
  serviceFee: number;
  processingFee: number;
  subtotal: number;
  discount: number;
  total: number;
  type?: string;
  fee?: number;
  chargeFee?: string;
}

const EditOrder = ({
  order,
  hideDialog,
}: {
  order: any;
  hideDialog: VoidFunction;
}) => {
  const [updatedOrder] = useState<any>(order);
  const [totals, setTotals] = useState<{
    fees: number;
    subtotal: number;
    chargebackFee: number;
    total: number;
  }>({
    fees: 0,
    subtotal: 0,
    total: 0,
    chargebackFee: 0,
  });

  const [transactionItems, setTransactionItems] = useState<
    ReturnTransactionItemProps[]
  >([]);

  const { callService } = useCallService();

  useEffect(() => {
    const formattedItems: ReturnTransactionItemProps[] =
      order.transactionItem.map((item: any) => {
        return {
          id: item.id,
          name: item.eventTickets[0].eventTicketType.name,
          price: item.eventTickets[0].eventTicketType.price,
          feesType: item.feesType,
          quantity: item.quantity,
          returnQuantity: 0,
          itemFee: item.itemFee,
          serviceFee: item.serviceFee,
          processingFee: item.processingFee,
          subtotal: item.subtotal,
          discount: item.discount,
          total: item.total,
          type: '-',
          chargeFee: '-',
        };
      });

    setTransactionItems(formattedItems);
  }, []);

  const updateOrder = async () => {
    try {
      // eventId
      // transactionItemId
      //
      const request = {
        eventId: order.eventId,
        transactionId: order.id,
        transactionItems,
      };

      const { response } = await callService({
        resource: httpRoutes.admin.transactions.updateTransaction(request),
        successMessage: 'Refund successfully processed!',
      });

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateItemQuantity = async (
    itemId: number,
    quantity: number | string
  ) => {
    const updatedItems = [...transactionItems];

    console.log(itemId, quantity);

    const itemToUpdate = updatedItems.find((item) => item.id === itemId);

    console.log(itemToUpdate);

    if (itemToUpdate) {
      itemToUpdate.returnQuantity = Number(quantity);

      const { totals } = await calculateRefundedCCFees(
        updatedItems,
        order.fees
      );

      setTotals({
        fees: totals.displayFees,
        chargebackFee: totals.chargebackFees,
        subtotal: totals.subtotal,
        total: totals.total,
      });
    }

    setTransactionItems(updatedItems);
  };

  const updateReturnType = async (itemId: number, type: string) => {
    const updatedItems = [...transactionItems];

    console.log(itemId, type);

    const itemToUpdate = updatedItems.find((item) => item.id === itemId);

    if (itemToUpdate) {
      itemToUpdate.type = type;
    }

    setTransactionItems(updatedItems);
    // update quantity in transactionItems
    // calculate and set return amount
  };

  const updateChargeFee = async (itemId: number, value: string) => {
    const updatedItems = [...transactionItems];

    console.log(itemId, value);

    const itemToUpdate = updatedItems.find((item) => item.id === itemId);

    if (itemToUpdate) {
      itemToUpdate.chargeFee = value;

      const { totals } = await calculateRefundedCCFees(
        updatedItems,
        order.fees
      );

      setTotals({
        fees: totals.displayFees,
        chargebackFee: totals.chargebackFees,
        subtotal: totals.subtotal,
        total: totals.total,
      });
    }

    setTransactionItems(updatedItems);
  };

  /**
   * transactionId
   * transactionItems [{
   * id
   * quantity
   * type: 'Refund' | 'Chargeback' }]
   */
  return (
    <Container>
      <Grid container flexDirection="row" spacing={1} mb={2} pr={3}>
        <Grid item md={8} textAlign="end">
          <Typography variant="h4">Event</Typography>
        </Grid>
        <Grid item md={4} textAlign="end">
          <Typography variant="h4">{updatedOrder.event.name}</Typography>
        </Grid>
        <Grid item md={8} textAlign="end">
          Order Id
        </Grid>
        <Grid item md={4} textAlign="end">
          {updatedOrder.id}
        </Grid>
        <Grid item md={8} textAlign="end">
          Order Date
        </Grid>
        <Grid item md={4} textAlign="end">
          {formatDateTime(updatedOrder.date)}
        </Grid>
        <Grid item md={8} textAlign="end">
          User
        </Grid>
        <Grid item md={4} textAlign="end">
          {`${updatedOrder.user.firstName} ${updatedOrder.user.lastName} (${updatedOrder.user.email})`}
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        spacing={1}
        mt={3}
        p={1}
        sx={{ border: '1px solid #EFEFEF', borderRadius: 1 }}
      >
        <Grid item xs={12} sx={{ m: 1 }}>
          <Grid container flexDirection="row" sx={{ margin: 'auto' }}>
            <Grid item md={2} textAlign="start">
              <Typography variant="body1">Ticket Name</Typography>
            </Grid>
            <Grid item md={1} textAlign="end">
              Quantity
            </Grid>
            <Grid item md={1} textAlign="end">
              Fees
            </Grid>
            <Grid item md={1} textAlign="end">
              Subtotal
            </Grid>
            <Grid item md={1} textAlign="end">
              Total
            </Grid>
            <Grid item md={1} textAlign="center" ml={3}>
              Quantity Returned
            </Grid>
            <Grid item md={2} textAlign="center">
              Return Type
            </Grid>
            <Grid item md={2} textAlign="end">
              Charge Fee
            </Grid>
          </Grid>
          <Divider />
        </Grid>
        {transactionItems.map((item: any, index: number) => {
          const _menuItems = [];

          const orderItem = updatedOrder.transactionItem.find(
            (transItem: any) => transItem.id === item.id
          );

          if (!orderItem) {
            return;
          }

          if (orderItem && orderItem.quantity) {
            for (let i = 0; i <= orderItem.quantity; i++) {
              _menuItems.push(
                <MenuItem key={item.id + i} value={i}>
                  {i}
                </MenuItem>
              );
            }
          } else {
            _menuItems.push(
              <MenuItem key={item.id + '0'} value={0}>
                0
              </MenuItem>
            );
          }

          return (
            <>
              <Grid container key={item.id}>
                <Grid item xs={12} key={orderItem.id} sx={{ m: 1 }}>
                  <Grid container flexDirection="row">
                    <Grid item md={2} textAlign="start">
                      <Typography variant="h3">{item.name}</Typography>
                    </Grid>
                    <Grid item md={1} textAlign="end">
                      {item.quantity}
                      <br />
                      <span style={{ color: 'red ' }}>
                        {item.returnQuantity > 0 && <>-{item.returnQuantity}</>}
                      </span>
                    </Grid>
                    <Grid item md={1} textAlign="end">
                      {formatCurrency(
                        Number(orderItem.itemFee) +
                          Number(orderItem.serviceFee) +
                          Number(orderItem.processingFee)
                      )}
                      <br />
                      <span style={{ color: 'red' }}>
                        {item.returnQuantity > 0 && (
                          <>
                            {formatCurrency(
                              Number(item.itemFee) +
                                Number(item.serviceFee) +
                                Number(item.processingFee)
                            )}
                          </>
                        )}
                      </span>
                    </Grid>
                    <Grid item md={1} textAlign="end">
                      {formatCurrency(orderItem.subtotal)}
                      <br />
                      <span style={{ color: 'red' }}>
                        {item.returnQuantity > 0 && (
                          <>{formatCurrency(item.subtotal)}</>
                        )}
                      </span>
                    </Grid>
                    <Grid item md={1} textAlign="end">
                      {formatCurrency(orderItem.total)}
                      <br />
                      <span style={{ color: 'red' }}>
                        {item.returnQuantity > 0 && (
                          <>{formatCurrency(item.total)}</>
                        )}
                      </span>
                    </Grid>

                    <Grid item md={1} textAlign="center" ml={3}>
                      <Select
                        fullWidth
                        onChange={(event) =>
                          updateItemQuantity(item.id, event.target.value)
                        }
                        value={transactionItems[index].returnQuantity}
                        label="Quantity To Return"
                      >
                        {_menuItems}
                      </Select>
                    </Grid>

                    <Grid item md={2} ml={2} textAlign="center">
                      <Select
                        fullWidth
                        disabled={transactionItems[index].returnQuantity < 1}
                        onChange={(event) =>
                          updateReturnType(item.id, event.target.value)
                        }
                        value={transactionItems[index].type}
                      >
                        <MenuItem value={'-'}>Select A Type</MenuItem>
                        <MenuItem value={'return'}>Return</MenuItem>
                        <MenuItem value={'chargeback'}>Chargeback</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={2} ml={2} textAlign="center">
                      <TextField
                        value={transactionItems[index].chargeFee}
                        onClick={() => {
                          updateChargeFee(item.id, '');
                        }}
                        variant="outlined"
                        label="Chargeback Fee"
                        onChange={(e: any) => {
                          updateChargeFee(item.id, e.target.value);
                        }}
                        onBlur={(e: any) => {
                          const _formatted = formatCurrency(e.target.value);
                          updateChargeFee(item.id, _formatted);
                        }}
                        fullWidth
                        placeholder="$20.00"
                        disabled={transactionItems[index].returnQuantity < 1}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid container flexDirection="row" spacing={1} p={3}>
        <Grid item md={8} textAlign="end">
          Subtotal
        </Grid>
        <Grid item md={4} textAlign="end">
          {formatCurrency(totals.subtotal)}
        </Grid>
        <Grid item md={8} textAlign="end">
          Fees
        </Grid>
        <Grid item md={4} textAlign="end">
          {formatCurrency(totals.fees)}
        </Grid>
        {Number(totals.chargebackFee) < 0 && (
          <>
            <Grid item md={8} textAlign="end">
              Chargeback Fee
            </Grid>
            <Grid item md={4} textAlign="end">
              {formatCurrency(totals.chargebackFee)}
            </Grid>
          </>
        )}

        <Grid item md={8} textAlign="end">
          <Typography variant="h3">Total</Typography>
        </Grid>
        <Grid item md={4} textAlign="end">
          <Typography variant="h3">
            {formatCurrency(totals.total + totals.chargebackFee)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} p={3}>
        <Button variant="text" onClick={() => hideDialog()}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => updateOrder()}>
          Submit
        </Button>
      </Grid>
    </Container>
  );
};

export default EditOrder;
