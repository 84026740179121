import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import {
  DashboardIcon,
  AttendeeIcon,
  SponsorIcon,
  TransactionIcon,
  EventIcon,
} from 'material-icons';

const actions = [
  { icon: <DashboardIcon />, name: 'Copy' },
  { icon: <AttendeeIcon />, name: 'Save' },
  { icon: <SponsorIcon />, name: 'Print' },
  { icon: <TransactionIcon />, name: 'Share' },
];

const DashboardBottomNavigation = ({ eventId }: { eventId: number }) => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(`/organizer/events/${eventId}/dashboard`);
        break;
      case 1:
        navigate(`/organizer/events/${eventId}/attendees`);
        break;
      case 2:
        navigate(`/organizer/events/${eventId}/sponsors`);
        break;
      case 3:
        navigate(`/organizer/events/${eventId}/transactions`);
        break;
      default:
        break;
    }
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, paddingBottom: 2 }}
      elevation={3}
    >
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
        <BottomNavigationAction label="Attendees" icon={<AttendeeIcon />} />
        <BottomNavigationAction label="Sponsors" icon={<SponsorIcon />} />
        <BottomNavigationAction
          label="Transactions"
          icon={<TransactionIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default DashboardBottomNavigation;
