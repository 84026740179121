import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { Box, Grid, Tab, Tabs } from '@mui/material';

import Profile from '../components/Profile';
import Security from '../components/Security';

import { useCallService } from 'hooks';

import resources from '../resources';
import PageContainer from 'components/PageContainer';

import {
  ProfileFormInputs,
  PROFILE_FORM_INITIAL_STATE,
} from '../components/Profile';

function TabPanel({ children, value, index, ...other }: any) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-profile-tabpanel-${index}`}
      aria-labelledby={`user-profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `user-profile-tab-${index}`,
    'aria-controls': `user-profile-tabpanel-${index}`,
  };
}

const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<ProfileFormInputs>(
    PROFILE_FORM_INITIAL_STATE
  );
  const [value, setValue] = useState(0);

  const { callService } = useCallService();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        setLoading(true);
        const { response } = await callService({
          resource: resources.getUserProfile(),
        });

        if (response) {
          setUserData(response);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getUserProfile();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <PageContainer title="Account Settings">
      <Grid m={3}>
        <Tabs
          value={value}
          indicatorColor="primary"
          onChange={(e, v) => handleChange(e, v)}
          sx={{
            borderBottom: '0.5px solid lightGray',
            mb: 3,
            minHeight: 'auto',
            '& button': {
              minWidth: 100,
            },
            '& a': {
              minHeight: 'auto',
              minWidth: 10,
              py: 1.5,
              px: 1,
              mr: 2.25,
              color: 'grey.600',
            },
            '& a.Mui-selected': {
              color: 'primary.main',
            },
          }}
          aria-label="user profile tabs"
          variant="scrollable"
        >
          <Tab label="Profile" {...a11yProps(0)} />
          {/* <Tab to="#" label="Billing" {...a11yProps(1)} /> */}
          <Tab label="Security" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Profile defaultValues={userData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Security />
        </TabPanel>
      </Grid>
    </PageContainer>
  );
};

export default UserProfile;
