import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { useCallService } from 'hooks';

import { ISponsorshipTypeFormInputs } from 'features/sponsorships/forms/SponsorshipTypeForm';

import httpRoutes from 'utils/httpRoutes';

type DeleteSponsorshipTypeProps = {
  sponsorshipId: number;
  sponsorship: ISponsorshipTypeFormInputs;
  onSuccess: () => void;
  hideDialog: () => void;
};

const DeleteSponsorshipType = ({
  sponsorshipId,
  sponsorship,
  onSuccess,
  hideDialog,
}: DeleteSponsorshipTypeProps) => {
  const { callService } = useCallService();

  const theme = useTheme();

  const deleteSponsorshipType = async () => {
    const { response } = await callService({
      resource:
        httpRoutes.sponsorshipTypes.deleteSponsorshipType(sponsorshipId),
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <WarningAmberOutlinedIcon
            sx={{ fontSize: '160px', color: theme.palette.warning.dark }}
          />
          <Typography fontSize={24} fontWeight="bold">
            {sponsorship.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography variant="body1" align="center">
          Are you sure you want to delete this sponsorship type?
        </Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Grid container justifyContent="space-between">
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={deleteSponsorshipType}
          >
            Yes, Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeleteSponsorshipType;
