import { LoadingButton as MUILoadingButton } from '@mui/lab';

type LoadingButtonProps = {
  children: any;
  loading: boolean;
  icon: React.ReactNode;
  onClick?: () => void;
  loadingPosition?: 'start' | 'center' | 'end';
  variant?: 'contained' | 'outlined' | 'text';
};

const LoadingButton = ({
  children,
  loading,
  icon,
  onClick,
  variant = 'contained',
}: LoadingButtonProps) => {
  return (
    <MUILoadingButton
      onClick={onClick}
      disabled={loading}
      loading={loading}
      startIcon={icon}
      loadingPosition="start"
      variant={variant}
      type={!onClick ? 'submit' : 'button'}
    >
      {children}
    </MUILoadingButton>
  );
};

export default LoadingButton;
