import React, { useEffect, useState } from 'react';

import { Button, Divider, Grid, Typography } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import RichTextEditor from 'components/RichText';

export interface ISponsorshipFormInputs {
  eventId: number;
  description: string;
  type: string;
  feesType: 'absorb' | 'pass';
  id?: number;
}

type SponsorshipFormProps = {
  eventId: number;
  onSubmit: (values: ISponsorshipFormInputs) => void;
  sponsorship?: ISponsorshipFormInputs;
};

function SponsorshipForm({
  eventId,
  sponsorship,
  onSubmit,
}: SponsorshipFormProps) {
  const edit = sponsorship && sponsorship.id;

  const { hideDialog } = useDialogDispatcher();

  const validationSchema = yup.object({
    description: yup.string().nullable(),
    eventId: yup.number(),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ISponsorshipFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      id: edit ? sponsorship.id : undefined,
      eventId: edit ? sponsorship.eventId : eventId,
      description: edit ? sponsorship.description : '',
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  return (
    <Grid container maxWidth="md" padding={2}>
      <Grid item xs={12}>
        <Typography>
          Upload your sponsorship deck slides as images to create a professional
          sponsorship page
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ margin: '0 auto' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {RichTextEditor && (
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <RichTextEditor
                      eventId={eventId}
                      onChange={field.onChange}
                      value={field.value}
                      readOnly={false}
                    />
                  )}
                />
              )}
              {!RichTextEditor && <div>Loading...</div>}
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Button variant="text" onClick={() => hideDialog()}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default SponsorshipForm;
