import { calculateCCFees } from 'utils/calculateCCFees';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

// separate data sets into steps to keep this clean
// step 1 items
// step 2 payment info
// step 3 confirm data
// step 4 checkout
export interface CheckoutFees {
  itemFee: number;
  serviceFee: number;
  processingFee: number;
}

export interface CheckoutItemState {
  id: number;
  name: string;
  price: string;
  description?: string;
  type: string;
  showQuantity: boolean;
  saleStartDate: Date;
  saleEndDate: Date;
  minPerOrder: number;
  maxPerOrder: number;
  quantity: number;
  quantityRemaining: number;
  feesType: 'pass' | 'absorb';
  subtotal?: number;
  discountAmount?: number;
  discountCode?: string;
  amountToDiscount?: number;
  itemFee?: number;
  serviceFee?: number;
  processingFee?: number;
  total?: number;
}

export interface CreditCardFormProps {
  fullName?: string;
  email?: string;
  number?: string;
  cvv?: string;
  month?: string;
  year?: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

export interface CCPurchaseRequest {
  creditCard: CreditCardFormProps;
  amount: number;
  currencyCode: 'AUD' | 'USD' | 'EUR';
}

interface CheckoutState {
  eventId: number;
  eventName: string;
  startsAt: Date;
  endsAt: Date;
  timezone: string;
  type: 'ticket' | 'sponsorship';
  fees: CheckoutFees;
  items: CheckoutItemState[];
  discount: number;
  subtotal: number;
  itemFee: number;
  processingFee?: number;
  serviceFee: number;
  total: number;
  displayFees: number;
  orderValid: boolean;
  paymentInfoValid: boolean;
  paymentInfo?: CreditCardFormProps;
  discountCodeRequired?: boolean;
}

interface CheckoutStore {
  state: CheckoutState;
  setTicketList: (tickets: CheckoutItemState[]) => void;
  update: (checkoutState: CheckoutState) => void;
  updatePaymentInfoStatus: (paymentInfoValid: boolean) => void;
  clearCart: () => void;
  clear: () => void;
}

export const INITIAL_CHECKOUT_STATE: CheckoutState = {
  eventId: 0,
  eventName: '',
  startsAt: new Date(),
  endsAt: new Date(),
  timezone: '',
  items: [],
  fees: {
    itemFee: 0,
    serviceFee: 0,
    processingFee: 0,
  },
  discount: 0,
  subtotal: 0,
  itemFee: 0,
  processingFee: 0,
  serviceFee: 0,
  total: 0,
  type: 'ticket',
  displayFees: 0,
  orderValid: false,
  paymentInfoValid: false,
  discountCodeRequired: false,
};

const useCheckoutStore = create<CheckoutStore>()(
  devtools(
    persist(
      (set) => ({
        state: INITIAL_CHECKOUT_STATE,
        setTicketList: (tickets: CheckoutItemState[]) => {
          set((state) => ({ ...state, items: tickets }));
        },
        update: (newState: CheckoutState) => {
          // create temp state to store values
          const _tempState = newState;

          const itemAdded = _tempState.items.find((item) => item.quantity > 0);

          const discountValidated = _tempState.items.find(
            (item) => item.discountCode !== ''
          );

          const discountRequired = _tempState.discountCodeRequired;

          _tempState.orderValid = itemAdded ? true : false;

          if (discountRequired && !discountValidated) {
            _tempState.orderValid = false;
          }

          // calculate order total
          calculateCCFees(newState.items, newState.fees).then(
            ({ items, totals }) => {
              _tempState.discount = totals.discount;
              _tempState.subtotal = totals.subtotal;
              _tempState.itemFee = totals.itemFee;
              _tempState.serviceFee = totals.serviceFee;
              _tempState.processingFee = totals.processingFee;
              _tempState.total = totals.total;
              _tempState.items = items;

              // set state
              set(() => ({ state: { ..._tempState } }));
            }
          );
        },
        updatePaymentInfo: (paymentInfo: Partial<CreditCardFormProps>) => {
          // update payment info
          set((state) => ({ ...state, paymentInfo }));
        },
        updatePaymentInfoStatus: (paymentInfoValid: boolean) => {
          set((state) => ({ ...state, paymentInfoValid }));
        },
        clearCart: () => {
          set((state) => ({
            ...state,
            total: 0,
            subtotal: 0,
            itemFee: 0,
            processingFee: 0,
            serviceFee: 0,
            discount: 0,
            items: [],
          }));
        },
        clear: () => {
          set(() => ({ state: INITIAL_CHECKOUT_STATE }));
        },
      }),
      {
        name: 'event-checkout-storage',
      }
    )
  )
);

export default useCheckoutStore;
