import { Box } from '@mui/system';
import DatePicker from 'components/datePicker';

import { FilterProps } from '../filterTypes';

export default function DateFilter({
  id,
  label,
  value,
  onChange,
}: FilterProps) {
  return (
    <Box id={id} sx={{ width: '100%' }}>
      <DatePicker label={label} onChange={onChange} value={value} />
    </Box>
  );
}
