import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCallService } from 'hooks';

import EventInfo from '../components/EventInfo';
import PageContainer from 'components/PageContainer';

import { REACT_APP_CLIENT_URL, REACT_APP_CLOUDFRONT_URL } from 'config/config';

import httpRoutes from 'utils/httpRoutes';
import { SponsorshipType } from 'types/Sponsorship';
import { Grid, Skeleton } from '@mui/material';
import SEO from 'components/SEO';

export type EventDetailsProps = {
  id: number;
  name: string;
  userId: number;
  description: string;
  timezone: string;
  dateAdded: Date;
  private: boolean;
  facebookLink?: string;
  twitterLink?: string;
  ehei?: number;
  eventType: number;
  videoUrl?: string;
  status?: string;
  cannabisConsumption: boolean;
  hostname: string;
  refundPolicy: number;
  paymentDueDate?: string;
  nameSlug?: string;
  contactEmail: string;
  endsAt: Date;
  startsAt: Date;
  allowShare: boolean;
  hideLocation: boolean;
  nameSlugHistory?: string;
  canceledAt?: Date;
  publishedAt?: Date;
  createIdempotencyKeyId?: number;
  updateIdempotencyKeyId?: number;
  groupId?: number;
  customMessage?: string;
  expandTicketDescriptions: boolean;
  isCopy: boolean;
  advancedPayments: boolean;
  isVirtual?: boolean;
  virtualUrl?: string;
  instagramLink?: string;
  imageUrl?: string;
  eventLocation?: EventLocationProps;
  eventTicketTypes?: EventTicketTypeProps[];
  sponsorships?: SponsorshipType[];
  discountCodeRequired?: boolean;
};

export type EventLocationProps = {
  id: number;
  streetAddress: string;
  city: string;
  postalCode: string;
  country: string;
  showOnSite: boolean;
  showOnTicket: boolean;
  state: string;
  latitude?: number;
  longitude?: number;
  placeId?: string;
  name?: string;
  formattedAddress: string;
};

export type EventTicketTypeProps = {
  id: number;
  name: string;
  description?: string;
  quantity: number;
  saleStartDate: Date;
  saleEndDate: Date;
  minPerOrder: number;
  maxPerOrder: number;
  eventId: number;
  availability: string;
  showQuantity: boolean;
  feesType: string;
  price?: string;
  type: string;
  purchaseMessage?: string;
  quantityRemaining?: number;
  active: boolean;
  updatedOn?: Date;
  deletedOn?: Date;
  discountCode?: string;
  discountAmount?: string;
};

export const INITIAL_TICKET_TYPE_FORM_STATE: EventTicketTypeProps = {
  id: 0,
  name: '',
  description: '',
  quantity: 0,
  saleStartDate: new Date(),
  saleEndDate: new Date(),
  minPerOrder: 0,
  maxPerOrder: 0,
  eventId: 0,
  availability: '',
  showQuantity: false,
  feesType: '',
  price: '',
  type: '',
  purchaseMessage: '',
  quantityRemaining: 0,
  active: false,
  updatedOn: new Date(),
  deletedOn: new Date(),
  discountCode: '',
  discountAmount: '',
};

export const INITIAL_EVENT_DETAILS_STATE: EventDetailsProps = {
  id: 0,
  name: '',
  userId: 0,
  description: '',
  timezone: '',
  dateAdded: new Date(),
  private: false,
  facebookLink: '',
  twitterLink: '',
  ehei: 0,
  eventType: 0,
  videoUrl: '',
  status: '',
  cannabisConsumption: false,
  hostname: '',
  refundPolicy: 0,
  paymentDueDate: '',
  nameSlug: '',
  contactEmail: '',
  endsAt: new Date(),
  startsAt: new Date(),
  allowShare: false,
  hideLocation: false,
  nameSlugHistory: '',
  canceledAt: new Date(),
  publishedAt: new Date(),
  createIdempotencyKeyId: 0,
  updateIdempotencyKeyId: 0,
  groupId: 0,
  customMessage: '',
  expandTicketDescriptions: false,
  isCopy: false,
  advancedPayments: false,
  isVirtual: false,
  virtualUrl: '',
  instagramLink: '',
  imageUrl: '',
  discountCodeRequired: false,
};

function EventDetails() {
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState<EventDetailsProps>(
    INITIAL_EVENT_DETAILS_STATE
  );

  const { callService } = useCallService();

  const { slug } = useParams();

  const imageUrl = eventDetails.imageUrl || 'EventHiDefault.jpeg';

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const getEventDetails = async () => {
      if (slug) {
        setLoading(true);

        const { response } = await callService({
          resource: httpRoutes.events.getEventDetailsBySlug(slug),
        });

        if (response) {
          setEventDetails(response.eventDetails);

          setLoading(false);
        }
      }
    };

    getEventDetails();
  }, [slug]);

  if (loading)
    return (
      <PageContainer maxWidth="md" elevation={5}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="25rem" width="inherit" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="3rem" width="inherit" />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Skeleton
                sx={{ m: 1 }}
                variant="circular"
                width="3rem"
                height="3rem"
              ></Skeleton>
              <Skeleton
                sx={{ m: 1 }}
                variant="circular"
                width="3rem"
                height="3rem"
              ></Skeleton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="3rem" width="inherit" />
          </Grid>
          <Grid item xs={9} pr={1}>
            <Skeleton variant="rectangular" height="50rem" width="inherit" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" height="40rem" width="inherit" />
          </Grid>
        </Grid>
      </PageContainer>
    );

  return (
    <PageContainer maxWidth="md" elevation={5}>
      <SEO
        title={`EventHi - ${eventDetails.name}`}
        description={eventDetails.description}
        keywords={['cannabis event']}
        url={`${REACT_APP_CLIENT_URL}/events/${eventDetails.nameSlug}`}
        image={`${REACT_APP_CLOUDFRONT_URL}/${imageUrl}`}
      />
      <EventInfo eventDetails={eventDetails} />
    </PageContainer>
  );
}

export default EventDetails;
