import { useState, useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import DataGrid, {
  DataGridModel,
  useDataGridState,
  LoadingState,
  ControlledDataGrid,
} from 'components/dataGrid/components/DataGrid';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import PageContainer from 'components/PageContainer';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import httpRoutes from 'utils/httpRoutes';
import TicketDetails from '../dialogs/TicketDetails';

const loadingState: LoadingState = {
  data: true,
  exporting: false,
};

const EventAttendeesGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog, hideDialog } = useDialogDispatcher();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<LoadingState>(loadingState);
  const [ticketTypes, setTicketTypes] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const params = useParams();
  const { id = 0, ticketCode = '' } = params;
  const _eventId = Number(id);

  const getAttendeeDetails = async (ticketCode: string) => {
    const { response } = await callService({
      resource: httpRoutes.organizer.getTicketDetails(ticketCode, _eventId),
    });

    if (response) {
      showDialog({
        size: 'xs',
        content: (
          <TicketDetails
            ticketDetails={response}
            onSuccess={getEventAttendees}
          />
        ),
      });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    getEventAttendees();
  }, [state, filters]);

  useEffect(() => {
    if (ticketCode !== '' && _eventId) {
      getAttendeeDetails(ticketCode);
    }
  }, [searchParams]);

  useEffect(() => {
    const getTicketTypes = async () => {
      if (_eventId) {
        const { response } = await callService({
          resource: httpRoutes.organizer.getAllTicketTypes(_eventId),
        });

        if (response) {
          const defaultOption = {
            label: 'Any Type',
            value: '',
          };

          const _ticketTypes = response.map(
            (item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            }
          );

          _ticketTypes.unshift(defaultOption);

          setTicketTypes(_ticketTypes);
        }
      }
    };

    getTicketTypes();
  }, []);

  const getEventAttendees = async () => {
    setLoading((prevState) => ({ ...prevState, data: true }));

    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.getEventAttendees({
          eventId: Number(id),
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, data: false }));
    }
  };

  const exportEventAttendees = async () => {
    setLoading((prevState) => ({ ...prevState, exporting: true }));
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.exportEventAttendees({
          eventId: Number(id),
          params: { ...state },
          filters,
        }),
      });

      if (response) {
        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Event Attendees.csv');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  const checkInAttendee = async (id: number) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.checkInAttendee(id),
      });

      if (response) {
        getEventAttendees();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const undoCheckInAttendee = async (id: number) => {
    try {
      const { response } = await callService({
        resource: httpRoutes.organizer.undoCheckInAttendee(id),
      });

      if (response) {
        getEventAttendees();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Attendee Name',
    },
    {
      id: 'email',
      type: 'text',
      label: 'Email',
    },
    {
      id: 'ticketTypeId',
      type: 'select',
      label: 'Ticket Type',
      options: ticketTypes,
    },
  ];

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Ticket Number',
      field: 'id',
      sortable: false,
      filterable: false,
      hide: matchDownSM,
      flex: 0.1,
    },
    {
      headerName: 'Name',
      field: 'userName',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      flex: 0.15,
    },
    {
      headerName: 'Email',
      field: 'userEmail',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      flex: 0.15,
    },
    {
      headerName: 'Ticket Type',
      field: 'ticketName',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      type: 'string',
      flex: 0.2,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      type: 'string',
    },
    {
      headerName: 'Claimed',
      field: 'claimed',
      sortable: true,
      filterable: true,
      hide: matchDownSM,
      type: 'boolean',
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      headerAlign: 'center',
      hide: matchDownSM,
      align: 'center',
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.claimed && (
              <Button onClick={() => undoCheckInAttendee(params.row.id)}>
                Undo Check-In
              </Button>
            )}
            {!params.row.claimed && (
              <Button onClick={() => checkInAttendee(params.row.id)}>
                Check-In
              </Button>
            )}
          </>
        );
      },
    },
    {
      headerName: 'Attendee',
      field: 'mobile',
      hide: !matchDownSM,
      flex: 1,
      ColumnComponent: (params: any) => {
        return (
          <Grid
            container
            height="100%"
            m={0.5}
            p={1}
            justifyContent="space-between"
            flexDirection="row"
            spacing={1}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Name</Typography>
              <Typography fontWeight="bold">{params.row.userName}</Typography>
              <Typography>{params.row.userEmail}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Ticket Type</Typography>
              <Typography>{params.row.ticketName}</Typography>
              <Typography>Ticket # {params.row.id}</Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="caption">Checked In</Typography>
              <Typography>{params.row.claimed ? 'Yes' : 'No'}</Typography>
              <Grid item xs={12}>
                {params.row.claimed && (
                  <Button
                    size="small"
                    onClick={() => undoCheckInAttendee(params.row.id)}
                  >
                    Undo Check-In
                  </Button>
                )}
                {!params.row.claimed && (
                  <Button
                    size="small"
                    onClick={() => checkInAttendee(params.row.id)}
                  >
                    Check-In
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <PageContainer maxWidth="xl">
      <DataGrid
        tableTitle="Event Attendees"
        rows={gridData.rows}
        columns={columns}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading.data}
        handleExport={exportEventAttendees}
      />
    </PageContainer>
  );
};

const EventAttendees = () => (
  <ControlledDataGrid>
    <EventAttendeesGrid />
  </ControlledDataGrid>
);

export default EventAttendees;
