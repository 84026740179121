import { useCallService } from 'hooks';

import SponsorshipForm, {
  ISponsorshipFormInputs,
} from '../../../forms/SponsorshipForm';

import httpRoutes from 'utils/httpRoutes';

type CreateSponsorshipProps = {
  eventId: number;
  onSuccess: () => void;
  hideDialog: () => void;
};

const Create = ({ eventId, onSuccess, hideDialog }: CreateSponsorshipProps) => {
  const { callService } = useCallService();

  const createSponsorship = async (values: ISponsorshipFormInputs) => {
    const { response } = await callService({
      resource: httpRoutes.sponsorships.createSponsorship(values),
      successMessage: 'Sponsorship created successfully!',
    });

    if (response) {
      onSuccess();
      hideDialog();
    }
  };

  return <SponsorshipForm eventId={eventId} onSubmit={createSponsorship} />;
};

export default Create;
