import { useEffect, useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { Avatar, Box, Card, Grid, Typography } from '@mui/material';
import { formatCurrency } from 'utils/helpers';
import { EventTicketIcon, SponsorshipIcon } from 'material-icons';

ChartJS.register(ArcElement, Tooltip);

const TotalSales = ({ eventId }: { eventId: number }) => {
  const { callService } = useCallService();

  const [stats, setStats] = useState<{
    ticketSales: number;
    sponsorshipSales: number;
    netSales: number;
  }>({
    ticketSales: 0,
    sponsorshipSales: 0,
    netSales: 0,
  });
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: ['Ticket Revenue', 'Sponsorship Revenue'], datasets: [] });
  const [loading, setLoading] = useState(true);

  const getStats = async () => {
    try {
      if (eventId) {
        const { response } = await callService({
          resource: httpRoutes.organizer.getEventStats(eventId),
        });

        if (response) {
          const data: number[] = [];

          setStats(response);
          data.push(response.ticketSales);
          data.push(response.sponsorshipSales);

          const doughnutData = {
            labels: chartData.labels,
            datasets: [
              {
                data,
                borderWidth: 1,
                hoverOffset: 4,
                backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)'],
              },
            ],
          };

          setChartData(doughnutData);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, [eventId]);

  return (
    <Card
      sx={{ p: { xs: 1, sm: 2, md: 3, lg: 4 }, borderRadius: '15px' }}
      elevation={2}
    >
      <Box>
        <Box
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '130%',
            color: '#56C596',
          }}
        >
          REVENUE SUMMARY
        </Box>
        <Box
          sx={{
            fontFamily: 'RobotoSlab',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '130%',
            color: '#445D74',
          }}
        >
          by tickets and sponsorships
        </Box>
      </Box>
      <Grid container flexDirection="row">
        <Grid item xs={5} md={12} sx={{ mb: 2 }}>
          <Doughnut
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (context: any) => {
                      return formatCurrency(context.parsed);
                    },
                  },
                },
              },
              maintainAspectRatio: false,
            }}
            data={chartData}
          />
        </Grid>
        <Grid item xs={7} md={12} sx={{ margin: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #BCC6CF',
                pb: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  sx={{
                    border: '1px solid rgb(255, 99, 132)',
                    color: 'rgb(255, 99, 132)',
                    backgroundColor: 'white',
                  }}
                >
                  <EventTicketIcon sx={{ color: 'inherit' }} />
                </Avatar>
                <Typography sx={{ ml: 2 }}>Ticket Revenue</Typography>
              </Box>
              <Box
                sx={{
                  fontWeight: '300',
                  lineHeight: '120%',
                  color: '#287E7D',
                }}
              >
                <Typography variant="body1">
                  {formatCurrency(stats.ticketSales)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #BCC6CF',
                pb: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  sx={{
                    border: '1px solid rgb(54, 162, 235)',
                    color: 'rgb(54, 162, 235)',
                    backgroundColor: 'white',
                  }}
                >
                  <SponsorshipIcon sx={{ color: 'inherit' }} />
                </Avatar>
                <Typography sx={{ ml: 2 }}>Sponsorship Revenue</Typography>
              </Box>
              <Box
                sx={{
                  fontWeight: '300',
                  lineHeight: '120%',
                  color: '#287E7D',
                }}
              >
                <Typography>
                  {formatCurrency(stats.sponsorshipSales)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 1,
              }}
            >
              <Typography fontSize="20px">Total Revenue</Typography>
              <Box
                sx={{
                  fontWeight: '600',
                  lineHeight: '120%',
                  color: '#287E7D',
                }}
              >
                <Typography fontSize="20px">
                  {formatCurrency(stats.netSales)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TotalSales;
