export type DiscountCodeProps = {
  id?: string;
  discountCode: string;
  discountAmount: string | number;
  discountLimit?: number;
};

export const INITIAL_DISCOUNT_CODE_STATE: DiscountCodeProps = {
  id: '',
  discountCode: '',
  discountAmount: '',
  discountLimit: 0,
};
