import { TablePagination } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { Order } from './TableHeader';

export type PaginationInterface = {
  page: number;
  limit: number;
  order?: Order;
  orderBy?: string;
};

interface IPagination {
  totalRows: number;
  page: number;
  rowsPerPage: number;
  handlePageChange: (event: any, page: any) => void;
  handleRowsPerPageChange: (event: any) => void;
}

const Pagination = ({
  totalRows,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
}: IPagination) => {
  return (
    <TablePagination
      rowsPerPageOptions={[25, 50, 100]}
      component="div"
      count={totalRows}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  );
};

export default Pagination;
